//Packages
import React, { useState } from "react";
import { CustomDialog } from "react-st-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import DeadlinesList from "./Entities/Deadlines/DeadlinesList.jsx";
import DomainsList from "./Entities/Domains/DomainsList.jsx";
import NewDomain from "./Entities/Domains/NewDomain.jsx";
import PrioritiesList from "./Entities/Priorities/PrioritiesList.jsx";

//Custom Components
import LeftMenuList from "./LeftMenu/LeftMenuList.jsx";
import UserProfile from "./Profile/userProfile.jsx";

const PrioritiesDeadlines = () => {
  const activeNodes = ["001", "001_005"]; //#179ed3
  const [tabActive, setTabActive] = useState(0);
  const [priorityTabActive, setPriorityTabActive] = useState(0);

  const tabList = ["Domain", "Priorities", "Deadlines"];
  const serviceList = ["transcription", "translation", "subtitling"];

  const handleDomain = async (props) => {
    await CustomDialog(<NewDomain serviceType="Transcription" />, {
      title: "Create Domain",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="001/001_005" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">Domains</div>

            <UserProfile />
          </div>

          <div>
            <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
              <TabList className="tabsWrap">
                {tabList.map((value, index) => (
                  <Tab
                    key={index}
                    tabIndex={tabActive}
                    onClick={() => {
                      setTabActive(index);
                    }}
                    className="tabItem"
                    style={
                      tabActive === index
                        ? {
                            color: "#EE6054",
                            fontWeight: "bold",
                            borderBottom: "3px solid #EE6054",
                          }
                        : {}
                    }
                  >
                    {value}
                  </Tab>
                ))}
              </TabList>
              <TabPanel key={0}>
                <Tabs
                  key={0}
                  forceRenderTabPanel
                  defaultIndex={0}
                  defaultFocus={true}
                >
                  <TabPanel>
                    <div className="domainContent ">
                      <div className="d-flex breadcrumWrap">
                        <ul className="breadcrumb">
                          <li>Entities</li>
                          <li>Domain</li>
                        </ul>
                        <button
                          className="orangeBtn btn"
                          onClick={handleDomain}
                        >
                          Add Domain
                        </button>
                      </div>
                    </div>

                    <DomainsList />
                  </TabPanel>

                  <TabPanel>
                    <div className="d-flex breadcrumWrap">
                      <div></div>

                      <button className="orangeBtn btn">Add</button>
                    </div>

                    <PrioritiesList serviceType="transcription" />
                  </TabPanel>
                  <TabPanel>
                    <div className="d-flex breadcrumWrap">
                      <div></div>

                      <button className="orangeBtn btn">Add</button>
                    </div>

                    <PrioritiesList serviceType="translation" />
                  </TabPanel>
                  <TabPanel>
                    <div className="d-flex breadcrumWrap">
                      <div></div>

                      <button className="orangeBtn btn">Add</button>
                    </div>

                    <PrioritiesList serviceType="subtitling" />
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel key={1}>
                <Tabs
                  key={1}
                  forceRenderTabPanel
                  defaultIndex={0}
                  defaultFocus={true}
                >
                  <div className="createNewPriBtn d-flex justify-end">
                    <button className="orangeBtn btn">
                      create a new priority
                    </button>
                  </div>
                  <div className=" breadcrumWrap">
                    <ul className="breadcrumb">
                      <li>Entities</li>
                      <li>Priorities</li>
                      <li>{serviceList[priorityTabActive]}</li>
                    </ul>
                    <TabList className="d-flex Tabs-filled">
                      {serviceList.map((value, index) => (
                        <Tab
                          className="tabsFilledItem"
                          key={index}
                          tabIndex={priorityTabActive}
                          onClick={() => {
                            setPriorityTabActive(index);
                          }}
                          style={
                            priorityTabActive === index
                              ? {
                                  backgroundColor: "#179ed3",
                                  color: "white",
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }
                              : {
                                  textTransform: "capitalize",
                                }
                          }
                        >
                          {value}
                        </Tab>
                      ))}
                    </TabList>
                  </div>

                  <TabPanel style={{ marginTop: -20 }}>
                    <PrioritiesList serviceType={serviceList[0]} />
                  </TabPanel>

                  <TabPanel style={{ marginTop: -20 }}>
                    <PrioritiesList serviceType={serviceList[1]} />
                  </TabPanel>

                  <TabPanel style={{ marginTop: -20 }}>
                    <PrioritiesList serviceType={serviceList[2]} />
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel>
                <Tabs
                  key={1}
                  forceRenderTabPanel
                  defaultIndex={0}
                  defaultFocus={true}
                >
                  <div className="createNewPriBtn d-flex justify-end">
                    <button className="orangeBtn btn">
                      create a new priority
                    </button>
                  </div>
                  <div className=" breadcrumWrap">
                    <ul className="breadcrumb">
                      <li>Entities</li>
                      <li>Deadlines</li>
                      <li>{serviceList[priorityTabActive]}</li>
                    </ul>
                    <TabList className="d-flex Tabs-filled">
                      {serviceList.map((value, index) => (
                        <Tab
                          className="tabsFilledItem"
                          key={index}
                          tabIndex={priorityTabActive}
                          onClick={() => {
                            setPriorityTabActive(index);
                          }}
                          style={
                            priorityTabActive === index
                              ? {
                                  backgroundColor: "#179ed3",
                                  color: "white",
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }
                              : {
                                  textTransform: "capitalize",
                                }
                          }
                        >
                          {value}
                        </Tab>
                      ))}
                    </TabList>
                  </div>

                  <TabPanel style={{ marginTop: -20 }}>
                    <DeadlinesList serviceType={serviceList[0]} />
                  </TabPanel>

                  <TabPanel style={{ marginTop: -20 }}>
                    <DeadlinesList serviceType={serviceList[1]} />
                  </TabPanel>

                  <TabPanel style={{ marginTop: -20 }}>
                    <DeadlinesList serviceType={serviceList[2]} />
                  </TabPanel>
                </Tabs>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrioritiesDeadlines;
