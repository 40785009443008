//Packages
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";

//Styles
import "./assets/style/App.sass";

//Helper Functions
import { logout } from "./actions/userActions.js";
import PrivateRoutes from "./helper/PrivateRoutes.js";

//Custom Components
import Login from "./components/Login/login.jsx";
import Dashboard from "./components/Dashboard/dashboard.jsx";
import Users from "./components/Entities/Users/Users.jsx";
import Customers from "./components/Entities/Customers/Customers.jsx";
import Vendors from "./components/Entities/Vendors/Vendors.jsx";
import VendorsRates from "./components/Entities/Vendors/VendorsRates.jsx";
import VendorOnBoarding from "./components/Entities/Vendors/VendorOnBoarding.jsx";
import PrioritiesDeadlines from "./components/PrioritiesDeadlines.jsx";
import LanguagesRates from "./components/LanguagesRates.jsx";
import Transcription from "./components/Transcription/Transcription.jsx";
import Assign from "./components/Transcription/Assign.jsx";
import UpDown from "./components/Transcription/UpDown.jsx";
import Delivery from "./components/Transcription/Delivery.jsx";
import InvoicesPayments from "./components/Transcription/InvoicesPayments.jsx";
import Tracking from "./components/Transcription/Tracking.jsx";
import ChangePassword from "./components/Profile/change-password.jsx";
import TranslationAssign from "./components/Translation/Assign.jsx";
import TranslationVendors from "./components/Entities/Vendors/TranslationVendors.jsx"

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (userLogin.token) {
      const decoded = jwt_decode(userLogin.token);

      //check for expire token
      const currentTime = Date.now() / 1000; //to get milliseconds
      if (decoded.exp < currentTime) {
        //Logout user
        dispatch(logout());
      }
    }
  }, [dispatch, userLogin.token]);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/users" component={Users} />
          <PrivateRoutes exact path="/customers" component={Customers} />
          <PrivateRoutes exact path="/vendors" component={Vendors} />
          <PrivateRoutes exact path="/vendorrates" component={VendorsRates} />
          <PrivateRoutes
            exact
            path="/vendoronboarding"
            component={VendorOnBoarding}
          />
          <PrivateRoutes
            exact
            path="/prioritiesdeadlines"
            component={PrioritiesDeadlines}
          />
          <PrivateRoutes
            exact
            path="/languagesrates"
            component={LanguagesRates}
          />
          <PrivateRoutes
            exact
            path="/transcriptions"
            component={Transcription}
          />
          <PrivateRoutes exact path="/transcriptionassign" component={Assign} />
          <PrivateRoutes exact path="/transcriptionupdown" component={UpDown} />
          <PrivateRoutes
            exact
            path="/transcriptiontrack"
            component={Tracking}
          />
          <PrivateRoutes
            exact
            path="/transcriptiondelivery"
            component={Delivery}
          />
          <PrivateRoutes
            exact
            path="/transcriptioninvpay"
            component={InvoicesPayments}
          />
          <PrivateRoutes
            exact
            path="/changepassword"
            component={ChangePassword}
          />
          <PrivateRoutes exact path="/translationassign" component={TranslationAssign} />
          <PrivateRoutes exact path="/translationvendors" component={TranslationVendors} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
