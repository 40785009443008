//NPM Packages
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CustomDialog } from "react-st-modal";

//Styles
import "../../../src/styles.css";
//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Components
import Table from "../Table/Table.jsx";
import ProgressBar from "../Utils/ProgressBar.jsx";
import PrjtAssign from "./PrjtAssign";
import PrjtClosure from "./prjtClosure"

const CustProjectList = (props) => {
  const {
    serviceType,
    type,
    setCustomer,
    totalDuration,
    uploadedDuration,
    handledBy,
    closure,
    status,
    progress,
  } = props;

  const userLogin = useSelector((state) => state.userLogin);
  const {  user } = userLogin;
  

  const [data, setData] = useState([]);
  const [rowColor, setRowColor] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState()

  let columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "custName",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer", background: selectedRowIndex && selectedRowIndex == row.index ? "#e8b9224d" : "transparent" }}
              onClick={() => handleClick(row.original, column, row.index)}
            >
              <span title="Click for the detailed Source_Target Language List">
                {row.original.custName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Source_Target Language List">
                {row.original.projectName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Total",
        accessor: "totalWordCount",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        //hidden: !totalWordCount,
        // Cell: ({ value, column, row }) => {
        //   return (
        //     <div
        //       style={{ cursor: "pointer" }}
        //       onClick={() => handleClick(row.original, column)}
        //     >
        //       <span title="Click for the detailed Language Domain List">
        //         {`${row.original.totalDuration}`}
        //       </span>
        //     </div>
        //   );
        // },
      },
      // {
      //   Header:
      //     type === "track" || type === "assign"
      //       ? "Progress / Uploaded"
      //       : " Progress / Total",
      //   accessor: "uploadedDuration",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   hidden: !uploadedDuration,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original, column)}
      //       >
      //         <span title="Click for the detailed Language Domain List">
      //           {type === "track"
      //             ? `${row.original.completedDuration} / ${row.original.uploadedDuration}`
      //             : type === "assign"
      //             ? `${row.original.assignedDuration} / ${row.original.uploadedDuration}`
      //             : `${row.original.uploadedDuration} / ${row.original.totalDuration}`}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Closure",
        accessor: "closure",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !closure,
        Cell: ({ value, column, row }) => { 
          return (
            user.email == "gurubasugol@gmail.com" ?
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
               <button className="rateBtn">Closure</button>
            </div> : ""
          );
        },
      },
      {
        Header: "Handled By",
        accessor: "assignedPM",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !handledBy,
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              {value !== "" && value !== undefined && value !== null ? (
                <span title="Click for the detailed Language Domain List">
                  {value}
                </span>
              ) : (
                <button className="rateBtn">Assign</button>
              )}
            </div>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "stage",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   hidden: !status,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original, column)}
      //       >
      //         <span title="Click for the detailed Language Domain List">
      //           {row.original.stage}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Completed (%)",
      //   accessor: "percentageOfCompletion",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   hidden: !progress,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original, column)}
      //       >
      //         <ProgressBar completed={row.original.percentageOfCompletion} />
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    const getCustomers = async () => {
      const url = type==="delivery"?`${process.env.REACT_APP_URL}/admin/translation/customerProjectList`:`${process.env.REACT_APP_URL}/admin/translation/getProjectList`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          //console.log("customerProjectList res", res)
          const { data } = res.data;

          setData(data);
        })
        .catch((err) => {
          setData([]);
        });
    };

    getCustomers();
  }, []);

  const handleClick = async (row, column, rowindex) => {
    //console.log("row, column,rowindex, selectedRowIndex", row, column,rowindex, selectedRowIndex)
    //selectedRowIndex == rowindex ? setRowColor("#e8b9224d") : setRowColor("#000000")
    setSelectedRowIndex(rowindex)
    if (row !== undefined) {
      //console.log("column.id,row",column.id,row);
      if (column.id === "assignedPM") {
        if (row.assignedPM !== "") setCustomer(row);
        else {
          await CustomDialog(<PrjtAssign selectedRow={row} />, {
            title: "Project Assign",
            showCloseIcon: true,
            className: { minwidth: "500px" },
          });
        }
      }else if(column.id === "closure"){
        await CustomDialog(<PrjtClosure selectedRow={row} />, {
          title: "Project Closure",
          showCloseIcon: true,
          className: { minwidth: "500px" },
        });
      } else {
        setCustomer(row);
      }
    } else {
      setCustomer({});
    }
  };

  return (
    <div className="tableContainer">
      <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
      </div>

      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="Projects"
        isMultiSelect={false}
        // setSelectedRows={setSelectedRows}
        // setSelectedRowsValues={setSelectedRowsValues}
        // setClickedRow={setClickedRow}
      />
    </div>
  );
};

export default CustProjectList;
