//Packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import Select from "react-select";
import { useDialog } from "react-st-modal";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

//Custom Components
import reqHeader from "../../helper/reqHeader.js";
import Spinner from "../Utils/Spinner.jsx";

//Styles
import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";

const UpLangDomainSpDets = (props) => {
  const { id, customerID, srcLang, domains: dataDomains } = props.selectedRow;

  const { serviceType } = props;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [spinnerMessage, setSpinnerMessage] = useState("");
  const [upload, setUpload] = useState(false);

  const [languages, setLanguages] = useState("");
  const [language, setLanguage] = useState();
  const [domains, setDomains] = useState("");
  const [domain, setDomain] = useState();
  const [frequencies, setFrequencies] = useState("");
  const [frequency, setFrequency] = useState();

  // use this hook to control the dialog
  const dialog = useDialog();

  toast.configure();

  const OnChangeLanguages = (item) => {
    setLanguage(item);
    setDomain(null);
    setFrequency(null);
  };

  const OnChangeDomain = (item) => {
    setDomain(item);
    setFrequency(null);
  };

  const OnChangeFrequencies = (item) => {
    setFrequency(item);
  };

  useEffect(() => {
    if (srcLang !== "") {
      const lang = srcLang.split(",");

      const langs = lang.map((value, index) => {
        return { id: index + 1, name: value, value: value };
      });

      setLanguages(langs);
    }
  }, [srcLang]);

  useEffect(() => {
    let doms, uniqueDoms;

    if (language !== undefined && language !== null) {
      if (dataDomains !== undefined || dataDomains.length > 0) {
        doms = dataDomains.filter((value, index) => {
          return value.language === language.name;
        });

        uniqueDoms = [...new Set(doms.map((val) => val.name))];

        uniqueDoms = uniqueDoms.map((val, ind) => {
          return { id: ind + 1, name: val, value: val };
        });
      }

      setDomains(uniqueDoms);
    }

    if (
      language !== undefined &&
      language !== null &&
      domain !== undefined &&
      domain !== null
    ) {
      if (dataDomains !== undefined || dataDomains.length > 0) {
        doms = dataDomains
          .filter((value, index) => {
            return (
              value.language === language.name && value.name === domain.name
            );
          })
          .map((val, ind) => {
            return { id: ind + 1, name: val.frequency, value: val.frequency };
          });
      }

      setFrequencies(doms);
    }
  }, [id, customerID, dataDomains, language, domain, frequency, upload]);

  const handleSubmit = async (files, allFiles) => {
    allFiles.forEach((f) => f.remove());

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);

    if (
      language !== undefined &&
      language !== null &&
      domain !== undefined &&
      domain !== null &&
      frequency !== undefined &&
      frequency !== null
    ) {
      setSpinnerMessage("Uploading files... Please Wait!");
      setLoading(true);

      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const f = file[0];

      reader.onload = async (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: "binary" });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});

        const { Sheet1: excelData } = jsonData;

        const url = `${process.env.REACT_APP_URL}/admin/upload/uploadspeakerdetails`;

        const headers = reqHeader(true, true, true);

        const params = {
          type: serviceType,
          projectID: id,
          customerID: customerID,
          language: language.value,
          domain: domain.value,
          frequency: frequency.value,
        };

        const config = { headers, params };

        await axios
          .post(url, excelData, config)
          .then((res) => {
            const { data, errors } = res.data;

            if (errors.length > 0) {
              toast.error(
                <div>
                  {/* Already exists these Email IDs :- <br /> */}
                  {errors.map((value, index) => {
                    return (
                      <span key={index}>
                        {value} <br />
                      </span>
                    );
                  })}
                </div>
              );
            } else {
              toast.success(`Speaker Details uploaded Successfully !!!`);
            }
          })
          .catch((err) => {});
      };

      reader.readAsBinaryString(f);

      setLoading(false);
      setUpload(!upload);
    } else {
      if (language === undefined || language === null) {
        toast.error("Kindly select the Language!");
      } else if (domain === undefined || domain === null) {
        toast.error("Kindly select the Domain!");
      } else if (frequency === undefined || frequency === null) {
        toast.error("Kindly select the Frequency!");
      }
    }
  };

  return loading ? (
    <div>
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} />
    </div>
  ) : (
    <>
      <form action="#" encType="multipart/form-data">
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Language<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="language"
                    value={language}
                    onChange={OnChangeLanguages}
                    placeholder={"Select Languages"}
                    options={languages}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Domain<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="domain"
                    value={domain}
                    onChange={OnChangeDomain}
                    placeholder={"Select Domain"}
                    options={domains}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Frequency<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="frequency"
                    value={frequency}
                    onChange={OnChangeFrequencies}
                    placeholder={"Select Frequency"}
                    options={frequencies}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>
              </div>
            </div>

            <Dropzone
              onSubmit={handleSubmit}
              autoUpload={false}
              maxFiles={1}
              styles={{
                dropzone: {
                  overflow: "auto",
                },
                inputLabelWithFiles: { display: "none" },
              }}
              canRemove={true}
              accept=".xls, .xlsx"
              inputContent={(files, extra) =>
                extra.reject
                  ? "Audio and Video files only"
                  : "Drag & Drop files here or click to select the file"
              }
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default UpLangDomainSpDets;
