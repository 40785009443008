//Packages
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDialog,Confirm } from "react-st-modal";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
//Custom Components
import Table from "../../Table/Table.jsx";
//Helper Files
import reqHeader from "../../../helper/reqHeader.js";
//Styles
import "react-phone-number-input/style.css";
import "react-tabs/style/react-tabs.scss";
import "react-toastify/dist/ReactToastify.css";

const EditVendor = (props) => {
  const schema = yup.object().shape({
    fname: yup
      .string()
      .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
      .required("First name is required!"),
    lname: yup
      .string()
      .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!"),
    // .required("Last name is required!"),
    email: yup
      .string()
      .required("Email is required!")
      .email("Enter a valid Email ID!"),
  });
  const { row } = props;
  console.log("edit vendor row..",row);
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      fname: row.fname,
      lname: row.lname,
      email: row.email,
      skypeID: row.skypeID,
      mobile: row.mobile,
      address1: row.billingAddress.address1,
      address2: row.billingAddress.address2,
      currency: row.currency,
      country: row.billingAddress.country,
      state: row.billingAddress.state,
      city: row.billingAddress.city,
      zipcode: row.billingAddress.zipcode,
      gstNo: row.billingAddress.GSTIN,
      timings: row.timings,
      hrsPerWeek: row.hrsPerWeek,
      active: row.active,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  toast.configure();

  const cols = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ row, value }) => (
          <div>
            <IndeterminateCheckBox
              value={value === "Inactive" ? false : true}
              selectedRow={row.original}
              name="status"
            />
          </div>
        ),
      },
      // {
      //   Header: "",
      //   accessor: "isDeleted",
      //   Cell: ({ row, value }) => (
      //     <div>
      //       <button
      //         disabled={!row.original.isNew}
      //         onClick={(e) => handleRowDeleteType(row.original, e)}>
      //         Delete
      //       </button>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const [mobile, setMobile] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [currencies] = useState([
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
    { label: "EURO", value: "EURO" },
  ]);
  const [currency, setCurrency] = useState(currencies[0]);
  const [timings] = useState([
    { label: "Full Time", value: "Full Time" },
    { label: "Part Time", value: "Part Time" },
  ]);
  const [timing, setTiming] = useState(timings[0]);

  const [vendtypes] = useState([
    { label: "Transcriber", value: "Transcriber" },
    { label: "Translator", value: "Translator" },
    { label: "Subtitler", value: "Subtitler" },
    { label: "Proof Reader", value: "Proof Reader" },
  ]);
  const [vendType, setVendType] = useState();

  const [languages, setLanguages] = useState([]);
  const [lang, setLang] = useState();

  const [prtypes] = useState([
    { label: "Transcription", value: "Transcription" },
    { label: "Translation", value: "Translation" },
    { label: "Subtitling", value: "Subtitling" },
  ]);
  const [prType, setPrType] = useState();

  const [langData, setLangData] = useState([]);
  const [changedRow, setChangedRow] = useState();
  const [isUpdated, setIsUpdated] = useState(false);

  // use this hook to control the dialog
  const dialog = useDialog();

  useEffect(() => {
    const getCountries = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: code,
              value: name,
            }))
          );
        })
        .catch((err) => {});
    };

    const getlanguages = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/language/alllanguages`;

      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          setLanguages(
            res.data.data.map(({ _id, language }, index) => ({
              key: index,
              label: language,
              value: language,
            }))
          );
        })
        .catch((err) => {});
    };

    getCountries();

    getlanguages();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      setCountry({
        id: 1,
        label: row.billingAddress.country,
        value: row.billingAddress.country,
      });
    }

    let langList = [];

    if (row.transcriberlanguages.length > 0) {
      const { transcriberlanguages: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Transcriber",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    if (row.translatorlanguages.length > 0) {
      const { translatorlanguages: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Translator",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    if (row.subtitlerlanguages.length > 0) {
      const { subtitlerlanguages: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Subtitler",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    if (row.prTranscriberLang.length > 0) {
      const { prTranscriberLang: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Proof Reader (Transcription)",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    if (row.prTranslatorLang.length > 0) {
      const { prTranslatorLang: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Proof Reader (Translation)",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    if (row.prSubtitlerLang.length > 0) {
      const { prSubtitlerLang: languages } = row;

      let types = languages.map((value, index) => {
        return {
          type: "Proof Reader (Subtitling)",
          language: value.type,
          oldStatus: "",
          status: value.status,
          active: value.status,
          isNew: false,
          isChanged: false,
          isEdited: value.status === "Rejected" ? false : true,
          isDeleted: value.status === "Rejected" ? false : true,
        };
      });

      langList = [...langList, types];
    }

    let uniqueLang = [...new Set(langList.flat())];

    setLangData(uniqueLang);

    setCurrency({ label: row.currency, value: row.currency });

    setMobile(
      row.mobile !== "" && row.mobile !== undefined
        ? row.mobile
        : row.billingAddress.mobile
    );
  }, [countries, row]);

  const OnChangeCountries = (item) => {
    setValue("countries", item.value);
    setCountry(item);
  };

  const OnChangeCurrency = (item) => {
    setValue("currency", item.value);
    setCurrency(item);
  };

  const OnChangeVendType = (item) => {
    setValue("vendType", item.value);
    setVendType(item);
  };

  const OnChangePrType = (item) => {
    setValue("prType", item.value);
    setPrType(item);
  };

  const OnChangeLang = (item) => {
    setValue("lang", item.value);
    setLang(item);
  };

  const dataList = useMemo(() => langData, [langData]);

  const handleRowAddType = async (e) => {
    e.preventDefault();
     //console.log("fname,lname,mobile..",fname,lname,mobile);
    // console.log("email..",row.email);
    let email=row.email;
    console.log("email1..",email);
    debugger;
    if(email!="" && email!=undefined)
    {
      const result = await Confirm(
        "Are you sure you want to Add this Language?"
      );
      if (result) {
        debugger;
        console.log("vendType,lang,prType",vendType,lang,prType);
       if (vendType !== undefined || lang !== undefined) {
        let row;
        if (vendType.value === "Proof Reader") {
          debugger;
          if (prType !== undefined && lang !== undefined) {
            let vendtype = `${vendType.value} (${prType.value})`;
            let langtype = lang.value;
  
            let isExists = langData.filter(
              (value, index) =>
                value.type === vendtype && value.language === langtype
            );
  
            if (isExists.length > 0) {
              toast.error("Selected Type and Languages already exists !!!");
            } else {
              row = {
                type: `${vendType.value} (${prType.value})`,
                language: lang.value,
                oldStatus: "",
                status: "Open",
                active: "Open",
                isNew: true,
                isChanged: true,
                isEdited: true,
                isDeleted: true,
              };
              ////newly added for db upate--add new lang for this user
              const url = `${process.env.REACT_APP_URL}/admin/users/updatevendorlang`;
              const headers = reqHeader(true, true, true);
              const config = { headers };
              const body = {
                vendType: vendType.value,
                utype:prType.value,
                lang:lang.value,
                email:email,
                mobile:mobile
              };
              console.log("body..",body);
              axios
                .put(url, body, config)
                .then((res) => {
                  if (res.data.success === false && res.data.msg) {
                    Swal.fire({
                      icon: "error",
                      title: "Error !!!",
                      text: `${res.data.msg}`,
                    });
                  } else {
                    Swal.fire({
                      icon: "success",
                      title: "Vendor Updated !!!",
                      text: `Vendor Updated Successfully !!!`,
                    }).then(function () {
                      //dialog.close();
                      //window.location.reload();
                    });
                  }
                })
                .catch((err) => {
                  const { success, error } = err.response.data;
    
                  if (success === false && error !== undefined) {
                    Swal.fire({
                      icon: "error",
                      title: "Error !!!",
                      text: `${error.message && error ? error : err.message}`,
                    });
                  }
                });
  
            }
          } else {
            if (prType === undefined) {
              toast.error("Kindly select the Proof Reader Type!");
            } else if (lang === undefined) {
              toast.error("Kindly select the Language!");
            }
          }
        } else {
          debugger;
          if (lang !== undefined) {
            let vendtype = vendType.value;
            let langtype = lang.value;
  
            let isExists = langData.filter(
              (value, index) =>
                value.type === vendtype && value.language === langtype
            );
  
            if (isExists.length > 0) {
              toast.error("Selected Type and Languages already exists !!!");
            } else {
              row = {
                type: vendType.value,
                language: lang.value,
                oldStatus: "",
                status: "Open",
                active: "Open",
                isNew: true,
                isChanged: true,
                isEdited: true,
                isDeleted: true,
              };
  
              ////newly added for db upate--add new lang for this user
              const url = `${process.env.REACT_APP_URL}/admin/users/updatevendorlang`;
              const headers = reqHeader(true, true, true);
              const config = { headers };
              const body = {
                vendType: vendType.value,
                utype:(prType!=undefined && prType.value!="")?prType.value:"",
                lang:lang.value,
                email:email,
                mobile:mobile
              };
              console.log("body..",body);
              axios
                .put(url, body, config)
                .then((res) => {
                  if (res.data.success === false && res.data.msg) {
                    Swal.fire({
                      icon: "error",
                      title: "Error !!!",
                      text: `${res.data.msg}`,
                    });
                  } else {
                    Swal.fire({
                      icon: "success",
                      title: "Vendor Updated !!!",
                      text: `Vendor Updated Successfully !!!`,
                    }).then(function () {
                      // dialog.close();
                      // window.location.reload();
                    });
                  }
                })
                .catch((err) => {
                  const { success, error } = err.response.data;
    
                  if (success === false && error !== undefined) {
                    Swal.fire({
                      icon: "error",
                      title: "Error !!!",
                      text: `${error.message && error ? error : err.message}`,
                    });
                  }
                });
            }
          } else {
            toast.error("Kindly select the Language Type!");
          }
        }
  
        if (row !== undefined) {
          let langList = [...langData, row];
          //console.log("langList..",langList);
          //console.log("langData..",langData);
          //console.log("row..",row);
          setLangData(langList);
        }
      }else {
        if (vendType === undefined) {
          toast.error("Kindly select the Vendor Type!");
        } else if (lang === undefined && vendType.value !== "Proof Reader") {
          toast.error("Kindly select the Language Type!");
        }
      }
     }

    }else
    {
      toast.error("Vendor email is not available!");
    }
    
  };

  const handleRowDeleteType = (row, e) => {
    e.preventDefault();

    let rows = langData
      .map((val, index) => {
        if (val.type !== row.type) {
          if (val.language !== row.language) {
            return {
              type: val.type,
              language: val.language,
              oldStatus: val.oldStatus,
              status: val.status,
              active: val.active,
              isNew: val.isNew,
              isChanged: val.isChanged,
              isEdited: val.isEdited,
              isDeleted: val.isDeleted,
            };
          }
        } else {
          if (val.language !== row.language) {
            return {
              type: val.type,
              language: val.language,
              oldStatus: val.oldStatus,
              status: val.status,
              active: val.active,
              isNew: val.isNew,
              isChanged: val.isChanged,
              isEdited: val.isEdited,
              isDeleted: val.isDeleted,
            };
          }
        }
      })
      .filter((item) => item);

    setLangData(rows);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();
    console.log("vendor edit data",data);
    debugger;
          const url = `${process.env.REACT_APP_URL}/admin/users/updatevendor`;
          const headers = reqHeader(true, true, true);
          const config = { headers };

          const body = {
            fname: data.fname,
            lname: data.lname,
            email: data.email,
            skypeID: data.skypeID,
            mobile: mobile,
            timings: data.timings,
            hrsPerWeek: data.hrsPerWeek,
            // vendType: vendType === undefined ? [] : vendType,
            // vendTranscriptionLang:
            //   vendTranscriptionLang === undefined ? [] : vendTranscriptionLang,
            // vendTranslationLang:
            //   vendTranslationLang === undefined ? [] : vendTranslationLang,
            // vendSubtitlingLang:
            //   vendSubtitlingLang === undefined ? [] : vendSubtitlingLang,
            // prType: prType === undefined ? [] : prType,
            // prTranscriptionLang:
            //   prTranscriptionLang === undefined ? [] : prTranscriptionLang,
            // prTranslationLang:
            //   prTranslationLang === undefined ? [] : prTranslationLang,
            // prSubtitlingLang:
            //   prSubtitlingLang === undefined ? [] : prSubtitlingLang,
            currency: currency.value,
            active: data.active,
            billingAddress: {
              firstName: data.fname,
              lastName: data.lname,
              invName: data.fname + " " + data.lname,
              GSTIN: data.gstNo,
              email: data.email,
              mobile: mobile,
              address1: data.address1,
              address2: data.address2,
              country: country.label,
              state: data.state,
              city: data.city,
              zipcode: data.zipcode,
            },
          };
          console.log("body..",body);
          axios
            .post(url, body, config)
            .then((res) => {
              if (res.data.success === false && res.data.msg) {
                Swal.fire({
                  icon: "error",
                  title: "Error !!!",
                  text: `${res.data.msg}`,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Vendor Created !!!",
                  text: `Vendor Created Successfully !!!`,
                }).then(function () {
                  dialog.close();
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              const { success, error } = err.response.data;

              if (success === false && error !== undefined) {
                Swal.fire({
                  icon: "error",
                  title: "Error !!!",
                  text: `${error.message && error ? error : err.message}`,
                });
              }
            });
  };
   
  

  const IndeterminateCheckBox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;
      const { value, selectedRow: row, name } = { ...rest };

      const [vals, setVals] = useState(value);

      const OnChangeVals = (e) => {
        setVals(e.target.checked);

        let rows = {
          type: row.type,
          language: row.language,
          oldStatus: row.status,
          status: e.target.checked ? row.oldStatus : "Inactive",
          active: e.target.checked,
          isNew: row.isNew,
          isChanged: !row.isChanged,
          isEdited: row.isEdited,
          isDeleted: row.isDeleted,
        };

        setChangedRow(rows);
        setIsUpdated(false);
      };

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      // const textbox = {
      //   fontFamily: "Arial, Helvetica, sans-serif",
      //   background: "rgba(255, 255, 255, 0.44)",
      //   color: "#333",
      //   border: "1px solid #A4A4A4",
      //   padding: "4px 8px 4px 4px !important",
      //   lineHeight: "1",
      //   width: "100px",
      //   height: "25px",
      //   textAlign: "right",
      // };

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            onChange={OnChangeVals}
            value={vals}
            checked={vals}
            // style={textbox}
            disabled={!row.isEdited}
            // {...rest}
          />
        </>
      );
    }
  );

  useEffect(() => {
    if (changedRow !== undefined && isUpdated === false) {
      let dataList = [];

      langData.forEach((value) => {
        let dataRows;
        if (
          changedRow.type === value.type &&
          changedRow.language === value.language &&
          changedRow.oldStatus === value.status
        ) {
          dataRows = changedRow;
        } else {
          dataRows = {
            type: value.type,
            language: value.language,
            oldStatus: value.oldStatus,
            status: value.status,
            active: value.active,
            isNew: value.isNew,
            isChanged: value.isChanged,
            isEdited: value.isEdited,
            isDeleted: value.isDeleted,
          };
        }

        dataList = [...dataList, dataRows];
      });
      setIsUpdated(true);
      setLangData(dataList);
    }
  }, [changedRow, isUpdated, langData]);

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
              <TabList>
                <Tab>Details</Tab>
                <Tab>Language</Tab>
              </TabList>
              <TabPanel>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className={`inputWrap ${errors.fname ? "error" : ""}`}>
                      <label className="label">
                        First Name<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="fname"
                        ref={register}
                      />
                      {errors.fname && (
                        <span className="inputErrorMsg">
                          {errors.fname?.message}
                        </span>
                      )}
                    </div>
                    <div className="inputWrap">
                      <label className="label">Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        ref={register}
                      />
                    </div>
                    <div className={`inputWrap ${errors.email ? "error" : ""}`}>
                      <label className="label">
                        Email<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        ref={register}
                      />
                      {errors.email && (
                        <span className="inputErrorMsg">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex inputWrap">
                    <div
                      className={`inputWrap ${errors.skypeID ? "error" : ""}`}>
                      <label className="label">Skype ID</label>
                      <input
                        type="text"
                        placeholder="Skype ID"
                        name="skypeID"
                        ref={register}
                      />
                      {errors.skypeID && (
                        <span className="inputErrorMsg">
                          {errors.skypeID?.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="" className="label">
                        Mobile No.
                      </label>

                      <div
                        className={`inputWrap ${
                          formatPhoneNumberIntl(mobile) &&
                          !isValidPhoneNumber(mobile)
                            ? "error"
                            : ""
                        }`}>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          placeholder="Enter Mobile Number"
                          value={formatPhoneNumberIntl(mobile)}
                          onChange={(phone) => setMobile(phone)}
                        />
                        {formatPhoneNumberIntl(mobile) &&
                          !isValidPhoneNumber(mobile) && (
                            <span className="inputErrorMsg">
                              Invalid Mobile Number !!!
                            </span>
                          )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`inputWrap ${errors.address1 ? "error" : ""}`}>
                    <label className="label">Address 1</label>
                    <input
                      type="text"
                      placeholder="Address 1"
                      name="address1"
                      ref={register}
                    />
                    {errors.address1 && (
                      <span className="inputErrorMsg">
                        {errors.address1?.message}
                      </span>
                    )}
                  </div>

                  <div
                    className={`inputWrap ${errors.address2 ? "error" : ""}`}>
                    <label className="label">Address 2</label>
                    <input
                      type="text"
                      placeholder="Address 2"
                      name="address2"
                      ref={register}
                    />
                    {errors.address2 && (
                      <span className="inputErrorMsg">
                        {errors.address2?.message}
                      </span>
                    )}
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">Currency</label>

                      <Controller
                        control={methods.control}
                        name="currencies"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={currency}
                            onChange={OnChangeCurrency}
                            placeholder={"Select Currency"}
                            options={currencies}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                    <div className="inputWrap">
                      <label className="label">Country</label>

                      <Controller
                        control={methods.control}
                        name="countries"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={country}
                            onChange={OnChangeCountries}
                            placeholder={"Select Country"}
                            options={countries}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">State</label>

                      <div className="inputWrap">
                        <input
                          type="text"
                          placeholder="State"
                          name="state"
                          ref={register}
                        />

                        {errors.state && (
                          <span className="inputErrorMsg">
                            {errors.state?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">City</label>
                      <div className="inputWrap">
                        <input
                          type="text"
                          placeholder="City"
                          name="city"
                          ref={register}
                        />
                        {errors.city && (
                          <span className="inputErrorMsg">
                            {errors.city?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Postal Code</label>
                      <div className="inputWrap">
                        <input
                          type="text"
                          placeholder="Postal Code"
                          name="zipcode"
                          ref={register}
                        />
                        {errors.zipcode && (
                          <span className="inputErrorMsg">
                            {errors.zipcode?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="inputWrap">
                      <label className="label">GST No.</label>
                      <div className="inputWrap">
                        <input
                          type="text"
                          placeholder="GST No."
                          name="gstNo"
                          ref={register}
                        />
                        {errors.gstNo && (
                          <span className="inputErrorMsg">
                            {errors.gstNo?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">Timings</label>
                      <select
                        name="timings"
                        ref={register}
                        defaultValue={timing}
                        // onChange={() => setTiming()}
                      >
                        {timings.map((op, index) => (
                          <option key={index} value={op.value}>
                            {op.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Hrs Per Week</label>
                      <div className="inputWrap">
                        <input
                          type="text"
                          placeholder="Hrs Per Week"
                          name="hrsPerWeek"
                          ref={register}
                          // defaultValue="0.00"
                        />
                      </div>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Active</label>
                      <input
                        type="checkbox"
                        placeholder="Active"
                        name="active"
                        defaultChecked={true}
                        style={{ height: "15px" }}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Vendor Type<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="vendType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={vendType}
                            onChange={OnChangeVendType}
                            placeholder={"Select Vendor Type"}
                            options={vendtypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Proof Reader Type
                        <span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="prType"
                        defaultValue={{}}
                        // disabled={
                        //   vendType.value === "Proof Reader" ? false : true
                        // }
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={prType}
                            onChange={OnChangePrType}
                            placeholder={"Select Reader Type"}
                            options={prtypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="lang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={lang}
                            onChange={OnChangeLang}
                            placeholder={"Select Languages"}
                            options={languages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <button
                    className="orangeBtn btn"
                    onClick={handleRowAddType}
                    style={{ float: "right" }}>
                    Add
                  </button>

                  <Table columns={cols} data={dataList} pagination={false} />

                  {/* <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Vendor Type<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="vendType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={vendType}
                            onChange={OnChangeVendType}
                            placeholder={"Select Vendor Type"}
                            isMulti={true}
                            options={vendtypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Proof Reader Type
                        <span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="prType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={prType}
                            onChange={OnChangePrType}
                            placeholder={"Select Reader Type"}
                            isMulti={true}
                            options={prtypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div> */}

                  {/* <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Transcription Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="vendTranscriptionLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={vendTranscriptionLang}
                            onChange={OnChangeTranscriptionVendLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={vendlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Translation Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="vendTranslationLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={vendTranslationLang}
                            onChange={OnChangeTranslationVendLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={vendlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Subtitling Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="vendSubtitlingLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={vendSubtitlingLang}
                            onChange={OnChangeSubtitlingVendLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={vendlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div> */}

                  {/* <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        PR Transcription Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="prTranscriptionLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={prTranscriptionLang}
                            onChange={OnChangeTranscriptionPrLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={prlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        PR Translation Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="prTranslationLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={prTranslationLang}
                            onChange={OnChangeTranslationPrLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={prlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        PR Subtitling Languages
                        <span className="requiredField">*</span>
                      </label>
                      <Controller
                        control={methods.control}
                        name="prSubtitlingLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={prSubtitlingLang}
                            onChange={OnChangeSubtitlingPrLang}
                            placeholder={"Select Languages"}
                            isMulti={true}
                            options={prlanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div> */}
                </div>
              </TabPanel>
            </Tabs>
            {/* <button className="orangeBtn btn">Submit</button> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default EditVendor;
