//Packages
import React, { useState, useEffect, useMemo } from "react";
import { CustomDialog } from "react-st-modal";
import axios from "axios";

//Custom Components
import Table from "../../Table/Table";
import reqHeader from "../../../helper/reqHeader.js";
import EditCustomer from "./EditCustomer.jsx";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components";

const CustomersList = (props) => {
  const { customerLists } = props;

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <span> {row.original.fname + " " + row.original.lname}</span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Type",
        accessor: "custType",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span> {value ? "Yes" : "No"}</span>,
      },
      {
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              value={value}
              onClick={(e) => editCustomer(row.original, e)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  useEffect(() => {
    let controller = new AbortController();

    async function getCustomers() {
      const url = `${process.env.REACT_APP_URL}/admin/users/getallcustomers`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          let custList = [];

          data.forEach((cus) => {
            custList = [...custList, cus];
          });

          setDataList(custList);
          customerLists(custList);
        })
        .catch((err) => {
          setDataList([]);
          customerLists([]);
        });
    }

    getCustomers();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const editCustomer = async (row, e) => {
    e.preventDefault();

    await CustomDialog(<EditCustomer row={row} />, {
      title: "Edit Customer",
      showCloseIcon: true,
    });
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Customers"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default CustomersList;
