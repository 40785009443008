//Packages
import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import axios from "axios";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

const FileCancelBatch = (props) => {
  const dialog = useDialog();

  const { selectedRows } = props;
  console.log("Selected rows...",selectedRows);

  const [reasons, setReasons] = useState(""); //For getting the reason for cancelling the assignment

  const handleCancel = async () => {
    if (reasons !== "") {
      let counter = 0;
      if (selectedRows.length > 0) {
        await Promise.all(
          selectedRows.map(async (value, index) => {
            console.log("value...",value);
            let url =`${process.env.REACT_APP_URL}/admin/translation/updatefile/${value.id}`;
            console.log("url",url);
              // value.serviceType === "Transcription"
              //   ? `${process.env.REACT_APP_URL}/admin/transcription/updatefile/${value.id}`
              //   : value.serviceType === "Translation"
              //   ? `${process.env.REACT_APP_URL}/admin/translation/updatefile/${value.id}`
              //   : `${process.env.REACT_APP_URL}/admin/subtitling/updatefile/${value.id}`;

            const headers = reqHeader(true, true, true);

            let arrID;
            if (value.serviceType === "Translation") {
              if (
                value.stage === "Open" ||
                value.stage === "Paid" ||
                value.stage === "Translation Completed" ||
                value.stage === "Proof Reading Completed"
              ) {
                arrID = "";
              } else if (value.stage === "Translation") {
                arrID = value.translation[value.translation.length - 1]._id;
              } else if (
                value.stage === "Translation Assigned" ||
                value.stage === "Translattion Inprogress"
              ) {
                arrID = value.translation[value.translation.length - 1]._id;
               } 
              else if (value.stage === "Proof Reading" || value.stage === "Proof Reading Assigned" || value.stage === "Proof Reading Inprogress") {
                arrID = value.proofReading[value.proofReading.length - 1]._id;
              }
            } else if (value.serviceType === "Subtitling") {
              if (
                value.stage === "Open" ||
                value.stage === "Paid" ||
                value.stage === "Subtitling Completed" ||
                value.stage === "Proof Reading Completed"
              ) {
                arrID = "";
              } else if (value.stage === "Subtitling") {
                arrID = value.subtitling[value.subtitling.length - 1]._id;
              } else if (value.stage === "Proof Reading") {
                arrID = value.proofReading[value.proofReading.length - 1]._id;
              }
            }

            let params = {
              msg: `File assigned has been cancelled !!!`,
              stage: value.stage,
              arrID: arrID,
              stat: "cancel",
            };
            console.log("params",params);

            let config = { headers, params };

            let body;

           
            if (value.serviceType === "Translation") {
              if (
                value.stage === "Translation Assigned" ||
                value.stage === "Translation Inprogress"
              ) {
                body = {
                  translation: {
                    _id: value.translation[value.translation.length - 1]
                      ._id,
                    translator:
                      value.translation[value.translation.length - 1]
                        .translator,
                    name: value.translation[value.translation.length - 1]
                      .name,
                    email:
                      value.translation[value.translation.length - 1].email,
                    assignedOn:
                      value.translation[value.translation.length - 1]
                        .assignedOn,
                    percentageOfCompletion:
                      value.translation[value.translation.length - 1]
                        .percentageOfCompletion,
                    ETA: value.translation[value.translation.length - 1]
                      .ETA,
                    currency:
                      value.translation[value.translation.length - 1]
                        .currency,
                    cost: value.translation[value.translation.length - 1]
                      .cost,
                    amount:
                      value.translation[value.translation.length - 1]
                        .amount,
                    balAmount:
                      value.translation[value.translation.length - 1]
                        .amount,
                    appStatus:
                      value.translation[value.translation.length - 1]
                        .appStatus,
                    docStatus: "Cancelled",
                    completedOn: null,
                    instructions:
                      value.translation[value.translation.length - 1]
                        .instructions,
                    cancelReason: reasons,
                    cancelledOn: Date.now(),
                    rateID:
                      value.translation[value.translation.length - 1]
                        .rateID,
                    acceptedOn:
                      value.translation[value.translation.length - 1]
                        .acceptedOn,
                    rejectReason:
                      value.translation[value.translation.length - 1]
                        .rejectReason,
                    rejectedOn:
                      value.translation[value.translation.length - 1]
                        .rejectedOn,
                    isAutoTranslated : value.translation[value.translation.length - 1]
                    .isAutoTranslated, 
                    },
                };
              } else if (
                value.stage === "Proof Reading Assigned" ||
                value.stage === "Proof Reading"||value.stage === "Proof Reading Inprogress"
              ) {
                body = {
                  proofReading: {
                    _id: value.proofReading[value.proofReading.length - 1]._id,
                    proofReader:
                      value.proofReading[value.proofReading.length - 1]
                        .proofReader,
                    name: value.proofReading[value.proofReading.length - 1]
                      .name,
                    email:
                      value.proofReading[value.proofReading.length - 1].email,
                    assignedOn:
                      value.proofReading[value.proofReading.length - 1]
                        .assignedOn,
                    percentageOfCompletion:
                      value.proofReading[value.proofReading.length - 1]
                        .percentageOfCompletion,
                    ETA: value.proofReading[value.proofReading.length - 1].ETA,
                    currency:
                      value.proofReading[value.proofReading.length - 1]
                        .currency,
                    cost: value.proofReading[value.proofReading.length - 1]
                      .cost,
                    amount:
                      value.proofReading[value.proofReading.length - 1].amount,
                    balAmount:
                      value.proofReading[value.proofReading.length - 1].amount,
                    appStatus:
                      value.proofReading[value.proofReading.length - 1]
                        .appStatus,
                    docStatus: "Cancelled",
                    completedOn: null,
                    instructions:
                      value.proofReading[value.proofReading.length - 1]
                        .instructions,
                    cancelReason: reasons,
                    cancelledOn: Date.now(),
                    rateID:
                      value.proofReading[value.proofReading.length - 1].rateID,
                    acceptedOn:
                      value.proofReading[value.proofReading.length - 1]
                        .acceptedOn && value.proofReading[value.proofReading.length - 1]
                        .acceptedOn!=""?value.proofReading[value.proofReading.length - 1]
                        .acceptedOn:null,
                    rejectReason:
                      value.proofReading[value.proofReading.length - 1]
                        .rejectReason && value.proofReading[value.proofReading.length - 1]
                        .rejectReason!=""?value.proofReading[value.proofReading.length - 1]
                        .rejectReason:"",
                    rejectedOn:
                      value.proofReading[value.proofReading.length - 1]
                        .rejectedOn && value.proofReading[value.proofReading.length - 1]
                        .rejectedOn!=""?value.proofReading[value.proofReading.length - 1]
                        .rejectedOn:null,                    
                  },
                };
              }
            } else {
              body = {};
            }

            await axios
              .put(url, body, config)
              .then((res) => {
                counter = counter + 1;
              })
              .catch((err) => {});

            url = `${process.env.REACT_APP_URL}/admin/transcription/updateassigndurations`;

            params = {
              language: value.language,
              domain: value.domain,
              frequency: value.frequency,
              projectID: value.projectID,
              id: value.id,
            };

            config = { headers, params };

            await axios
              .put(url, {}, config)
              .then((res) => {})
              .catch((err) => {});
          })
        );

        if (selectedRows.length === counter) {
          Swal.fire({
            icon: "success",
            title: "Files Cancelled !!!",
            text: `Files Cancelled !`,
          }).then(function () {
            dialog.close();
            window.location.reload();
          });
        }
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Required !!!",
        text: `Reasons cannot be empty !!!`,
      });
    }
  };

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <div className="inputWrap instructionarea">
                <label className="label">
                  Reasons<span className="requiredField">*</span>
                </label>
                <textarea
                  placeholder="Reasons"
                  name="reasons"
                  maxLength="1000"
                  value={reasons}
                  onChange={(e) => {
                    setReasons(e.target.value);
                  }}></textarea>
              </div>
            </div>

            <div className="footerAction">
              <button className="orangeBtn btn" onClick={() => handleCancel()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileCancelBatch;
