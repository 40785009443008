//Packages
import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

toast.configure();

const FileClosureBatch = (props) => {
  const dialog = useDialog();

  const { selectedRows } = props;

  const [remarks, setRemarks] = useState(""); //For getting the Closure Remarks from PM

  const handleCancel = async () => {
    if (remarks !== "") {
      let counter = 0;
      let errMsgList = [];
      let successMsgList = [];

      if (selectedRows.length > 0) {
        await Promise.all(
          selectedRows.map(async (value, index) => {
            const { percentageOfCompletion: filePercCompletion } = value;

            counter = counter + 1;

            if (parseFloat(filePercCompletion) < 100) {
              errMsgList.push(value.fileName);
            } else {
              const url =
                value.serviceType === "Transcription"
                  ? `${process.env.REACT_APP_URL}/admin/transcription/fileClosure/${value.id}`
                  : value.serviceType === "Translation"
                  ? `${process.env.REACT_APP_URL}/admin/translation/fileClosure/${value.id}`
                  : `${process.env.REACT_APP_URL}/admin/subtitling/fileClosure/${value.id}`;

              const headers = reqHeader(true, true, true);
              const config = { headers };

              let body = {
                remarks: remarks,
                stage: "Completed",
                status: "Completed",
              };

              await axios
                .put(url, body, config)
                .then((res) => {
                  successMsgList.push(res.data.data.fileName);
                })
                .catch((err) => {});
            }
          })
        );

        if (selectedRows.length === counter) {
          if (errMsgList.length > 0) {
            toast.error(
              <div>
                Files are only Partially Completed; will not be Closed :-
                <br />
                {errMsgList.map((value) => {
                  return (
                    <span key={value}>
                      {value} <br />
                    </span>
                  );
                })}
              </div>
            );
          }

          if (successMsgList.length > 0) {
            toast.success(
              <div>
                Files are Closed :-
                <br />
                {successMsgList.map((value) => {
                  return (
                    <span key={value}>
                      {value} <br />
                    </span>
                  );
                })}
              </div>
            );
          }

          setTimeout(() => {
            dialog.close();
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      toast.error("Remarks cannot be empty.");
    }
  };

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <div className="inputWrap instructionarea">
                <label className="label">
                  Remarks<span className="requiredField">*</span>
                </label>
                <textarea
                  placeholder="Remarks"
                  name="remarks"
                  maxLength="1000"
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}></textarea>
              </div>
            </div>

            <div className="footerAction">
              <button className="orangeBtn btn" onClick={() => handleCancel()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileClosureBatch;
