//Packages
import React, { useState, useEffect } from "react";
import { CustomDialog } from "react-st-modal";
import Split from "react-split";

//Custom Components
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";
import UserProfile from "../Profile/userProfile.jsx";
import NewProject from "../Entities/Projects/NewProject.jsx";
import CustProjectList from "./CustProjectList.jsx";
import LangDomainList from "./LangDomainList.jsx";
import FileList from "./FileList.jsx";

//Styles
import "../../../src/styles.css";

const UpDown = () => {
  const activeNodes = ["002", "002_002"];

  const [customer, setCustomer] = useState({});
  const [clickedLangDomain, setClickedLangDomain] = useState({});

  useEffect(() => {
    setClickedLangDomain({});
  }, [customer]);

  const handleProject = async (props) => {
    await CustomDialog(<NewProject serviceType="Transcription" />, {
      title: "Transcription Project",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="002/002_002" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <ul className="breadcrumb">
              <li>Services</li>
              <li>Transcriptions</li>
              <li>Upload / Download</li>
            </ul>
            <UserProfile />
          </div>

          <div className="d-flex breadcrumWrap">
            <div></div>

            <button className="orangeBtn btn" onClick={handleProject}>
              Add Project
            </button>
          </div>

          <Split className="split" sizes={[50, 50]}>
            <CustProjectList
              serviceType="Transcription"
              type="updown"
              totalDuration={false}
              uploadedDuration={true}
              handledBy={false}
              status={false}
              progress={false}
              setCustomer={setCustomer}
            />
            <LangDomainList
              serviceType="Transcription"
              type="updown"
              langDomainDuration={true}
              status={false}
              progress={false}
              customer={customer}
              setClickedLangDomain={setClickedLangDomain}
            />
          </Split>

          <div>
            <FileList
              serviceType="Transcription"
              type="updown"
              multiSelect={false}
              customer={customer}
              progress={false}
              preview={false}
              download={true}
              clickedLangDomain={clickedLangDomain}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpDown;
