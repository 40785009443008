import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CustomDialog } from "react-st-modal";
import reqHeader from "../../../helper/reqHeader.js";
import Table from "../../Table/Table.jsx";
import EditVendor from "./EditVendor.jsx";
import VendorsRates from "./VendorsRates.jsx";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components";

const VendorList = (props) => {
  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return <span> {row.original.fname + " " + row.original.lname}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Skype ID",
        accessor: "skypeID",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Type",
        accessor: "vendType",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Languages",
        accessor: "languages",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span> {value ? "Yes" : "No"}</span>,
      },
      {
        Header: "Edit",
        className: "center",
        Cell: ({ row }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              onClick={(e) => editVendor(row.original, e)}
            >
              Edit
            </button>
          </div>
        ),
      },
      {
        Header: "Rate",
        className: "center",
        Cell: ({ row }) => (
          <button
            className="rateBtn"
            onClick={(e) => rateMapping(row.original, e)}
          >
            Rate
          </button>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let controller = new AbortController();

    async function getVendors() {
      const url = `${process.env.REACT_APP_URL}/admin/users/getallvendors`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          let vendList = [];

          data.forEach((vend) => {
            let languages = [];

            let vendType = vend.vendType.filter((value) => {
              if (
                value.status === "Partial" ||
                value.status === "Completed" ||
                value.status === "Inactive"
              ) {
                return value.type;
              }
            });

            let prType = vend.prType.filter((value) => {
              if (
                value.status === "Partial" ||
                value.status === "Completed" ||
                value.status === "Inactive"
              ) {
                return value.type;
              }
            });

            let transcriberlanguages = vend.vendTranscriberLang.filter(
              (value) => {
                if (value.status === "Approved") {
                  return value.type;
                }
              }
            );
            let translatorlanguages = vend.vendTranslatorLang.filter(
              (value) => {
                if (value.status === "Approved") {
                  return value.type;
                }
              }
            );
            let subtitlerlanguages = vend.vendSubtitlerLang.filter((value) => {
              if (value.status === "Approved") {
                return value.type;
              }
            });

            languages = [
              ...languages,
              transcriberlanguages,
              translatorlanguages,
              subtitlerlanguages,
            ];

            let prTranscriberLang, prTranslatorLang, prSubtitlerLang;

            if (
              vend.prTranscriberLang.length >= 1 ||
              vend.prTranslatorLang.length >= 1 ||
              vend.prSubtitlerLang.length >= 1
            ) {
              prTranscriberLang = vend.prTranscriberLang.filter((value) => {
                if (value.status === "Approved") {
                  return value.type;
                }
              });
              prTranslatorLang = vend.prTranslatorLang.filter((value) => {
                if (value.status === "Approved") {
                  return value.type;
                }
              });
              prSubtitlerLang = vend.prSubtitlerLang.filter((value) => {
                if (value.status === "Approved") {
                  return value.type;
                }
              });

              languages = [
                ...languages,
                prTranscriberLang,
                prTranslatorLang,
                prSubtitlerLang,
              ];
            }

            let langList = [];

            for (let i = 0; i < languages.length; i++) {
              if (languages[i].length >= 1) {
                for (let j = 0; j < languages[i].length; j++) {
                  let arr = languages[i];
                  langList.push(arr[j].type);
                }
              }
            }

            let uniqueLang = [...new Set(langList.flat())];

            let vendor = {
              _id: vend._id,
              role: vend.role,
              fname: vend.fname,
              lname: vend.lname,
              email: vend.email,
              mobile: vend.mobile,
              skypeID: vend.skypeID,
              billingAddress: vend.billingAddress,
              vendType: vendType.map((value) => value.type).join(", "),
              prType: prType.map((value) => value.type).join(", "),
              languages: uniqueLang.map((value) => value).join(", "),
              currency: vend.currency,
              timings: vend.timings,
              hrsPerWeek: vend.hrsPerWeek,
              active: vend.active,
              vendTypeDetails: vendType,
              prTypeDetails: prType,
              transcriberlanguages: vend.vendTranscriberLang,
              translatorlanguages: vend.vendTranslatorLang,
              subtitlerlanguages: vend.vendSubtitlerLang,
              prTranscriberLang: vend.prTranscriberLang,
              prTranslatorLang: vend.prTranslatorLang,
              prSubtitlerLang: vend.prSubtitlerLang,
            };

            vendList = [...vendList, vendor];
          });

          setDataList(vendList);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getVendors();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const editVendor = async (row, e) => {
    e.preventDefault();

    await CustomDialog(<EditVendor row={row} />, {
      title: "Edit Vendor",
      showCloseIcon: true,
    });
  };

  const rateMapping = async (props, e) => {
    history.push({
      pathname: "/vendorrates",
      state: {
        selectedRow: props,
      },
    });
    // e.preventDefault();
    // await CustomDialog(<VendorsRates row={props} />, {
    //   title: "Vendor Rate",
    //   showCloseIcon: true,
    // });
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Vendors"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default VendorList;
