//Packages
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

const schema = yup.object().shape({
  name: yup
    .string()
    // .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
  factor: yup
    .number()
    .typeError("Factor must be a Number!")
    .required("Factor is required!")
    .min(1, "Minimum value is 1"),
});

const NewCategory = () => {
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      factor: 1.0,
      active: true,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [servicetypes] = useState([
    { label: "Transcription", value: "Transcription" },
    { label: "Translation", value: "Translation" },
    { label: "Subtitling", value: "Subtitling" },
  ]);
  const [serviceType, setServiceType] = useState([]);

  const OnChangeServiceType = (item) => {
    const service = item.map((vals) => vals.value).toString();
    setValue("serviceType", service);
    setServiceType(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (
      data.name !== "" &&
      data.name !== undefined &&
      data.name !== null &&
      data.factor !== "" &&
      data.factor !== undefined &&
      data.factor !== null &&
      serviceType.length > 0
    ) {
      const url = `${process.env.REACT_APP_URL}/admin/category/addcategory`;
      const headers = reqHeader(true, true, true);
      const body = {
        name: data.name,
        factor: data.factor,
        serviceType: serviceType,
        active: data.active,
      };

      const config = { headers };

      axios
        .post(url, body, config)
        .then((res) => {
          const { errList, insertList } = res.data;

          if (errList.length > 0) {
            let msg = errList.map((value, index) => value.type).toString();

            Swal.fire({
              icon: "info",
              title: "Exists !!!",
              text: `${msg} with Factor ${data.factor} already exists !`,
            });

            if (insertList.length > 0) {
              msg = insertList.map((value, index) => value.type).toString();

              Swal.fire({
                icon: "success",
                title: "Created !!!",
                text: `${msg} with Factor ${data.factor} created Succesfully !`,
              }).then(function () {
                window.location.reload();
              });
            }
          } else {
            if (insertList.length > 0) {
              let msg = insertList.map((value, index) => value.type).toString();

              Swal.fire({
                icon: "success",
                title: "Category Created !!!",
                text: `${msg} with Factor ${data.factor} created Succesfully !`,
              }).then(function () {
                dialog.close();
                window.location.reload();
              });
            }
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          let e = "";

          for (const [key, value] of Object.entries(errorList)) {
            const keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1);
            e += `${keyCapitalized} ${value} ${error}`;
          }

          e = e === "" ? error : e;

          if (success === false && error !== undefined) {
            Swal.fire({
              icon: "error",
              title: "Error !!!",
              text: `${e}`,
            });
          }
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      } else if (
        data.factor === "" ||
        data.factor === undefined ||
        data.factor === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Factor is required !!!`,
        });
      } else if (serviceType.length <= 0) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Atleast One Service Type is required !!!`,
        });
      }
    }
  };

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <label className="label">
                    Name<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register}
                  />
                  {errors.name && (
                    <span className="inputErrorMsg">
                      {errors.name?.message}
                    </span>
                  )}
                </div>

                <div className={`inputWrap ${errors.factor ? "error" : ""}`}>
                  <label className="label">
                    Factor<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Factor"
                    name="factor"
                    ref={register}
                  />
                  {errors.factor && (
                    <span className="inputErrorMsg">
                      {errors.factor?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Service Type<span className="requiredField">*</span>
                  </label>

                  <Controller
                    control={methods.control}
                    name="serviceType"
                    defaultValue={{}}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={serviceType}
                        onChange={OnChangeServiceType}
                        placeholder={"Select the Service Type"}
                        isMulti={true}
                        isSearchable={true}
                        options={servicetypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Active</label>
                  <input
                    type="checkbox"
                    placeholder="Active"
                    name="active"
                    defaultChecked={true}
                    style={{ height: "15px" }}
                    ref={register}
                  />
                </div>
              </div>

              <br />
              <button className="orangeBtn btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewCategory;
