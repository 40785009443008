//Packages
import React, { useState, useEffect, useMemo } from "react";
import { CustomDialog } from "react-st-modal";
import axios from "axios";

//Components
import Table from "../../Table/Table";
import EditLanguages from "./EditLanguages.jsx";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components";

const LanguageLists = (props) => {
  const { onClick } = props;

  const [datalist, setDataList] = useState([]);

  let columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "custType",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Code",
        accessor: "code",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Transcription",
        accessor: "isTranscription",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Translation",
        accessor: "isTranslation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Subtitling",
        accessor: "isSubtitling",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        // className: "center",
        className: "col-edit",
        Cell: ({ value, row, cell }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              value={value}
              onClick={(e) => onCellClick(row.original, value, cell)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    async function getLanguages() {
      let url = `${process.env.REACT_APP_URL}/admin/language`;

      let headers = reqHeader(true, true, true);
      let config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          setDataList(res.data.data);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getLanguages();
  }, []);

  const onCellClick = async (row, value, cell) => {
    const { id: cellId } = cell.column;

    if (cellId !== "edit") {
      onClick(row);
    } else {
      await CustomDialog(<EditLanguages selectedRow={row} />, {
        title: "Edit Language",
        showCloseIcon: true,
      });
    }
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      hideTargetDownload={false}
      hideInvoiceDownload={false}
      hideReceiptDownload={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Languages"
      isMultiSelect={false}
      // setSelectedRows={setSelectedRows}
      // setSelectedRowsValues={setSelectedRowsValues}
      getHeaderProps={(column) => ({
        // onClick: () => console.log(`Header Column clicked`, column),
      })}
      getColumnProps={(column, row) => ({
        // onClick: () => setColInfo(column), //console.log(`Column clicked`, column),
      })}
      getRowProps={(row, column) => ({
        // onClick: () => onRowClick(row, column), //console.log(`row clicked`, row),
        // style: {
        //   background: row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
        // },
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default LanguageLists;
