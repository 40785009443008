//Packages
import React, { useState } from "react";
import { CustomDialog } from "react-st-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

//Components
import LeftMenuList from "../../LeftMenu/LeftMenuList.jsx";
import UserProfile from "../../Profile/userProfile";
import NewCustomer from "./NewCustomer.jsx";
import CustomersList from "./CustomersList";

//Styles
import "react-tabs/style/react-tabs.scss";
// import CustomerGraph from "./CustomerGraph";

const Customers = () => {
  const activeNodes = ["001", "001_002"];
  const [customerList, setCustomerList] = useState([]);

  const handleNewCustomer = async (e) => {
    await CustomDialog(<NewCustomer />, {
      title: "Add New Customer",
      showCloseIcon: true,
    });
  };

  return (
    <div className="container projectContainer d-flex">
      <LeftMenuList activeKey="001/001_002" activeNodes={activeNodes} />

      <div className="pageRight">
        <div className="userSection">
          <div className="welcomeUser">Customer</div>

          <UserProfile />
        </div>

        <div className="d-flex breadcrumWrap">
          <ul className="breadcrumb">
            <li>Entities</li>
            <li>Customer</li>
          </ul>

          <div className="d-flex">
            <button
              className="orangeBtn btn"
              onClick={(e) => handleNewCustomer(e)}
            >
              Create a New Customer
            </button>
          </div>
        </div>
        <CustomersList customerLists={setCustomerList} />

        {/* <CustomerGraph dataList={customerList} /> */}
      </div>
    </div>
  );
};

export default Customers;
