//Packages
import React, { useState } from "react";
import { CustomDialog } from "react-st-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

//Custom Components
import UserProfile from "../../Profile/userProfile.jsx";
import NewVendor from "./NewVendor.jsx";
import NewTranslator from "./NewTranslator.jsx";
import ExportToJSON from "../../Utils/ExportToJSON.jsx";
import LeftMenuList from "../../LeftMenu/LeftMenuList.jsx";
// import VendorList from "./VendorList.jsx";
// import VendorWaitingList from "./VendorWaitingList.jsx";

//Styles
import "react-tabs/style/react-tabs.scss";
// import VendorHistory from "./VendorHistory.jsx";
import Tanslators from "./Translators.jsx";

const TranslationVendors = () => {
  const activeNodes = ["001", "001_006"];

  const [tabActive, setTabActive] = useState(0);

  //const tabList = ["Vendor List", "Waiting List"];
  const tabList = ["Vendor List"];
    
  const handleNewTranslator = async (e) => {
    await CustomDialog(<NewTranslator />, {
      title: "Add New Translator",
      showCloseIcon: true,
    });
  };

//   const handleUpload = async () => {
//     await CustomDialog(<ExportToJSON />, {
//       title: "Upload Excel File",
//       showCloseIcon: true,
//     });
//   };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="001/001_006" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">Translators</div>

            <UserProfile />
         </div>

         <div>
            <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
              <TabList className="tabsWrap">
                {tabList.map((value, index) => (
                  <Tab
                    key={index}
                    tabIndex={tabActive}
                    onClick={() => {
                      setTabActive(index);
                    }}
                    className="tabItem"
                    style={
                      tabActive === index
                        ? {
                            color: "#EE6054",
                            fontWeight: "bold",
                            borderBottom: "3px solid #EE6054",
                          }
                        : {}
                    }
                  >
                    {value}
                  </Tab>
                ))}
              </TabList>
              <TabPanel>
                <div className="d-flex breadcrumWrap">
                  <ul className="breadcrumb">
                    <li>Entities</li>
                    <li>Translator Vendors</li>
                  </ul>
                  <div className="d-flex">
                    {/* <button
                      className="greenBtn btn uploadBtn"
                      onClick={(e) => handleUpload(e)}
                    >
                      upload
                    </button> */}
                    <button
                      className="orangeBtn btn"
                      onClick={(e) => handleNewTranslator(e)}
                    >
                      Create A New Translator
                    </button>
                  </div>
                </div>

                <Tanslators/>
              </TabPanel>
              <TabPanel>
                {/* <VendorWaitingList /> */}
              </TabPanel>
              <TabPanel>
                {/* <VendorHistory /> */}
                <Tanslators/>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationVendors;
