//Packages
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { useDialog } from "react-st-modal";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

const EditUser = (props) => {
  const schema = yup.object().shape({
    fname: yup
      .string()
      .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
      .required("First name is required!"),

    // email: yup
    //   .string()
    //   .required("Email is required!")
    //   .email("Enter a valid Email ID!"),
    mobile: yup.string().required("Mobile is required!"),
    // role: yup
    // .array()
    // .nullable()
    // .required("Role is required.")
  });

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, reset } = methods;

  const {name, email, mobile, active, role, _id} = props.row;

  //let isActive = props.row.active == "Yes" ? true : false;


  // use this hook to control the dialog
  const dialog = useDialog();

  const [userRole, setuserRole] = useState("");
  const [roles] = useState([
    { label: "Admin", value: "Admin" },
    { label: "PM", value: "PM" },
    { label: "AdmPR", value: "AdmPR" },
  ]);

  
  //const [active, setActive] = useState(true);

  // const OnChangeRole = (item) => {
  //   setuserRole(item);
  // };

  useEffect(() => {
    if(props){
      reset({
        fname: name,
        email: email,
        mobile: mobile,
        active: active,
        role: role
      })
    }
  }, [name, email, mobile, active, role]);

  const submitHandler = (data, e) => {
    e.preventDefault();
    console.log("submit data", data)

    if (
      data.fname !== "" &&
      data.fname !== undefined &&
      data.fname !== null &&
      data.email !== "" &&
      data.email !== undefined &&
      data.email !== null &&
      data.mobile !== "" &&
      data.mobile !== undefined &&
      data.mobile !== null &&
      role !== undefined &&
      role !== null &&
      role !== ""
    ) {
      const url = `${process.env.REACT_APP_URL}/admin/users/updateuser`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      const body = {
        id: _id,
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        mobile: data.mobile,
        role: role,
        active: data.active,
      };
      console.log("body", body)
      axios
        .put(url, body, config)
        .then((res) => {
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "User Updated !!!",
              text: `${msg}`,
            }).then(function () {
              dialog.close();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error}`,
          });
        });
    } else {
      if (
        data.fname === "" ||
        data.fname === undefined ||
        data.fname === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `First Name is required !!!`,
        });
      }
      // if (
      //   data.email === "" ||
      //   data.email === undefined ||
      //   data.email === null
      // ) {
      //   Swal.fire({
      //     icon: "info",
      //     title: "Required !!!",
      //     text: `Email is required !!!`,
      //   });
      // }
      if (
        data.mobile === "" ||
        data.mobile === undefined ||
        data.mobile === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Mobile is required !!!`,
        });
      }
      // if (role === "" || role === undefined || role === null) {
      //   Swal.fire({
      //     icon: "info",
      //     title: "Required !!!",
      //     text: `Role is required !!!`,
      //   });
      // }
    }
  };

  return (
    <>
      <form className="userFormWrapper" onSubmit={handleSubmit(submitHandler)}>
        <div className={`textFieldContainer inputWrap ${errors.fname ? "error" : ""}`}>
          <div className="titieWrapper">
            <span className="title">First Name</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer">
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              ref={register}
            />
          </div>
          {errors.fname && (
              <span className="errorText">{errors.fname?.message}</span>
            )}
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Last Name</span>
          </div>
          <div className="inputContainer">
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              ref={register}
            />
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Email</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer">
            <input
              type="email"
              placeholder="Email"
              name="email"
              ref={register}
              readOnly
            />
          </div>
          {/* {errors.email && (
              <span className="errorText">{errors.email?.message}</span>
            )} */}
        </div>
        <div className={`textFieldContainer inputWrap ${errors.mobile ? "error" : ""}`}>
          <div className="titieWrapper">
            <span className="title">Mobile</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer">
            <input
              type="number"
              placeholder="Mobile"
              name="mobile"
              ref={register}
            />
          </div>
          {errors.mobile && (
              <span className="errorText">{errors.mobile?.message}</span>
            )}
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Role Type</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer">
          <input type="text" name="role" ref={register} readOnly />
            {/* <Controller
              control={methods.control}
              name="role"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={userRole ? userRole : role}
                  onChange={OnChangeRole}
                  placeholder={"Select Role"}
                  isMulti={false}
                  options={roles}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                  ref={register}
                />
              )}
            /> */}
          </div>
          {/* {errors.role && (
              <span className="errorText">{errors.role?.message}</span>
            )} */}
        </div>
          <div className="bottomButtonSection">
            <div className="checkBoxContainer">
              <input
                type="checkbox"
                placeholder="Active"
                name="active"
                defaultChecked={active}
                //onChange={() => setActive(!active)}
                ref={register}
                className="checkboxStyle"
              />
              <label>Active</label>
            </div>
            {console.log("active", active)}
          <button className="orangeBtn btn">Submit</button>
        </div>
      </form>


    </>
  );
};

export default EditUser;
