import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import "react-toastify/dist/ReactToastify.css";

import reqHeader from "../../../helper/reqHeader.js";
import Table from "../../Table/Table.jsx";
//import LeftMenuList from "../../LeftMenu/LeftMenuList.jsx";

toast.configure();

const VendorsRates = () => {
  const [changedRow, setChangedRow] = useState();
  const [isUpdated, setIsUpdated] = useState(false);

  const [transcriberPrjts, setTranscriberPrjts] = useState([]);
  const [translatorPrjts, setTranslatorPrjts] = useState([]);
  const [subtitlerPrjts, setSubtitlerPrjts] = useState([]);
  const [prTranscriberPrjts, setPrTranscriberPrjts] = useState([]);
  const [prTranslatorPrjts, setPrTranslatorPrjts] = useState([]);
  const [prSubtitlerPrjts, setPrSubtitlerPrjts] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const history = useHistory();

  //  const activeNodes = ["001", "001_002"];

  const location = useLocation();
  const props = location.state !== undefined ? location.state.selectedRow : "";
  //console.log("props", props)
  const {
    _id: id,
    fname,
    lname,
    mobile,
    email,
    vendType,
    languages,
    prType,
    currency,
    translatorlanguages,
    prTranslatorLang
   } = props;


  const vendTypeArr = vendType.split(", ");
  const prTypeArr = prType.split(", ");

  

  //console.log("translatorlanguages,prTranslatorLang",translatorlanguages,prTranslatorLang);
  


  vendTypeArr.push("History");

  useEffect(() => {
    async function getData() {
      let url = `${process.env.REACT_APP_URL}/admin/projects/getvendorratemapping`;
      const headers = reqHeader(true, true, true);
      let params = {
        id: id,
        name: `${fname} ${lname}`,
        email: email,
        vendType: vendType,
        languages: languages,
        prType: prType,
        
      };;
      //console.log("vendTypeArr",vendTypeArr);
                   
      const config = { headers, params };

      //console.log("params...",params);

      await axios
        .get(url, config)
        .then((res) => {

          //console.log("res.data",res.data);
          const {
            transcriberPrjts,
            translatorPrjts,
            subtitlerPrjts,
            prTranscriberPrjts,
            prTranslatorPrjts,
            prSubtitlerPrjts,
          } = res.data;

          let dataList = [];
          //console.log("transcriberPrjts", transcriberPrjts)
          transcriberPrjts.forEach((value, index) => {
            const { _id: id } = value;

            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              language: id.tgtLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });

          setTranscriberPrjts(dataList);

          dataList = [];
          translatorPrjts.forEach((value, index) => {
            const { _id: id } = value;
            //console.log("id(value)",id);
            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              tgtLang: id.tgtLang,
              srcLang:id.srcLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });
          
          setTranslatorPrjts(dataList);

          dataList = [];
          subtitlerPrjts.forEach((value, index) => {
            const { _id: id } = value;

            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              language: id.tgtLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });

          setSubtitlerPrjts(dataList);

          dataList = [];
          //console.log("prTranscriberPrjts",prTranscriberPrjts)
          prTranscriberPrjts.forEach((value, index) => {
            const { _id: id } = value;

            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              language: id.tgtLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });

          setPrTranscriberPrjts(dataList);

          dataList = [];
          prTranslatorPrjts.forEach((value, index) => {
            const { _id: id } = value;

            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              tgtLang: id.tgtLang,
              srcLang:id.srcLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });

          setPrTranslatorPrjts(dataList);

          dataList = [];
          prSubtitlerPrjts.forEach((value, index) => {
            const { _id: id } = value;

            let row = {
              id: index + 1,
              type: id.type,
              vendorID: id.vendorID,
              vendName: id.vendName,
              vendEmail: id.vendEmail,
              custType: id.custType,
              customerID: id.customerID,
              custName: id.custName,
              custEmail: id.custEmail,
              projectID: id.projectID,
              projectName: id.projectName,
              language: id.tgtLang,
              currency: currency,
              rates: id.rates,
            };

            dataList = [...dataList, row];
          });

          setPrSubtitlerPrjts(dataList);
        })
        .catch((err) => {
          setTranscriberPrjts([]);
          setTranslatorPrjts([]);
          setSubtitlerPrjts([]);

          setPrTranscriberPrjts([]);
          setPrTranslatorPrjts([]);
          setPrSubtitlerPrjts([]);
        });
    }

    async function getHistoryData() {
      let url = `${process.env.REACT_APP_URL}/admin/vendorrates/getvendorrates`;
      const headers = reqHeader(true, true, true);
      const params = {
        id: id,
        name: `${fname} ${lname}`,
        email: email,
        vendType: vendType,
        languages: languages,
        prType: prType,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          let dataList = [];
          data.forEach((value, index) => {
            let row = {
              id: value._id,
              serviceType: value.type,
              type: "History",
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rate.toFixed(3),
              isUpdated: false,
            };

            dataList = [...dataList, row];
          });

          setHistoryData(dataList);
        })
        .catch((err) => {
          setHistoryData([]);
        });
    }

    getData();

    getHistoryData();
  }, [id, fname, lname, email, vendType, prType, languages, currency]);

  useEffect(() => {
    if (changedRow !== undefined && isUpdated === false) {
      let dataList = [];
      console.log("changed row transcriberPrjts", transcriberPrjts)
      if (changedRow.type === "Transcriber") {
        transcriberPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setTranscriberPrjts(dataList);
      } else if (changedRow.type === "Translator") {
        translatorPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              tgtLang: id.tgtLang,
              srcLang:id.srcLang,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setTranslatorPrjts(dataList);
      } else if (changedRow.type === "Subtitler") {
        subtitlerPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setSubtitlerPrjts(dataList);
      } else if (changedRow.type === "PR Transcriber") {
        console.log("changed row prTranscriberPrjts", prTranscriberPrjts)
        prTranscriberPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrTranscriberPrjts(dataList);
      } else if (changedRow.type === "PR Translator") {
        prTranslatorPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              tgtLang: id.tgtLang,
              srcLang:id.srcLang,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrTranslatorPrjts(dataList);
      } else if (changedRow.type === "PR Subtitler") {
        prSubtitlerPrjts.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rates,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrSubtitlerPrjts(dataList);
      } else if (changedRow.type === "History") {
        historyData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              serviceType: value.serviceType,
              type: value.type,
              vendorID: value.vendorID,
              vendName: value.vendName,
              vendEmail: value.vendEmail,
              custType: value.custType,
              customerID: value.customerID,
              custName: value.custName,
              custEmail: value.custEmail,
              projectID: value.projectID,
              projectName: value.projectName,
              language: value.language,
              currency: value.currency,
              rates: value.rates,
              isUpdated: value.isUpdated,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setHistoryData(dataList);
      }

      setIsUpdated(true);
    }
  }, [
    changedRow,
    isUpdated,
    transcriberPrjts,
    translatorPrjts,
    subtitlerPrjts,
    prTranscriberPrjts,
    prTranslatorPrjts,
    prSubtitlerPrjts,
    historyData,
  ]);

  const handleSubmit = (type) => {
    const url = `${process.env.REACT_APP_URL}/admin/vendorrates/addvendorrates`;
    const headers = reqHeader(true, true, true);
    const config = { headers };

    let body;

    if (type === "Vendor Transcriber") {
      body = transcriberPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else if (type === "Vendor Translator") {
      body = translatorPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else if (type === "Vendor Subtitler") {
      body = subtitlerPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else if (type === "Proof Reader Transcription") {
      body = prTranscriberPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else if (type === "Proof Reader Translation") {
      body = prTranslatorPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else if (type === "Proof Reader Subtitling") {
      body = prSubtitlerPrjts.filter((value, index) => {
        return parseFloat(value.rates) > 0;
      });
    } else {
      body = historyData.filter((value, index) => {
        return value.isUpdated === true;
      });
    }
    console.log("body..",body);
    axios
      .post(url, body, config)
      .then((res) => {
        if (res.data.success === false && res.data.msg) {
          toast.error("error in saving!");
        } else {
          toast.success(`Vendor Rates created Successfully !`);
          
          setTimeout(() => {
            //window.location.reload();
            if (type === "Vendor Translator"||type === "Proof Reader Translation") {
              history.push({
                pathname: "/translationvendors",
              });
            }
            else{
              history.push({
                pathname: "/vendors",
              });
            }
            
          }, 2000);
        }
      })
      .catch((err) => {
        const { success, error } = err.response.data;

        if (success === false && error !== undefined) {
          toast.error(error);
        }
      });
  };

  const IndeterminateTextbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;
      const { value, selectedRow: row } = { ...rest };

      const [rates, setRates] = useState(value);

      const OnChangeRates = (e) => {
        setRates(e.target.value);

        let rows = {
          id: row.id,
          serviceType: row.serviceType,
          type: row.type,
          vendorID: row.vendorID,
          vendName: row.vendName,
          vendEmail: row.vendEmail,
          custType: row.custType,
          customerID: row.customerID,
          custName: row.custName,
          custEmail: row.custEmail,
          projectID: row.projectID,
          projectName: row.projectName,
          language: row.language,
          srcLang:row.srcLang,
          tgtLang:row.tgtLang,
          currency: row.currency,
          rates: e.target.value,
          isUpdated: true,
        };

        setChangedRow(rows);
        setIsUpdated(false);
      };

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      const textbox = {
        fontFamily: "Arial, Helvetica, sans-serif",
        background: "rgba(255, 255, 255, 0.44)",
        color: "#333",
        border: "1px solid #A4A4A4",
        padding: "4px 8px 4px 4px !important",
        lineHeight: "1",
        width: "100px",
        height: "25px",
        textAlign: "right",
      };

      return (
        <>
          <input
            type="text"
            ref={resolvedRef}
            onChange={OnChangeRates}
            value={rates}
            style={textbox}
            // {...rest}
          />
        </>
      );
    }
  );

  const colsTransSubtitling = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Customer",
        accessor: "custName",
      },
      {
        Header: "Project Name",
        accessor: "projectName",
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Rate / Min",
        accessor: "rates",
        Cell: ({ row, value }) => (
          <div>
            <IndeterminateTextbox value={value} selectedRow={row.original} />
          </div>
        ),
      },
    ],
    []
  );

  const colsTranslating = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Customer",
        accessor: "custName",
      },
      {
        Header: "Project Name",
        accessor: "projectName",
      },
      {
        Header: "Source Language",
        accessor: "srcLang",
      },
      {
        Header: "Target Language",
        accessor: "tgtLang",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Rate / Word",
        accessor: "rates",
        Cell: ({ row, value }) => (
          <div>
            <IndeterminateTextbox value={value} selectedRow={row.original} />
          </div>
        ),
      },
    ],
    []
  );

  const colsHistory = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Type",
        accessor: "serviceType",
      },
      {
        Header: "Customer",
        accessor: "custName",
      },
      {
        Header: "Project Name",
        accessor: "projectName",
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Rate / Min",
        accessor: "rates",
        Cell: ({ row, value }) => (
          <div>
            <IndeterminateTextbox value={value} selectedRow={row.original} />
          </div>
        ),
      },
    ],
    []
  );

  // const dataList = useMemo(
  //   () => [
  //     {
  //       id: 1,
  //       customer: "Amazon",
  //       projectName: "ABC",
  //       language: "Malayalam",
  //       rates: 120,
  //     },
  //     {
  //       id: 2,
  //       customer: "Microsoft",
  //       projectName: "XYZ",
  //       language: "Malayalam",
  //       rates: 100,
  //     },
  //     {
  //       id: 3,
  //       customer: "Maruthi",
  //       projectName: "QWE",
  //       language: "Malayalam",
  //       rates: 150,
  //     },
  //   ],
  //   []
  // );

  const transcriberData = useMemo(() => transcriberPrjts, [transcriberPrjts]);
  const translatorData = useMemo(() => translatorPrjts, [translatorPrjts]);
  const subtitlerData = useMemo(() => subtitlerPrjts, [subtitlerPrjts]);
  const prTranscriberData = useMemo(
    () => prTranscriberPrjts,
    [prTranscriberPrjts]
  );
  const prTranslatorData = useMemo(
    () => prTranslatorPrjts,
    [prTranslatorPrjts]
  );
  const prSubtitlerData = useMemo(() => prSubtitlerPrjts, [prSubtitlerPrjts]);
  const historyDataList = useMemo(() => historyData, [historyData]);

  return (
    <>
      <div className="container projectContainer d-flex">
        {/* <LeftMenuList activeKey="001/001_003" activeNodes={activeNodes} /> */}

        {/* <div className="pageRight d-flex"> */}
          {/* <div className="userSection">
            <ul className="breadcrumb">
              <li>Entites</li>
              <li>Vendors</li>
              <li>On Boarding</li>
            </ul>

            <UserProfile />
          </div> */}

          <div className="contentLeft">
            <div className="block">
              <div className="addProjectDetails">
                <div className="d-flex createOption">
                  <div className="inputWrap">
                    <label className="label">Name</label>
                    <input
                      type="text"
                      placeholder="Vendor Name"
                      name="name"
                      readOnly={true}
                      value={`${fname} ${lname}`}
                    />
                  </div>
                  <div className="inputWrap">
                    <label className="label">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      name="name"
                      readOnly={true}
                      value={email}
                    />
                  </div>
                  <div className="inputWrap">
                    <label className="label">Mobile</label>
                    <input
                      type="text"
                      placeholder="Mobile"
                      name="name"
                      readOnly={true}
                      value={mobile}
                    />
                  </div>
                </div>

                <div className="d-flex createOption">
                  <div className="inputWrap">
                    <label className="label">Vendor Type</label>
                    <input
                      type="text"
                      placeholder="Vendor Type"
                      name="vendType"
                      readOnly={true}
                      value={vendType}
                    />
                  </div>
                  {translatorPrjts.length>0||prTranslatorPrjts.length>0?
                  <div className="inputWrap">
                    <label className="label">Source Languages</label>
                    <input
                      type="text"
                      placeholder="SourceLanguages"
                      name="languages"
                      readOnly={true}
                      value={translatorlanguages}
                    />
                    
                  </div>:
                  <div className="inputWrap">
                    <label className="label">Languages</label>
                    <input
                      type="text"
                      placeholder="Languages"
                      name="languages"
                      readOnly={true}
                      value={languages}
                    />
                  </div>}
                  {/* <div className="inputWrap">
                    <label className="label">Languages</label>
                    <input
                      type="text"
                      placeholder="Languages"
                      name="languages"
                      readOnly={true}
                      value={languages}
                    />
                  </div>
                  <div className="inputWrap"></div> */}
                </div>

                <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
                  <TabList>
                    {vendTypeArr.map((value, index) => (
                      <Tab key={index}> {value}</Tab>
                    ))}
                  </TabList>
                  {vendTypeArr.map((value, index) =>
                    value !== "Proof Reader" ? (
                      <TabPanel key={index}>
                        <Table
                          columns={
                            value === "History"
                              ? colsHistory
                              : value === "Translator"
                              ? colsTranslating
                              : colsTransSubtitling
                          }
                          data={
                            value === "Transcriber"
                              ? transcriberData
                              : value === "Translator"
                              ? translatorData
                              : value === "Subtitler"
                              ? subtitlerData
                              : historyDataList
                          }
                          count={
                            value === "Transcriber"
                              ? transcriberData.length
                              : value === "Translator"
                              ? translatorData.length
                              : subtitlerData.length
                          }
                          filter={false}
                          pagination={true}
                          isMultiSelect={false}
                          setSelectedRows={false}
                          setSelectedRowsValues={false}
                          customText="Projects"
                        />

                        <button
                          className="orangeBtn btn"
                          onClick={() => handleSubmit(`Vendor ${value}`)}>
                          Submit
                        </button>
                      </TabPanel>
                    ) : (
                      <TabPanel key={index}>
                        <Tabs
                          forceRenderTabPanel
                          defaultIndex={0}
                          defaultFocus={true}>
                          <TabList>
                            {prTypeArr.map((value, index) => (
                              <Tab key={index}>{value}</Tab>
                            ))}
                          </TabList>
                          {prTypeArr.map((value, index) => (
                            <TabPanel key={index}>
                              <Table
                                columns={
                                  value === "Translation"
                                    ? colsTranslating
                                    : colsTransSubtitling
                                }
                                data={
                                  value === "Transcription"
                                    ? prTranscriberData
                                    : value === "Translation"
                                    ? prTranslatorData
                                    : prSubtitlerData
                                }
                                count={
                                  value === "Transcription"
                                    ? prTranscriberData.length
                                    : value === "Translation"
                                    ? prTranslatorData.length
                                    : prSubtitlerData.length
                                }
                                pagination={true}
                              />

                              <button
                                className="orangeBtn btn"
                                onClick={() =>
                                  handleSubmit(`Proof Reader ${value}`)
                                }>
                                Submit
                              </button>
                            </TabPanel>
                          ))}
                        </Tabs>
                      </TabPanel>
                    )
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default VendorsRates;
