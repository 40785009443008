import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

class EntitiCategoryPopup extends React.Component {
  render() {
    return (
      <form>
        <div className="custommerFormWrapper">
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">First Name</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="First Name"
                name="fname"
                // ref={register}
              />
              {/* {errors.fname && (
                <text className="errorText">{errors.fname?.message}</text>
              )} */}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Factor</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Factor"
                name="factor*"
                // ref={register}
              />
              {/* {errors.fname && (
                <text className="errorText">{errors.fname?.message}</text>
              )} */}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Service Type</span>
              <span className="requiredField">*</span>
            </div>
            <Select
              // inputRef={ref}
              // name={name}
              // value={prType}
              // onChange={OnChangePrType}
              placeholder={"Select"}
              isMulti={true}
              // options={prtypes}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.value}
            />
          </div>
        </div>
        <div className="bottomButtonSection">
          <div className="checkBoxContainer">
            <input
              type="checkbox"
              placeholder="Active"
              name="active"
              // defaultChecked={active}
              // onChange={() => setActive(!active)}

              className="checkboxStyle"
            />
            <label>Active</label>
          </div>

          <button className="orangeBtn btn">Submit</button>
        </div>
      </form>
    );
  }
}

export default EntitiCategoryPopup;
