//Packages
import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { toast } from "react-toastify";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

toast.configure();

const FileAssignBatch = (props) => {
  const dialog = useDialog();

  const { selectedRows } = props;

  const [properties, setProperties] = useState({}); //On load need to get the params as object properties
  const [assignedList, setAssignedList] = useState([]); //All the Vendor Details w.r.t project, language and allocation
  const [assignedTo, setAssignedTo] = useState(""); //Selected Vendor details
  const [assignedETA, setAssignedETA] = useState(new Date()); //For Assigning the ETA date for the Files
  const [ETA, setETA] = useState(new Date()); //Max Date of the files selected
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  }); //selected vendor details
  const [rate, setRate] = useState({
    rateID: "",
    vendorID: "",
    language: "",
    currency: "",
    rates: Number(0).toFixed(3),
    amounts: Number(0).toFixed(3),
    rate: "INR " + Number(0).toFixed(3),
    amount: "INR " + Number(0).toFixed(3),
  }); //selected vendor rate details
  const [instructions, setInstructions] = useState(""); //for PM instructions while assigning
  const [loaded, setLoaded] = useState(false); //Need to manage the loading of the Assigned To Rates
  const [fileLength, setFileLength] = useState(new Date()); //Max Date of the files selected

  //For getting the common properties like stage, language from the props
  useEffect(() => {
    if (selectedRows.length > 0) {
      let rows = selectedRows.map((value, index) => {
        return {
          stage: value.stage,
          language: value.language,
          type: value.type,
          serviceType: value.serviceType,
          customerID: value.customerID,
          custName: value.custName,
          custType: value.custType,
          projectID: value.projectID,
          projectName: value.projectName,
        };
      });

      let uniqueRows = [
        ...new Map(rows.map((item) => [JSON.stringify(item), item])).values(),
      ];

      const params = Object.assign({}, ...uniqueRows);

      let dates = selectedRows.map((value, index) => {
        return {
          DeliveryDate: value.ETA,
        };
      });

      let uniqueDates = [
        ...new Map(dates.map((item) => [JSON.stringify(item), item])).values(),
      ];

      const ETA = Object.assign({}, ...uniqueDates);

      let currDate = new Date();

      if (currDate < new Date(ETA.DeliveryDate)) {
        setAssignedETA(currDate);
      } else {
        setAssignedETA(new Date(ETA.DeliveryDate));
      }

      let duration = selectedRows
        .map((value, index) => {
          let file = value.fileDuration.split(":");

          let seconds = +file[0] * 60 * 60 + +file[1] * 60 + +file[2];

          return seconds;
          // return {
          //   fileLength: value.fileDuration,
          // };
        })
        .reduce((a, b) => a + b, 0);

      setProperties(params);

      setETA(new Date(ETA.DeliveryDate));

      setFileLength(new Date(duration * 1000).toISOString().substr(11, 8));
    }
  }, [selectedRows]);

  //For filling assignedTo dropdownlist w.r.t service type, language etc
  useEffect(() => {
    async function getAssignedTo() {
      const url = `${process.env.REACT_APP_URL}/admin/users/assigned`;
      const headers = reqHeader(true, true, true);
      const params = {
        stage: stage,
        type: type,
        serviceType: serviceType,
        language: language,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          setAssignedList(
            res.data.data.map(({ _id: id, fname, lname }) => ({
              label: `${id}`,
              value: `${fname} ${lname}`,
            }))
          );
        })
        .catch((err) => {});
    }

    const { stage, type, serviceType, language } = properties;

    if (
      stage !== undefined &&
      type !== undefined &&
      serviceType !== undefined &&
      language !== undefined
    ) {
      getAssignedTo();
    }
  }, [properties]);

  //For getting the selected Assigned Vendor and the rate of the project
  useEffect(() => {
    async function getUserDetails() {
      if (
        assignedTo !== "" &&
        assignedTo !== undefined &&
        assignedTo !== null
      ) {
        let id = assignedTo.label;

        const url = `${process.env.REACT_APP_URL}/admin/users/${id}`;
        const headers = reqHeader(true, true, true);
        const config = { headers };

        await axios
          .get(url, config)
          .then((res) => {
            const { fname, lname, email } = res.data.data;

            setUser({ id: id, name: fname + " " + lname, email: email });
          })
          .catch((err) => {});
      }
    }

    async function getRateDetails() {
      if (
        assignedTo !== "" &&
        assignedTo !== undefined &&
        assignedTo !== null
      ) {
        let id = assignedTo.label;

        const url = `${process.env.REACT_APP_URL}/admin/vendorrates/getvendorratesfile`;
        const headers = reqHeader(true, true, true);
        const params = {
          id: id,
          customerID: properties.customerID,
          custName: properties.custName,
          custType: properties.custType,
          projectID: properties.projectID,
          projectName: properties.projectName,
          language: properties.language,
          serviceType: properties.serviceType,
          stage: properties.stage,
        };
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            const { data } = res.data;

            if (data === undefined || data === null) {
              toast.error("Rate is not set for this vendor.");
              setRate({
                rateID: "",
                vendorID: "",
                language: "",
                currency: "",
                rates: Number(0).toFixed(3),
                amounts: Number(0).toFixed(3),
                rate: "INR " + Number(0).toFixed(3),
                amount: "INR " + Number(0).toFixed(3),
              });
            } else {
              let amount = 0;

              if (
                data.type === "Transcriber" ||
                data.type === "Subtitler" ||
                data.type === "PR Transcriber" ||
                data.type === "PR Subtitler"
              ) {
                const times = fileLength.split(":");

                const hhRate = Number(times[0]) * 60 * data.rate;
                const mmRate = Number(times[1]) * data.rate;
                const ssRate = (Number(times[2]) / 60) * data.rate;
                amount = hhRate + mmRate + ssRate;
              } else {
                amount = data.rate * fileLength;
              }

              setRate({
                rateID: data._id,
                vendorID: data.vendorID,
                language: properties.language,
                currency: data.currency,
                rates: data.rate.toFixed(3),
                amounts: amount.toFixed(3),
                rate: data.currency + " " + data.rate.toFixed(3),
                amount: data.currency + " " + amount.toFixed(3),
              });
            }
          })
          .catch((err) => {});
      }
    }

    getUserDetails();

    if (properties.type === "child" && loaded === false) {
      getRateDetails();

      setLoaded(true);
    }
  }, [
    assignedTo,
    properties.type,
    properties.custName,
    properties.custType,
    properties.customerID,
    properties.language,
    properties.projectID,
    properties.projectName,
    properties.serviceType,
    properties.stage,
    fileLength,
    loaded,
    rate,
  ]);

  //When assigned to is changed
  const OnChangeAssignedTo = (item) => {
    setAssignedTo(item);
    setLoaded(false);
  };

  //When assigned date is changed
  const handleDatePicker = (date) => {
    let selectedDate = date.toDateString();

    if (new Date(selectedDate) <= new Date(ETA)) {
      setAssignedETA(new Date(selectedDate));
    } else {
      setAssignedETA(new Date(assignedETA));
      let formattedDate = format(date, "dd/MM/yyyy");
      toast.error(`ETA cannot be set as ${formattedDate}`);
    }
  };

  //When assigned button is clicked
  const handleAssign = async () => {
    if (assignedTo !== "") {
      if (rate.rates !== Number(0).toFixed(3)) {
        let counter = 0;
        if (selectedRows.length > 0) {
          document.getElementById("btnAssign").disabled = true;
          await Promise.all(
            selectedRows.map(async (value, index) => {
              let url =
                value.serviceType === "Transcription"
                  ? `${process.env.REACT_APP_URL}/admin/transcription/updatefile/${value.id}`
                  : value.serviceType === "Translation"
                  ? `${process.env.REACT_APP_URL}/admin/translation/updatefile/${value.id}`
                  : `${process.env.REACT_APP_URL}/admin/subtitling/updatefile/${value.id}`;

              const headers = reqHeader(true, true, true);

              let arrID;
              if (value.serviceType === "Transcription") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid"
                  //value.stage === "Transcription Completed" ||
                  //value.stage === "Proof Reading Completed"
                ) {
                  arrID = "";
                } else if (value.stage === "Transcription Assigned") {
                  arrID =
                    value.transcription[value.transcription.length - 1]._id;
                } else if (value.stage === "Proof Reading" || value.stage === "Proof Reading Assigned" ) {
                  arrID = value.proofReading[value.proofReading.length - 1]._id;
                }
              } else if (value.serviceType === "Translation") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid" ||
                  value.stage === "Translation Completed" ||
                  value.stage === "Proof Reading Completed"
                ) {
                  arrID = "";
                } else if (value.stage === "Translation Assigned") {
                  arrID = value.translation[value.translation.length - 1]._id;
                } else if (value.stage === "Proof Reading Assigned") {
                  arrID = value.proofReading[value.proofReading.length - 1]._id;
                }
              } else if (value.serviceType === "Subtitling") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid" ||
                  value.stage === "Subtitling Completed" ||
                  value.stage === "Proof Reading Completed"
                ) {
                  arrID = "";
                } else if (value.stage === "Subtitling Assigned") {
                  arrID = value.subtitling[value.subtitling.length - 1]._id;
                } else if (value.stage === "Proof Reading Assigned") {
                  arrID = value.proofReading[value.proofReading.length - 1]._id;
                }
              }

              let params = {
                msg: `File assigned to ${user.name}`,
                stage: value.stage,
                arrID: arrID,
                stat: "insertUpdate",
              };
              console.log("params...",params);
              let config = { headers, params };

              let body;

              if (value.serviceType === "Transcription") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid" ||
                  value.stage === "Transcription Assigned"
                ) {
                  body = {
                    transcription: {
                      transcriber: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      //docStatus: "Assigned",
                      docStatus: "Accepted",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      // acceptedOn: null,
                      acceptedOn: Date.now(),
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                } else if (
                  value.stage === "Transcription Completed" ||
                  value.stage === "Proof Reading Assigned" ||
                  value.stage === "Proof Reading" ||
                  value.stage === "Proof Reader Rejected" ||
                  value.stage === "Proof Reading Completed"
                ) {
                  body = {
                    proofReading: {
                      proofReader: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      //docStatus: "Assigned",
                      docStatus: "Accepted",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      // acceptedOn: null,
                      acceptedOn: Date.now(),
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                }
              } else if (value.serviceType === "Translation") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid" ||
                  value.stage === "Translation Assigned"
                ) {
                  body = {
                    translation: {
                      translator: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      docStatus: "Assigned",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      acceptedOn: null,
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                } else if (
                  value.stage === "Translation Completed" ||
                  value.stage === "Proof Reading Assigned" ||
                  value.stage === "Proof Reading"
                ) {
                  body = {
                    proofReading: {
                      proofReader: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      docStatus: "Assigned",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      acceptedOn: null,
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                }
              } else if (value.serviceType === "Subtitling") {
                if (
                  value.stage === "Open" ||
                  value.stage === "Paid" ||
                  value.stage === "Subtitling Assigned"
                ) {
                  body = {
                    subtitling: {
                      subtitler: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      docStatus: "Assigned",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      acceptedOn: null,
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                } else if (
                  value.stage === "Subtitling Completed" ||
                  value.stage === "Proof Reading Assigned" ||
                  value.stage === "Proof Reading"
                ) {
                  body = {
                    proofReading: {
                      proofReader: user.id,
                      name: user.name,
                      email: user.email,
                      assignedOn: Date.now(),
                      percentageOfCompletion: 0,
                      ETA: assignedETA,
                      currency: rate.currency,
                      cost: rate.rates,
                      amount: rate.amounts,
                      balAmount: rate.amounts,
                      appStatus: "Open",
                      docStatus: "Assigned",
                      completedOn: null,
                      instructions: instructions,
                      cancelReason: "",
                      cancelledOn: null,
                      rateID: rate.rateID,
                      acceptedOn: null,
                      rejectReason: null,
                      rejectedOn: null,
                    },
                  };
                }
              }

              await axios
                .put(url, body, config)
                .then((res) => {
                  counter = counter + 1;
                })
                .catch((err) => {});

              url = `${process.env.REACT_APP_URL}/admin/transcription/updateassigndurations`;

              params = {
                language: value.language,
                domain: value.domain,
                frequency: value.frequency,
                projectID: value.projectID,
                id: value.id,
              };

              config = { headers, params };

              await axios
                .put(url, {}, config)
                .then((res) => {})
                .catch((err) => {});
            })
          );

          if (selectedRows.length === counter) {
            toast.success(`Files has been assigned to ${user.name}`);
            setTimeout(() => {
              dialog.close();
              window.location.reload();
            }, 2000);
          }
        }
      } else {
        toast.error("Cannot Assign the File. Rate is not set for this vendor.");
      }
    } else {
      toast.error("Cannot Assign the File. Assigned To is not selected.");
    }
  };

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">
                  Assigned To <span className="requiredField">*</span>
                </label>
                <Select
                  name="assignedTo"
                  value={assignedTo}
                  onChange={OnChangeAssignedTo}
                  placeholder={"Select Assigned To"}
                  options={assignedList}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              </div>

              <div className="inputWrap">
                <label className="label">
                  Assigned ETA <span className="requiredField">*</span>
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={assignedETA}
                  onChange={(date) => handleDatePicker(date)}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  readOnly={true}
                  value={user.name}
                />
              </div>
              <div className="inputWrap">
                <label className="label">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  readOnly={true}
                  value={user.email}
                />
              </div>
              <div className="inputWrap">
                <label className="label">Delivery Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={ETA}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">
                  {properties.serviceType === "Translation"
                    ? "Word Count"
                    : "Duration"}
                </label>
                <input
                  type="text"
                  placeholder={
                    properties.serviceType === "Translation"
                      ? "Word Count"
                      : "Duration"
                  }
                  name="duration"
                  readOnly={true}
                  value={fileLength}
                />
              </div>

              <div className="inputWrap">
                <label className="label">
                  {properties.serviceType === "Translation"
                    ? "Rate / Words"
                    : "Rate / Min"}
                </label>
                <input
                  type="text"
                  placeholder={
                    properties.serviceType === "Translation"
                      ? "Rate / Words"
                      : "Rate / Min"
                  }
                  name="rate"
                  readOnly={true}
                  value={rate.rate}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Amount</label>
                <input
                  type="text"
                  placeholder="Amount"
                  name="amount"
                  readOnly={true}
                  value={rate.amount}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap instructionarea">
                <label className="label">Instructions</label>
                <textarea
                  placeholder="Instructions"
                  name="instructions"
                  maxLength="1000"
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}></textarea>
              </div>
            </div>

            <div className="footerAction">
              <button className="orangeBtn btn" id="btnAssign" onClick={() => handleAssign()}>
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileAssignBatch;
