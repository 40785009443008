//Packages
import React, { useEffect, useState } from "react";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "../../../node_modules/react-h5-audio-player/lib/styles.css";

const AudioPlayers = (props) => {
  const {
    fileName,
    language,
    fileDuration: fileLength,
    fileLocation: url,
  } = props.selectedRow;

  // use this hook to control the dialog
  // const dialog = useDialog();

  const [urlPath, setUrlPath] = useState("");
  const [urlDateTime, setUrlDateTime] = useState(new Date());
  const [expiryDateTime, setExpiryDateTime] = useState(new Date());

  useEffect(() => {
    async function getSignedURL() {
      const downloadurl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;
      const headers = reqHeader(true, true, true);

      var revisedUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? url.replace("/texlang-cloud-storage/", "/")
          : url.replace("/staging-texlang-cloudstorage/", "/");

      const params = {
        fpath: revisedUrl,
        type: "Transcription",
      };

      const config = { headers, params };

      await axios
        .get(downloadurl, config)
        .then((response) => {
          const { url } = response.data.data;

          setUrlPath(url);

          let googDate = url.split("&")[2];
          let googExpiry = url.split("&")[3];

          if (googDate !== undefined && googExpiry !== undefined) {
            let date = new Date(
              parseInt(googDate.substring(12, 16)),
              parseInt(googDate.substring(16, 18)),
              parseInt(googDate.substring(18, 20)),
              parseInt(googDate.substring(21, 23)),
              parseInt(googDate.substring(23, 25)),
              parseInt(googDate.substring(25, 27)),
              0
            );

            let expiry = parseInt(googExpiry.substring(15, googExpiry.length));

            setUrlDateTime(date);
            setExpiryDateTime(date.setSeconds(date.getSeconds() + expiry));
          }
        })
        .catch((err) => {});
    }
    getSignedURL();
  }, [url]);

  const downloadFile = async () => {
    if (urlDateTime <= expiryDateTime) {
      let fname = url.split("/")[7];

      const link = document.createElement("a");
      link.download = fname;
      link.target = "_blank";
      link.href = urlPath;
      document.body.appendChild(link);
      link.click();
    } else {
      const headers = reqHeader(true, true, true);

      let downloadUrl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;

      var revisedUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? url.replace("/texlang-cloud-storage/", "/")
          : url.replace("/staging-texlang-cloudstorage/", "/");

      const params = {
        fpath: revisedUrl,
        type: "Transcription",
      };

      let fname = url.split("/")[7];

      const config = { headers, params };
      await axios
        .get(downloadUrl, config)
        .then((response) => {
          const { url } = response.data.data;

          const link = document.createElement("a");
          link.download = fname;
          link.target = "_blank";
          link.href = url;
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <AudioPlayer
                className="audioPlayer"
                src={urlPath}
                controls={true}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                style={{
                  background: "#740AF6",
                  padding: "40px",
                  borderRadius: "5px",
                }}
              />
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">File Name</label>
                <input
                  type="text"
                  placeholder="File Name"
                  name="fileName"
                  readOnly={true}
                  value={fileName}
                  title={fileName}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Duration</label>
                <input
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  readOnly={true}
                  value={fileLength}
                  title={fileLength}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Language</label>
                <input
                  type="text"
                  placeholder="Language"
                  name="language"
                  readOnly={true}
                  value={language}
                  title={language}
                />
              </div>
            </div>

            <div className="flexBox">
              <button
                className="fileDownloadBtn"
                onClick={() => downloadFile()}
                style={{
                  marginRight: "10px",
                }}
              >
                Download
              </button>
              <button className="submitButton">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioPlayers;
