//Packages
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { CustomDialog } from "react-st-modal";

//Components
import NewDialect from "./NewDialect.jsx";
import EditDialect from "./EditDialect.jsx";
import Table from "../../Table/Table";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

const LanguageDialects = (props) => {
  const { _id: id, custType, name } = props.language;

  const [datalist, setDataList] = useState([]);
  const [langID, setLangID] = useState();
  const [reload, setReload] = useState(false);

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        width: 50,
        Cell: ({ value, row, cell }) => (
          <div onClick={(e) => onCellClick(row.original, value, cell)}>
            {value}
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="invoiceBtn"
            value={value}
            onClick={(e) => onCellClick(row.original, value, cell)}>
            Edit
          </button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    async function getLangDialects() {
      let url = `${process.env.REACT_APP_URL}/admin/language/getlanguagedialects`;

      let headers = reqHeader(true, true, true);
      const params = {
        id: id,
        custType: custType === "Retail" ? "OC" : "EC",
        langName: name,
      };
      let config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          setDataList(res.data.data);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    if (id !== undefined) {
      getLangDialects();
      setLangID(id);
    }
  }, [id]);

  useEffect(() => {
    async function getLangDialects() {
      let url = `${process.env.REACT_APP_URL}/admin/language/getlanguagedialects`;

      let headers = reqHeader(true, true, true);
      const params = {
        id: langID,
        custType: custType === "Retail" ? "OC" : "EC",
        langName: name,
      };
      let config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          setDataList(res.data.data);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    if (langID !== undefined && reload) {
      getLangDialects();
      setReload(false);
    }
  }, [langID, reload]);

  const onCellClick = async (row, value, cell) => {
    const { id: cellId } = cell.column;

    if (cellId === "edit") {
      const isUpdated = await CustomDialog(<EditDialect selectedRow={row} />, {
        title: "Edit Dialect",
        showCloseIcon: true,
      });

      setReload(isUpdated);
    }
  };

  const onNewClick = async () => {
    const isAdded = await CustomDialog(
      <NewDialect langID={id} langName={name} />,
      {
        title: "New Dialect",
        showCloseIcon: true,
      }
    );

    setReload(isAdded);
  };

  return (
    <>
      <div>
        <button
          disabled={id !== undefined ? false : true}
          onClick={() => onNewClick()}>
          Add
        </button>
        <button disabled={datalist.length > 0 ? false : true}>Export</button>
        <Table
          columns={columns}
          data={datalist}
          count={datalist.length}
          pagination={false}
          expanded={false}
          hideTargetDownload={false}
          hideInvoiceDownload={false}
          hideReceiptDownload={false}
          isHeader={true}
          filter={true}
          isCountDisplayed={true}
          customText="Dialects"
          isMultiSelect={false}
          // setSelectedRows={setSelectedRows}
          // setSelectedRowsValues={setSelectedRowsValues}
          getHeaderProps={(column) => ({
            // onClick: () => console.log(`Header Column clicked`, column),
          })}
          getColumnProps={(column) => ({
            // onClick: () => console.log(`Column clicked`, column),
          })}
          getRowProps={(row) => ({
            // onClick: () => console.log(`row clicked`, row),
            // style: {
            //   background: row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
            // },
          })}
          getCellProps={(cellInfo) => ({})}
        />
      </div>
    </>
  );
};

export default LanguageDialects;
