import axios from "axios";
import React, { useEffect, useMemo, useState, useRef } from "react";
import "../../../src/styles.css";
import reqHeader from "../../helper/reqHeader.js";
import ProgressBar from "../Utils/ProgressBar.jsx";
import Table from "../Table/Table.jsx";
import UploadLangDomain from "../Uploads/UploadLangDomain.jsx";
import { CustomDialog } from "react-st-modal";
import moment from "moment";
import {
  DownloadIcon,
  UploadIcon,
} from "../../svgIconComponent/svg_icon_components";
import DomainDownloadFiles from "../Downloads/DomainDownloadFiles";

const SrcTgtLangList = (props) => {
  const {
    type,
    customer,
    setClickedSrcTgtLang,
    clickedSrcTgtLang,
    langDomainDuration,
    totalDuration,
    progress,
    status,
  } = props;
  //console.log("customer..",customer);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRowsValues, setSelectedRowsValues] = useState({});

  console.log("customer", customer);
  //console.log("clickedSrcTgtLang",clickedSrcTgtLang);

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "srctgtlang",
        sortType: "basic",
        filter: "text",
        sticky: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              title="Click for the detailed File List"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original)}
            >
              {row.original.srctgtlang}
            </div>
          );
        },
      },
      {
        Header: "Closure",
        accessor: "percentageOfCompletion",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original)}
            >
              <ProgressBar completed={row.original.percentageOfCompletion} />
            </div>
          );
        },
      },
      // {
      //   Header: "Progress / Total",
      //   accessor: "completedDuration",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !langDomainDuration,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {type === "track"
      //           ? `${row.original.completedDuration} / ${row.original.uploadedDuration}`
      //           : type === "assign"
      //           ? `${row.original.assignedDuration} / ${row.original.uploadedDuration}`
      //           : `${row.original.uploadedDuration} / ${row.original.duration} `}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !status,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {row.original.status}
      //       </div>
      //     );
      //   },
      // },

      {
        Header: "Upload",
        accessor: "upload",
        sortType: "basic",
        filter: "text",
        hidden: type === "updown" ? false : true,
        Cell: ({ value, column, row }) => {
          return (
            <button
              style={{ cursor: "pointer" }}
              title="Click for the detailed File List"
              className="uploadBtb"
              onClick={() => handleUpload(row.original)}
              disabled={row.original.status !== "Completed" ? false : true}
            >
              <UploadIcon />
              &nbsp; Upload
            </button>
          );
        },
      },
      {
        Header: "Download",
        accessor: "download",
        sortType: "basic",
        filter: "text",
        hidden: type === "updown" ? false : true,
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return (
            <button
              className="downloadBtn"
              title="Click for the detailed File List"
              onClick={() => handleDownload(row.original)}
            >
              <DownloadIcon />
              &nbsp; Download
            </button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const getLanguageDomains = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/translation/projectsrctgtlanglist`;
      const headers = reqHeader(true, true, true);
      const params = {
        customerID: customer.customerID,
        projectID: customer._id,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          console.log("src tgt lang data", data)
        // let uniqueSrcTgtLanglist = [
        //   ...new Map(data.map((item) => [JSON.stringify(item.srctgtlang), item.srctgtlang,item.percentageOfCompletion])).values(),
        // ];

        // console.log("uniqueSrcTgtLanglist",uniqueSrcTgtLanglist);
  
        // const uniquedata = Object.assign({}, ...uniqueSrcTgtLanglist);
        // console.log("uniquedata..",uniquedata);
          let uniquedata = data.filter(function (a) {
                var key = a.srctgtlang + '|' + a.percentageOfCompletion;
                if (!this[key]) {
                    this[key] = true;
                    return true;
                }
            }, Object.create(null));
          console.log("uniquedata..",uniquedata);
          setData(uniquedata);
        })
        .catch((err) => {
          setData([]);
        });
    };

    if (customer && Object.keys(customer).length !== 0) {
      getLanguageDomains();
    }
  }, [customer]);

  const handleClick = (row) => {
    //console.log("row..",row);
    //console.log("customer..",customer);
    if (row !== undefined) {
      setClickedSrcTgtLang(row);
    } else {
      setClickedSrcTgtLang({});
    }
  };

  const handleUpload = async (row) => {
    let upldDetails = {
      domainID: row._id,
      id: row.projectID,
      projectName: row.projectName,
      customerID: row.customerID,
      custName: row.custName,
      custEmail: row.custEmail,
      custType: row.custType,
      custCode: row.custCode,
      srcLang: row.language,
      tgtFormat: row.language,
      isToolNeeded: row.isToolNeeded,
      ETA: row.ETA,
      // domains: [
      //   {
      //     domainID: row._id,
      //     language: row.language,
      //     domain: row.domain,
      //     frequency: row.frequency,
      //     duration: row.duration,
      //     upDuration: row.uploadedDuration,
      //     folderPath: row.folderPath,
      //   },
      // ],
      serviceType: "Translation",
      tgtFormat: row.language,
    };

    await CustomDialog(
      <UploadLangDomain
        selectedRow={upldDetails}
        serviceType={upldDetails.serviceType}
        src="LangDomain"
      />,
      {
        title: "Upload Target File",
        showCloseIcon: false,
      }
    );
  };

  const handleDownload = async (row) => {
    await CustomDialog(<DomainDownloadFiles selectedRow={row} />, {
      title: "File Downloads",
      showCloseIcon: true,
    });
  };

  return (
    <div className="tableContainer">
      <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
      </div>
      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText={customer.projectName}
        //customer={customer}
        setClickedSrcTgtLang={setClickedSrcTgtLang}
        isMultiSelect={false}
        setSelectedRows={setSelectedRows}
        setSelectedRowsValues={setSelectedRowsValues}
      />
    </div>
  );
};

export default SrcTgtLangList;
