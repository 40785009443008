//Packages
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Swal from "sweetalert2";
import { useDialog } from "react-st-modal";

//Custom Components
import Table from "../../Table/Table.jsx";

//Helper
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "react-tabs/style/react-tabs.scss";

const schema = yup.object().shape({
  name: yup
    .string()
    // .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
  hrs: yup
    .string()
    // .typeError("Number of Hours must be a Number!")
    .required("Total Duration is required!")
    .min(1, "Minimum value is 1"),
});

const NewProject = (props) => {
  const { serviceType } = props;

  // use this hook to control the dialog
  const dialog = useDialog();

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      hrs: "01:00:00",
      eta: new Date(),
      custType: "",
      customer: "",
      language: "",
      srcType: "",
      tgtType: "",
      isToolNeeded: false,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  const [custtypes] = useState([
    { label: "Retail", value: "Retail" },
    { label: "Enterprise", value: "Enterprise" },
  ]);
  const [custType, setCustType] = useState([]);

  const [srctypes] = useState([
    { label: ".mp3", value: ".mp3" },
    { label: ".wav", value: ".wav" },
    { label: ".m4a", value: ".m4a" },
  ]);
  const [srcType, setSrcType] = useState([]);

  const [tgttypes] = useState([
    { label: "JSON", value: "JSON" },
    { label: "Text", value: "Text" },
  ]);
  const [tgtType, setTgtType] = useState([]);

  const [customers, setCustomers] = useState();
  const [customer, setCustomer] = useState([]);

  const [languages, setLanguages] = useState();
  const [language, setLanguage] = useState([]);
  const [ETA, setETA] = useState(new Date());

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState();
  const [frequency, setFrequency] = useState("8");
  const [hrsDomain, setHrsDomain] = useState("00:00:00");
  const [langData, setLangData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRowsValues, setSelectedRowsValues] = useState({});

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const cols = useMemo(
    () => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Domain",
        accessor: "domain",
      },
      {
        Header: "Frequency (Khz)",
        accessor: "frequency",
      },
      {
        Header: "Hrs / Domain",
        accessor: "hrsDomain",
      },
    ],
    []
  );

  useEffect(() => {
    if (custtypes.length > 0) {
      setCustType(custtypes[1]);
      setValue("custType", custtypes[1].value);
      setSrcType(srctypes[1]);
      setValue("srcType", srctypes[1].value);
      setTgtType(tgttypes[1]);
      setValue("tgtType", tgttypes[1].value);
    }
  }, [custtypes, srctypes, tgttypes, setValue]);

  useEffect(() => {
    const getDomains = async () => {
      if (custType !== undefined) {
        const url = `${process.env.REACT_APP_URL}/admin/domain/getdomain`;
        const headers = reqHeader(true, true, true);
        // const params = {
        //   type: "Transcription",
        // };
        const config = { headers };

        await axios
          .get(url, config)
          .then((res) => {
            setDomains(
              res.data.data.map(({ name, code, _id: id }) => ({
                id: id,
                label: name,
                value: name,
                name: name,
                code: code,
              }))
            );
            // setCountries(res.data.data);
          })
          .catch((err) => {
            setDomains([]);
          });
      }
    };

    getDomains();
  }, []);

  useEffect(() => {
    const getCustomers = async () => {
      if (custType !== undefined) {
        const url = `${process.env.REACT_APP_URL}/admin/users/activecustomerswithtype`;
        const headers = reqHeader(true, true, true);
        const params = { type: custType !== undefined ? custType.value : "" };
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setCustomers(
              res.data.data.map(({ name, email, custType, code, _id: id }) => ({
                id: id,
                label: name,
                value: name,
                name: name,
                custType: custType,
                custCode: code,
                email: email,
              }))
            );
            // setCountries(res.data.data);
          })
          .catch((err) => {
            setCustomers();
          });
      }
    };

    getCustomers();
  }, [custType]);

  useEffect(() => {
    const getLanguages = async () => {
      if (custType !== undefined || customer !== undefined) {
        const url = `${process.env.REACT_APP_URL}/admin/language/activelanguageswithcustomer`;
        const headers = reqHeader(true, true, true);
        const params = {
          custType: customer !== undefined ? custType.value : "",
          serviceType: serviceType,
        };
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setLanguages(
              res.data.data.map(({ name, code, _id: id }) => ({
                id: id,
                label: name,
                value: name,
                code: code,
              }))
            );
            // setCountries(res.data.data);
          })
          .catch((err) => {
            setLanguages();
          });
      }
    };

    getLanguages();
  }, [serviceType, custType, customer]);

  useEffect(() => {
    if (language !== undefined) {
      let langs = language.map((val) => {
        return {
          id: val.id,
          label: val.label,
          value: val.value,
          code: val.code,
        };
      });

      setSelectedLanguages(langs);
    }
  }, [language]);

  const OnChangeCustType = (item) => {
    setValue("custType", item.value);
    setCustType(item);
    setValue("customer", "");
    setCustomer([]);
    setValue("language", "");
    setLanguage([]);
  };

  const OnChangeCustomer = (item) => {
    setValue("customer", item.value);
    setCustomer(item);
    setValue("language", "");
    setLanguage([]);
  };

  const OnChangeLanguages = (item) => {
    const lang = item.map((vals) => vals.value).toString();
    setValue("language", lang);
    setLanguage(item);

    if (langData !== undefined && langData.length > 0) {
      let fillData = langData;

      item.forEach((val, index) => {
        // let filterData = index === 0 ? langData : fillData;

        let filterData = fillData;

        let newData = filterData.filter(
          (value) => value.language === val.value
        );

        fillData = newData;
      });

      if (item.length === 0) {
        setLangData([]);
      } else {
        setLangData(fillData);
      }
    }
  };

  const OnChangeSrcType = (item) => {
    setValue("srcType", item.value);
    setSrcType(item);
  };

  const OnChangeTgtType = (item) => {
    setValue("tgtType", item.value);
    setTgtType(item);
  };

  const handleDatePicker = (date) => {
    let selectedDate = date.toDateString();
    setETA(new Date(selectedDate));
    setValue("eta", new Date(selectedDate));
  };

  const OnChangeDomain = (item) => {
    setDomain(item);
  };

  const OnChangeSelectedLanguages = (item) => {
    setSelectedLanguage(item);
  };

  const handleAddDomains = (e) => {
    e.preventDefault();

    let maxID = Math.max(...langData.map((value) => value.id));

    if (
      selectedLanguage !== undefined &&
      domain !== undefined &&
      frequency !== "" &&
      hrsDomain !== ""
    ) {
      const [hours, minutes, seconds] = hrsDomain.split(":");
      const totalSecs = +hours * 60 * 60 + +minutes * 60 + +seconds;

      if (totalSecs <= 0) {
        Swal.fire({
          icon: "error",
          title: "Error !!!",
          text: `Kindly enter the Hours Per Domain !`,
        });

        return;
      }

      let isExists = langData.filter(
        (value) =>
          selectedLanguage.value === value.language &&
          domain.value === value.domain &&
          frequency === value.frequency
      );

      if (isExists.length > 0) {
        Swal.fire({
          icon: "error",
          title: "Error !!!",
          text: `Language with Domain already added !`,
        });

        return;
      }

      let row = {
        id: maxID === -Infinity ? 1 : maxID + 1,
        language: selectedLanguage.value,
        domain: domain.value,
        frequency: frequency,
        hrsDomain: hrsDomain,
        domainCode: domain.code,
        langCode: selectedLanguage.code,
      };

      setLangData((current) => [...current, row]);
    } else {
      if (selectedLanguage === undefined) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Language is required !!!`,
        });
      } else if (domain === undefined) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Domain is required !!!`,
        });
      } else if (frequency === "") {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Frequency is required !!!`,
        });
      } else if (hrsDomain === "") {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Kindly enter the Hours Per Domain !!!`,
        });
      }
    }
  };

  const handleRemoveDomain = (e) => {
    e.preventDefault();
    let rows = selectedRowsValues.map((value, index) => value.original);

    if (rows.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Required !!!",
        text: `Select atleast One Domain from the list !!!`,
      });
      return;
    }

    let fillData = langData; // [];

    rows.forEach((val, index) => {
      // let filterData = index === 0 ? langData : fillData;
      let filterData = fillData;

      let newData = filterData
        .map((value, index) => {
          if (value.id === val.id) {
          } else {
            return {
              id: value.id,
              language: value.language,
              domain: value.domain,
              frequency: value.frequency,
              hrsDomain: value.hrsDomain,
              domainCode: value.domainCode,
              langCode: value.langCode,
            };
          }
        })
        .filter((value) => {
          return value;
        });

      fillData = newData;
    });

    setLangData(fillData);
  };

  const submitHandler = (data, e) => {
    // e.preventDefault();

    if (
      data.name !== "" &&
      data.name !== undefined &&
      data.name !== null &&
      data.hrs !== "" &&
      data.hrs !== undefined &&
      data.hrs !== null &&
      data.custType !== "" &&
      data.language !== "" &&
      langData.length > 0
    ) {
      const [hours, minutes, seconds] = data.hrs.split(":");
      const durationSecs = +hours * 60 * 60 + +minutes * 60 + +seconds;

      let domainHrsMns = langData.map((value) => {
        const [hrs, mins, secs] = value.hrsDomain.split(":");
        const durSecs = +hrs * 60 * 60 + +mins * 60 + +secs;
        return Number(durSecs);
      });

      const totalDomainDuration = domainHrsMns.reduce((a, b) => a + b, 0);

      if (totalDomainDuration > durationSecs) {
        Swal.fire({
          icon: "info",
          title: "Exceeded !!!",
          text: `Total Domain Duration is higher than Project Duration !!!`,
        });

        return;
      }

      let momentdatetime = moment().format();
      let currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
      let ind = customer.email.indexOf("@");
      // let folder = customer.email.substr(0, ind) + currentDateTime;
      let folder = customer.custCode + "_" + data.name.replaceAll(" ", "_");

      const url = `${process.env.REACT_APP_URL}/admin/transcription/addproject`;
      const headers = reqHeader(true, true, true);
      const body = {
        projectName: data.name,
        totalDuration: data.hrs,
        eta: ETA, //data.eta,
        customerID: customer.id,
        custName: customer.name,
        custEmail: customer.email,
        custType: data.custType,
        custCode: customer.custCode,
        srcLang: data.language,
        tgtLang: data.language,
        srcFormat: data.srcType,
        tgtFormat: data.tgtType,
        folderPath: folder,
        isToolNeeded: data.isToolNeeded,
        uploadedDuration: "00:00:00",
        uploadedDurationSecs: 0,
        assignedDuration: "00:00:00",
        assignedDurationSecs: 0,
        completedDuration: "00:00:00",
        completedDurationSecs: 0,
        domains: langData.map((value) => {
          const [hrs, mins, secs] = value.hrsDomain.split(":");
          let domainDurationSecs = +hrs * 60 * 60 + +mins * 60 + +secs;

          return {
            language: value.language,
            name: value.domain,
            frequency: value.frequency,
            duration: value.hrsDomain,
            durationSecs: domainDurationSecs,
            uploadedDuration: "00:00:00",
            uploadedDurationSecs: 0,
            assignedDuration: "00:00:00",
            assignedDurationSecs: 0,
            completedDuration: "00:00:00",
            completedDurationSecs: 0,
            rate: 0,
            amount: 0,
            folderPath:
              customer.custCode +
              "_" +
              data.name.replaceAll(" ", "_") +
              "_" +
              value.langCode +
              "_" +
              value.domainCode +
              "_" +
              value.frequency,
          };
        }),
      };

      const config = { headers };

      axios
        .post(url, body, config)
        .then((res) => {
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "Project Created !!!",
              text: `${msg} !!!`,
            }).then(function () {
              dialog.close();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      } else if (
        data.hrs === "" ||
        data.hrs === undefined ||
        data.hrs === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Total Duration is required !!!`,
        });
      } else if (data.custType === "") {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Customer Type is required !!!`,
        });
      } else if (data.customer === "") {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Customer is required !!!`,
        });
      } else if (data.language === "") {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Language is required !!!`,
        });
      } else if (langData.length <= 0) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Kindly allocate the Domain level duration in Detail Tab !!!`,
        });
      }
    }
  };

  const dataList = useMemo(() => langData, [langData]);
  const itemList = ["Main", "Detailed"];

  const [itemTabActive, setItemTabActive] = useState(0);

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
              <TabList className="d-flex Tabs-filled">
                {itemList.map((items, index) => (
                  <Tab
                    className="tabsFilledItem"
                    tabIndex={itemTabActive}
                    onClick={() => {
                      setItemTabActive(index);
                    }}
                    style={
                      itemTabActive === index
                        ? {
                            color: "white",
                            fontWeight: "bold",
                            background: "#22A4EE",
                          }
                        : {
                            background: "#DCE0EA",
                            color: "#717394",
                          }
                    }
                  >
                    {items}
                  </Tab>
                ))}
              </TabList>
              <TabPanel>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                      <label className="label">
                        Project Name<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        ref={register}
                      />
                      {errors.name && (
                        <span className="inputErrorMsg">
                          {errors.name?.message}
                        </span>
                      )}
                    </div>

                    <div className={`inputWrap ${errors.hrs ? "error" : ""}`}>
                      <label className="label">
                        Total Duration<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Number of Hours"
                        name="hrs"
                        ref={register}
                      />
                      {errors.hrs && (
                        <span className="inputErrorMsg">
                          {errors.hrs?.message}
                        </span>
                      )}
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        ETA <span className="requiredField">*</span>
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={ETA}
                        onChange={(date) => handleDatePicker(date)}
                        name="eta"
                        ref={register}
                      />
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Customer Type<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="custType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={custType}
                            onChange={OnChangeCustType}
                            placeholder={"Select the Customer Type"}
                            options={custtypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Customer<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="customer"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={customer}
                            onChange={OnChangeCustomer}
                            placeholder={"Select the Customer"}
                            options={customers}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                    <div className="inputWrap">
                      <label className="label">
                        Audio Format<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="srcType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={srcType}
                            onChange={OnChangeSrcType}
                            placeholder={"Select the Audio Format"}
                            options={srctypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Languages<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="language"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={language}
                            onChange={OnChangeLanguages}
                            placeholder={"Select the Languages"}
                            isMulti={true}
                            isSearchable={true}
                            options={languages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Output Format<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="tgtType"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={tgtType}
                            onChange={OnChangeTgtType}
                            placeholder={"Select the Output Format"}
                            options={tgttypes}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    {/* <div className="inputWrap">
                      <label className="label">Tool Needed ?</label>
                      <input
                        type="checkbox"
                        placeholder="Tool Needed ?"
                        name="isToolNeeded"
                        ref={register}
                        style={{ height: "15px" }}
                      />
                    </div> */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Languages<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="language"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={selectedLanguage}
                            onChange={OnChangeSelectedLanguages}
                            placeholder={"Select the Language"}
                            options={selectedLanguages}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Domain<span className="requiredField">*</span>
                      </label>

                      <Controller
                        control={methods.control}
                        name="domain"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={domain}
                            onChange={OnChangeDomain}
                            placeholder={"Select the Domains"}
                            options={domains}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Frequency (Khz)<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Frequency in Khz"
                        name="frequency"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">
                        Hrs / Domain<span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Hours Per Domain"
                        name="hrsDomain"
                        value={hrsDomain}
                        onChange={(e) => setHrsDomain(e.target.value)}
                      />
                    </div>
                  </div>

                  <button
                    className="domainBtn"
                    onClick={(e) => handleAddDomains(e)}
                  >
                    Add Domain
                  </button>

                  <button
                    className="domainBtn"
                    onClick={(e) => handleRemoveDomain(e)}
                  >
                    Remove Domain
                  </button>

                  <Table
                    columns={cols}
                    data={dataList}
                    count={dataList.length}
                    pagination={true}
                    isMultiSelect={true}
                    setSelectedRows={setSelectedRows}
                    setSelectedRowsValues={setSelectedRowsValues}
                    isCountDisplayed={false}
                    customText=""
                    filter={false}
                    isHeader={false}
                  />
                </div>
              </TabPanel>
            </Tabs>
            <br />
            <button className="orangeBtn btn">Submit</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewProject;
