import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import reqHeader from "../../../helper/reqHeader.js";
import Table from "../../Table/Table.jsx";

const VendorWaitingList = (props) => {
  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.fname + " " + row.original.lname}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.email}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.mobile}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Skype ID",
        accessor: "skypeID",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.skypeID}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "vendType",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.vendType}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Languages",
        accessor: "languages",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => vendorOnboading(row.original, column)}>
              <span title="Click for the Onboarding Procedure">
                {row.original.languages}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let controller = new AbortController();

    async function getVendors() {
      const url = `${process.env.REACT_APP_URL}/admin/users/getonboardingvendors`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          console.log("data..",data);
          let vendList = [];

          data.forEach((vend) => {
            let languages = [];

            let vendType = vend.vendType.filter((value) => {
              if (value.status === "Open" || value.status === "Partial") {
                return value.type;
              }
            });

            let transcriberlanguages = vend.vendTranscriberLang.filter(
              (value) => {
                if (value.status === "Open") {
                  return value.type;
                }
              }
            );
            let translatorlanguages = vend.vendTranslatorLang.filter(
              (value) => {
                if (value.status === "Open") {
                  return value.type;
                }
              }
            );
            let subtitlerlanguages = vend.vendSubtitlerLang.filter((value) => {
              if (value.status === "Open") {
                return value.type;
              }
            });

            languages = [
              ...languages,
              transcriberlanguages,
              translatorlanguages,
              subtitlerlanguages,
            ];

            if (
              vend.prTranscriberLang.length >= 1 ||
              vend.prTranslatorLang.length >= 1 ||
              vend.prSubtitlerLang.length >= 1
            ) {
              let prTranscriberLang = vend.prTranscriberLang.filter((value) => {
                if (value.status === "Open") {
                  return value.type;
                }
              });
              let prTranslatorLang = vend.prTranslatorLang.filter((value) => {
                if (value.status === "Open") {
                  return value.type;
                }
              });
              let prSubtitlerLang = vend.prSubtitlerLang.filter((value) => {
                if (value.status === "Open") {
                  return value.type;
                }
              });

              languages = [
                ...languages,
                prTranscriberLang,
                prTranslatorLang,
                prSubtitlerLang,
              ];
            }

            let langList = [];

            for (let i = 0; i < languages.length; i++) {
              if (languages[i].length >= 1) {
                for (let j = 0; j < languages[i].length; j++) {
                  let arr = languages[i];
                  langList.push(arr[j].type);
                }
              }
            }

            let uniqueLang = [...new Set(langList.flat())];

            let vendor = {
              _id: vend._id,
              role: vend.role,
              fname: vend.fname,
              lname: vend.lname,
              email: vend.email,
              mobile: vend.mobile,
              skypeID: vend.skypeID,
              billingAddress: vend.billingAddress,
              vendType: vendType.map((value) => value.type).join(", "),
              languages: uniqueLang.map((value) => value).join(", "),
              active: vend.active,
              vendorType: vend.vendType.filter((value) => {
                return value.status === "Open" || value.status === "Partial" || value.status === "Completed";
              }),
              vendTranscriberLang: vend.vendTranscriberLang.filter((value) => {
                return value.status === "Open";
              }),
              vendTranslatorLang: vend.vendTranslatorLang.filter((value) => {
                return value.status === "Open";
              }),
              vendSubtitlerLang: vend.vendSubtitlerLang.filter((value) => {
                return value.status === "Open";
              }),
              prType: vend.prType.filter((value) => {
                return value.status === "Open" || value.status === "Partial"|| value.status === "Completed";
              }),
              prTranscriberLang: vend.prTranscriberLang.filter((value) => {
                return value.status === "Open";
              }),
              prTranslatorLang: vend.prTranslatorLang.filter((value) => {
                return value.status === "Open";
              }),
              prSubtitlerLang: vend.prSubtitlerLang.filter((value) => {
                return value.status === "Open";
              }),
            };

            vendList = [...vendList, vendor];
          });

          setDataList(vendList);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getVendors();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const vendorOnboading = async (props) => {
    history.push({
      pathname: "/vendoronboarding",
      state: {
        selectedRow: props,
      },
    });
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Vendors"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default VendorWaitingList;
