//Packages
import React, { useState, useEffect } from "react";

//Custom Components
import LanguageLists from "./LanguageLists";

const Languages = (props) => {
  const { onClick } = props;
  const [clickedRow, setClickedRow] = useState({});

  useEffect(() => {
    if (clickedRow && Object.keys(clickedRow).length !== 0) {
      onClick(clickedRow);
    }
  }, [clickedRow, onClick]);

  return (
    <div>
      <LanguageLists onClick={setClickedRow} />
    </div>
  );
};

export default Languages;
