import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PasswordHide, PasswordShow } from "../icons.component";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserProfile from "../Profile/userProfile";

const ChangePassword = () => {

  const schema = yup.object().shape({
    currentPassword: yup.string().required("Password is required !"),
    newPassword: yup
      .string()
      .required("New Password is required !")
      .min(6, "New Password should be 6 characters long"),
    confirmpassword: yup
      .string()
      .required("Confirm Password is required !")
      .min(6, "Confirm Password should be 6 characters")
      .oneOf(
        [yup.ref("newPassword"), null],
        "New Password and Confirm Password does not match!"
      ),
  });
  
  toast.configure();
  

console.log("Inside Change Password");
  const activeNodes = ["000"];
  //const history = useHistory();

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const url = `${process.env.REACT_APP_URL}/admin/updatepassword/`;
  const headers = reqHeader(true, true, true);
  const config = { headers };

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, authenticated, error, user } = userLogin;

  const [passwordShown, setPasswordShown] = useState(false);
  const [newpasswordShown, setNewPasswordShown] = useState(false);
  const [conpasswordShown, setConPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newpasswordShown ? false : true);
  };
  const toggleConPasswordVisiblity = () => {
    setConPasswordShown(conpasswordShown ? false : true);
  };

  const submitHandler = (data) => {
    //console.log("form data..", data);
    const { currentPassword, newPassword } = data;
    const body = {
      currentPassword,
      newPassword,
    };
    axios
      .put(url, body, config)
      .then((res) => {
        toast.success(res.data.msg);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="000" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">User</div>

            <UserProfile />
          </div>

          <div className="d-flex breadcrumWrap">
            <ul className="breadcrumb">
            <li>User Profile</li>
              <li>Change Password</li>
            </ul>

            {/* <div className="d-flex">
              <button
                className="orangeBtn btn"
                onClick={(e) => handleNewUser(e)}
              >
                Create a New User
              </button>
            </div> */}
          </div>
      <div className="tableWrap projectTableWrap block">
        <form className="userFormWrapper" onSubmit={handleSubmit(submitHandler)}>
          <div className="custommerFormWrapper">
          <div className={`textFieldContainer inputWrap ${errors.currentPassword ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Old Password</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer passwordField">
              <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Old Password"
                  name="currentPassword"
                  ref={register}
              />
              <div className="passwordEye">
                  <i onClick={togglePasswordVisiblity}>
                    {passwordShown ? <PasswordShow /> : <PasswordHide />}
                  </i>{" "}
              </div>  
            </div>
            {errors.currentPassword && (
                <span className="errorText">{errors.currentPassword?.message}</span>
              )}
          </div>
          <div className={`textFieldContainer inputWrap ${errors.newPassword ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">New Password</span>
            </div>
            <div className="inputContainer passwordField">
              <input
                  type={newpasswordShown ? "text" : "password"}
                  placeholder="New Password"
                  name="newPassword"
                  ref={register}
              />
              <div className="passwordEye">
                  <i onClick={toggleNewPasswordVisiblity}>
                    {newpasswordShown ? <PasswordShow /> : <PasswordHide />}
                  </i>{" "}
              </div>
            </div>
              {errors.newPassword && (
                <span className="inputErrorMsg">
                  {errors.newPassword?.message}
                </span>
              )}
          </div>
          <div className={`textFieldContainer inputWrap ${errors.confirmpassword ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title"> Confirm new password</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer passwordField">
              <input
                type={conpasswordShown ? "text" : "password"}
                placeholder="Confirm Password"
                name="confirmpassword"
                ref={register}
              />
              <div className="passwordEye">
                <i onClick={toggleConPasswordVisiblity}>
                  {conpasswordShown ? <PasswordShow /> : <PasswordHide />}
                </i>{" "}
              </div>
            </div>
            {errors.confirmpassword && (
                <span className="errorText">{errors.confirmpassword?.message}</span>
              )}
          </div>
          </div>
      
          <div className="bottomButtonSection">
            <button className="orangeBtn btn">Submit</button>
          </div>
        </form> 
      </div>
        
      </div>
    </div>
  </>
  );
};

export default ChangePassword;
