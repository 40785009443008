//Packages
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

//Custom Components
import Table from "../../Table/Table.jsx";

//Helper
import reqHeader from "../../../helper/reqHeader.js";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components.js";

const RetailRatesList = (props) => {
  const { serviceType } = props;

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Customer",
        accessor: "custName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Category",
        accessor: "tgtCategory",
        sortType: "basic",
        filter: "text",
      },
      {
        Header:
          serviceType === "transcription" || serviceType === "subtitling"
            ? "Rate / Minute"
            : "Rate / Word Count",
        className: "center",
        columns: [
          {
            Header: "INR",
            accessor: "INRRate",
            sortType: "basic",
            filter: "text",
          },
          {
            Header: "USD",
            accessor: "USDRate",
            sortType: "basic",
            filter: "text",
          },
        ],
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Edit",
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button className="editBtn" value={value}>
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  useEffect(() => {
    let controller = new AbortController();

    async function getRates() {
      const url = `${process.env.REACT_APP_URL}/admin/rates/`;
      const headers = reqHeader(true, true, true);

      const params = {
        type:
          serviceType === "transcription"
            ? "Transcription"
            : serviceType === "translation"
            ? "Translation"
            : "Subtitling",
      };

      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          let rates = [];

          let convEngRateINR = 115,
            convEngRateUSD = 115 / 70;

          res.data.data.forEach((value, index) => {
            const { _id: id } = value;

            let rate = {
              type: id.type,
              tgtCategory: id.tgtCategory,
              custType: id.custType,
              custName: id.custName,
              customer: id.customer,
              INRRate: (
                (id.INRRate +
                  (serviceType === "subtitling" ? convEngRateINR : 0)) *
                id.tgtCategory
              ).toFixed(2),
              USDRate: (
                (id.USDRate +
                  (serviceType === "subtitling" ? convEngRateUSD : 0)) *
                id.tgtCategory
              ).toFixed(3),
              active: id.active === true ? "Yes" : "No",
            };

            rates = [...rates, rate];
          });

          setDataList(rates);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getRates();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Rates"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default RetailRatesList;
