import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
} from "../constants/userConstants.js";
import reqHeader from "../helper/reqHeader";

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { email, password } = formData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/admin/login/`;

    const body = {
      email,
      password,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
    });
  }
};

export const registerUser = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const { fname, lname, email, mobile, password, country } = formData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/admin/register/`;

    const body = {
      fname,
      lname,
      email,
      mobile,
      password,
      role: "Customer",
      active: true,
      createdBy: email,
      createdOn: Date.now,
      billingAddress: {
        firstName: fname,
        lastName: lname,
        email: email,
        mobile: mobile,
        country: country,
      },
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error.response.data,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const microsoftLogin = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/admin/microsoftlogin/`;

    const body = {
      accessToken: userdata.accessToken,
      userID: userdata.userID,
      fname: userdata.given_name,
      lname: userdata.family_name,
      email: userdata.email,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.message && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
