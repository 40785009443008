import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Methods
import reqHeader from "../../../helper/reqHeader.js";

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
});

const EditLanguages = (props) => {
  const { selectedRow } = props;

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedRow.name,
      code: selectedRow.code,
      tcode: selectedRow.tesseractCode,
      active: selectedRow.active === "Yes" ? true : false,
      custType: selectedRow.custName,
      transcriptionType: selectedRow.isTranscription,
      translationType: selectedRow.isTranslation,
      subtitlingType: selectedRow.isSubtitling,
      transcriptionCategory: selectedRow.transcriptionCategory,
      translationCategory: selectedRow.translationCategory,
      subtitlingCategory: selectedRow.subtitlingCategory,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [custtypes, setCustTypes] = useState([]);
  const [custType, setCustType] = useState();

  const [transcriptiontypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [transcriptionType, setTranscriptionType] = useState(
    transcriptiontypes[0]
  );

  const [transcriptioncategories] = useState([
    { label: "1", value: "1" },
    { label: "1.5", value: "1.5" },
    { label: "1.8", value: "1.8" },
    { label: "2", value: "2" },
  ]);
  const [transcriptionCategory, setTranscriptionCategory] = useState(
    transcriptioncategories[0]
  );

  const [translationtypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [translationType, setTranslationType] = useState(translationtypes[0]);

  const [translationcategories] = useState([
    { label: "1", value: "1" },
    { label: "1.5", value: "1.5" },
    { label: "1.8", value: "1.8" },
    { label: "2", value: "2" },
  ]);
  const [translationCategory, setTranslationCategory] = useState(
    translationcategories[0]
  );

  const [subtitlingtypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [subtitlingType, setSubtitlingType] = useState(subtitlingtypes[0]);

  const [subtitlingcategories] = useState([
    { label: "1", value: "1" },
    { label: "1.5", value: "1.5" },
    { label: "1.8", value: "1.8" },
    { label: "2", value: "2" },
  ]);
  const [subtitlingCategory, setSubtitlingCategory] = useState(
    subtitlingcategories[0]
  );

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/users/getcustomerstypename`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCustTypes(
            res.data.data.map(({ name, custType, _id: id }) => ({
              id: id,
              label: custType,
              value: name,
            }))
          );
        })
        .catch((err) => {});
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRow !== undefined && custtypes.length > 1) {
      setValue("transcriptionType", selectedRow.isTranscription);
      setTranscriptionType({
        label: selectedRow.isTranscription,
        value: selectedRow.isTranscription,
      });

      setValue("translationType", selectedRow.isTranslation);
      setTranslationType({
        label: selectedRow.isTranslation,
        value: selectedRow.isTranslation,
      });

      setValue("subtitlingType", selectedRow.isSubtitling);
      setSubtitlingType({
        label: selectedRow.isSubtitling,
        value: selectedRow.isSubtitling,
      });

      setValue("transcriptionCategory", selectedRow.transcriptionCategory);
      setTranscriptionCategory({
        label: selectedRow.transcriptionCategory,
        value: selectedRow.transcriptionCategory,
      });

      setValue("translationCategory", selectedRow.translationCategory);
      setTranslationCategory({
        label: selectedRow.translationCategory,
        value: selectedRow.translationCategory,
      });

      setValue("subtitlingCategory", selectedRow.subtitlingCategory);
      setSubtitlingCategory({
        label: selectedRow.subtitlingCategory,
        value: selectedRow.subtitlingCategory,
      });

      setValue("custType", selectedRow.custName);
      setCustType({
        id: selectedRow.customer,
        label: selectedRow.custType,
        value: selectedRow.custName,
      });
    }
  }, [selectedRow, custtypes, setValue]);

  const OnChangeCustType = (item) => {
    setValue("custType", item.value);
    setCustType(item);
  };

  const OnChangeTranscriptionType = (item) => {
    setValue("transcriptionType", item.value);
    setTranscriptionType(item);
  };

  const OnChangeTranscriptionCategory = (item) => {
    setValue("transcriptionCategory", item.value);
    setTranscriptionCategory(item);
  };

  const OnChangeTranslationType = (item) => {
    setValue("translationType", item.value);
    setTranslationType(item);
  };

  const OnChangeTranslationCategory = (item) => {
    setValue("translationCategory", item.value);
    setTranslationCategory(item);
  };

  const OnChangeSubtitlingType = (item) => {
    setValue("subtitlingType", item.value);
    setSubtitlingType(item);
  };

  const OnChangeSubtitlingCategory = (item) => {
    setValue("subtitlingCategory", item.value);
    setSubtitlingCategory(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (data.name !== "" && data.name !== undefined && data.name !== null) {
      const url = `${process.env.REACT_APP_URL}/admin/language/${selectedRow._id}`;
      const headers = reqHeader(true, true, true);
      const body = {
        custType: custType.label === "Enterprise" ? "EC" : "OC",
        customer: custType.id,
        custName: custType.value,
        name: data.name,
        code: data.code,
        tesseractCode: data.tcode,
        language: "",
        accent: "",
        isTranscription: transcriptionType.value === "Yes" ? true : false,
        isTranslation: translationType.value === "Yes" ? true : false,
        isSubtitling: subtitlingType.value === "Yes" ? true : false,
        transcriptionCategory: transcriptionCategory.value,
        translationCategory: translationCategory.value,
        subtitlingCategory: subtitlingCategory.value,
        active: data.active,
      };

      const config = { headers };

      axios
        .put(url, body, config)
        .then((res) => {
          if (res.data.success === false && res.data.msg) {
            Swal.fire({
              icon: "error",
              title: "Server Error !!!",
              text: `Error in Updating !`,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Created !!!",
              text: `Language updated Successfully !`,
            }).then(function () {
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          let e = "";

          for (const [key, value] of Object.entries(errorList)) {
            const keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1);
            e += `${keyCapitalized} ${value} ${error}`;
          }

          e = e === "" ? error : e;

          if (success === false && error !== undefined) {
            Swal.fire({
              icon: "error",
              title: "Server Error !!!",
              text: `${e}`,
            });
          }
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      } else if (
        custType.value === "" ||
        custType.value === undefined ||
        custType.value === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Customer is required !!!`,
        });
      }
    }
  };

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <label className="label">
                    Name<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register}
                  />
                  {errors.name && (
                    <span className="inputErrorMsg">
                      {errors.name?.message}
                    </span>
                  )}
                </div>
                <div className="inputWrap">
                  <label className="label">Code</label>
                  <input
                    type="text"
                    placeholder="Code"
                    name="code"
                    ref={register}
                  />
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Tesseract Code</label>
                  <input
                    type="text"
                    placeholder="Tesseract Code"
                    name="tcode"
                    ref={register}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Customer<span className="requiredField">*</span>
                  </label>

                  <Controller
                    control={methods.control}
                    name="custType"
                    defaultValue={{}}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={custType}
                        onChange={OnChangeCustType}
                        placeholder={"Select the Customer"}
                        options={custtypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Transcription</label>

                  <Controller
                    control={methods.control}
                    name="transcriptionType"
                    defaultValue={transcriptiontypes[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={transcriptionType}
                        onChange={OnChangeTranscriptionType}
                        placeholder={"Transcription Needed ?"}
                        options={transcriptiontypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Category</label>

                  <Controller
                    control={methods.control}
                    name="transcriptionCategory"
                    defaultValue={transcriptioncategories[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={transcriptionCategory}
                        onChange={OnChangeTranscriptionCategory}
                        placeholder={"Select Transcription Category"}
                        options={transcriptioncategories}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Translation</label>

                  <Controller
                    control={methods.control}
                    name="translationType"
                    defaultValue={translationtypes[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={translationType}
                        onChange={OnChangeTranslationType}
                        placeholder={"Translation Needed ?"}
                        options={translationtypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Category</label>

                  <Controller
                    control={methods.control}
                    name="translationCategory"
                    defaultValue={translationcategories[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={translationCategory}
                        onChange={OnChangeTranslationCategory}
                        placeholder={"Select Translation Category"}
                        options={translationcategories}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Subtitling</label>

                  <Controller
                    control={methods.control}
                    name="subtitlingType"
                    defaultValue={subtitlingtypes[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={subtitlingType}
                        onChange={OnChangeSubtitlingType}
                        placeholder={"Subtitling Needed ?"}
                        options={subtitlingtypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Category</label>

                  <Controller
                    control={methods.control}
                    name="subtitlingCategory"
                    defaultValue={subtitlingcategories[0].value}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={subtitlingCategory}
                        onChange={OnChangeSubtitlingCategory}
                        placeholder={"Select Subtitling Category"}
                        options={subtitlingcategories}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Active</label>
                  <input
                    type="checkbox"
                    placeholder="Active"
                    name="active"
                    style={{ height: "15px" }}
                    ref={register}
                  />
                </div>
              </div>

              <br />
              <button className="orangeBtn btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditLanguages;
