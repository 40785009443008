//Packages
import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Split from "react-split";

//Components
import LeftMenuList from "./LeftMenu/LeftMenuList.jsx";
import UserProfile from "./Profile/userProfile.jsx";
import Languages from "./Entities/Languages/Languages.jsx";
import NewLanguages from "./Entities/Languages/NewLanguages";
import LanguageDialects from "./Entities/Dialects/LanguageDialects.jsx";
import RetailRatesList from "./Entities/Rates/RetailRatesList.jsx";
import CategoriesList from "./Entities/Categories/CategoriesList";
import NewCategory from "./Entities/Categories/NewCategory";

//Styles
import "react-tabs/style/react-tabs.scss";
import "../../src/styles.css";
import { CustomDialog } from "react-st-modal";
import EntitiCategoryPopup from "./Entities/Categories/entiti-category-popup.jsx";

const LanguagesRates = () => {
  const activeNodes = ["001", "001_004"];
  const [clickedLanguage, setClickedLanguage] = useState({});
  const [tabActive, setTabActive] = useState(0);

  const tabList = ["Category", "Languages", "Rates"];

  const rateItemList = ["Transcription", "Translation", "Subtitling"];

  const [rateItemTabActive, setRateItemTabActive] = useState(0);

  const handleNewLanguages = async (e) => {
    await CustomDialog(<NewLanguages />, {
      title: "Add New Language",
      showCloseIcon: true,
    });
  };

  const handleNewCategory = async (e) => {
    await CustomDialog(<NewCategory />, {
      title: "Add New Category",
      showCloseIcon: true,
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="001/001_004" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">{tabList[tabActive]}</div>

            <UserProfile />
          </div>

          <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
            <TabList className="tabsWrap">
              {tabList.map((value, index) => (
                <Tab
                  key={index}
                  tabIndex={tabActive}
                  onClick={() => {
                    setTabActive(index);
                  }}
                  className="tabItem"
                  style={
                    tabActive === index
                      ? {
                          color: "#EE6054",
                          fontWeight: "bold",
                          borderBottom: "3px solid #EE6054",
                        }
                      : {}
                  }
                >
                  {value}
                </Tab>
              ))}
            </TabList>

            <TabPanel>
              <div className="d-flex breadcrumWrap">
                <ul className="breadcrumb">
                  <li>Entities</li>
                  <li>Category</li>
                </ul>
                <div className="d-flex">
                  <button className="orangeBtn btn" onClick={handleNewCategory}>
                    Create Category
                  </button>
                </div>
              </div>

              <CategoriesList />
            </TabPanel>

            <TabPanel>
              <div className="d-flex breadcrumWrap">
                <ul className="breadcrumb">
                  <li>Entities</li>
                  <li>Languages</li>
                </ul>
                <div className="d-flex">
                  <button
                    className="orangeBtn btn"
                    onClick={(e) => handleNewLanguages(e)}
                  >
                    Create Language
                  </button>
                </div>
              </div>

              <Split className="split" sizes={[60, 40]}>
                <Languages onClick={setClickedLanguage} />
                <LanguageDialects language={clickedLanguage} />
              </Split>

              {/* <Languages onClick={setClickedLanguage} />
              <LanguageDialects language={clickedLanguage} /> */}
            </TabPanel>

            <TabPanel>
              <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
                <div className=" breadcrumWrap">
                  <ul className="breadcrumb">
                    <li>Entities</li>
                    <li>Rates</li>
                    <li>{rateItemList[rateItemTabActive]}</li>
                  </ul>

                  <TabList className="d-flex Tabs-filled">
                    {rateItemList.map((items, index) => (
                      <Tab
                        className="tabsFilledItem"
                        tabIndex={rateItemTabActive}
                        onClick={() => {
                          setRateItemTabActive(index);
                        }}
                        style={
                          rateItemTabActive === index
                            ? {
                                color: "white",
                                fontWeight: "bold",
                                background: "#22A4EE",
                              }
                            : {}
                        }
                      >
                        {items}
                      </Tab>
                    ))}
                  </TabList>
                </div>

                <TabPanel style={{ marginTop: -20 }}>
                  <RetailRatesList serviceType="transcription" />
                </TabPanel>

                <TabPanel style={{ marginTop: -20 }}>
                  <RetailRatesList serviceType="translation" />
                </TabPanel>

                <TabPanel style={{ marginTop: -20 }}>
                  <RetailRatesList serviceType="subtitling" />
                </TabPanel>
              </Tabs>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default LanguagesRates;
