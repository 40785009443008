//Packages
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
  code: yup
    .string()
    .required("Code is required!")
    .matches(/^([^0-9]*)$/, "Code should not contain numbers!")
    .max(5, "Code has a max limit of 5 characters"),
});

const EditDomain = (props) => {
  const { selectedRow } = props;

  const { type } = selectedRow;

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedRow.name,
      code: selectedRow.code,
      active: selectedRow.active === "Yes" ? true : false,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [servicetypes] = useState([
    { label: "Transcription", value: "Transcription" },
    { label: "Translation", value: "Translation" },
    { label: "Subtitling", value: "Subtitling" },
  ]);
  const [serviceType, setServiceType] = useState();

  useEffect(() => {
    if (type !== undefined) {
      setValue("serviceType", type);
      setServiceType({
        label: type,
        value: type,
      });
    }
  }, [type, setValue]);

  const OnChangeServiceType = (item) => {
    setValue("serviceType", item.value);
    setServiceType(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (
      data.name !== "" &&
      data.name !== undefined &&
      data.name !== null &&
      data.code !== "" &&
      data.code !== undefined &&
      data.code !== null &&
      serviceType.value !== "" &&
      serviceType.value !== undefined &&
      serviceType.value !== null
    ) {
      const url = `${process.env.REACT_APP_URL}/admin/domain/${selectedRow._id}`;
      const headers = reqHeader(true, true, true);
      const body = {
        _id: selectedRow._id,
        name: data.name,
        code: data.code,
        type: serviceType.value,
        active: data.active,
      };

      const config = { headers };

      axios
        .put(url, body, config)
        .then((res) => {
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "Domain Updated !!!",
              text: `${msg}`,
            }).then(function () {
              dialog.close();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { error } = err.response.data;

          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error}`,
          });
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      }
      if (data.code === "" || data.code === undefined || data.code === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Code is required !!!`,
        });
      }
      if (
        serviceType.value === "" ||
        serviceType.value === undefined ||
        serviceType.value === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Service Type is required !!!`,
        });
      }
    }
  };

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <label className="label">
                    Name<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register}
                  />
                  {errors.name && (
                    <span className="inputErrorMsg">
                      {errors.name?.message}
                    </span>
                  )}
                </div>

                <div className={`inputWrap ${errors.code ? "error" : ""}`}>
                  <label className="label">
                    Code<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Code"
                    name="code"
                    ref={register}
                  />
                  {errors.code && (
                    <span className="inputErrorMsg">
                      {errors.code?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Service Type<span className="requiredField">*</span>
                  </label>

                  <Controller
                    control={methods.control}
                    name="serviceType"
                    defaultValue={{}}
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        name={name}
                        value={serviceType}
                        onChange={OnChangeServiceType}
                        placeholder={"Select the Type"}
                        options={servicetypes}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.value}
                      />
                    )}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Active</label>
                  <input
                    type="checkbox"
                    placeholder="Active"
                    name="active"
                    defaultChecked={true}
                    style={{ height: "15px" }}
                    ref={register}
                  />
                </div>
              </div>

              <br />
              <button className="orangeBtn btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditDomain;
