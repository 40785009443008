//Packages
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required!"),
    // .matches(/^([^0-9]*)$/, "Name should not contain numbers!"),
  code: yup
    .string()
    .required("Code is required!")
    // .matches(/^([^0-9]*)$/, "Code should not contain numbers!")
    // .max(5, "Code has a max limit of 5 characters"),
});

const NewDomain = (props) => {
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      code: "",
      active: true,
    },
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // const { serviceType } = props;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [servicetypes] = useState([
    { label: "Transcription", value: "Transcription" },
    { label: "Translation", value: "Translation" },
    { label: "Subtitling", value: "Subtitling" },
  ]);
  const [serviceType, setServiceType] = useState(servicetypes[0]);

  const OnChangeServiceType = (item) => {
    setValue("serviceType", item.value);
    setServiceType(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (
      data.name !== "" &&
      data.name !== undefined &&
      data.name !== null &&
      data.code !== "" &&
      data.code !== undefined &&
      data.code !== null &&
      serviceType.value !== "" &&
      serviceType.value !== undefined &&
      serviceType.value !== null
    ) {
      const url = `${process.env.REACT_APP_URL}/admin/domain/adddomain`;
      const headers = reqHeader(true, true, true);
      const body = {
        name: data.name,
        code: data.code,
        serviceType: serviceType.value,
        active: data.active,
      };

      const config = { headers };

      axios
        .post(url, body, config)
        .then((res) => {
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "Domain Created !!!",
              text: `${msg}`,
            }).then(function () {
              dialog.close();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error}`,
          });
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      }
      if (data.code === "" || data.code === undefined || data.code === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Code is required !!!`,
        });
      }
      if (
        serviceType.value === "" ||
        serviceType.value === undefined ||
        serviceType.value === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Service Type is required !!!`,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="custommerFormWrapper">
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Name</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input type="text" placeholder="Name" name="name" ref={register} />
            {errors.name && (
              <text className="errorText">{errors.name?.message}</text>
            )}
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Code</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input type="text" placeholder="Code" name="code" ref={register} />
            {errors.code && (
              <text className="errorText">{errors.code?.message}</text>
            )}
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Service Type</span>
            <span className="requiredField">*</span>
          </div>
          <Controller
            control={methods.control}
            name="serviceType"
            defaultValue={{}}
            render={({ onChange, value, name, ref }) => (
              <Select
                inputRef={ref}
                name={name}
                value={serviceType}
                onChange={OnChangeServiceType}
                placeholder={"Select the Type"}
                options={servicetypes}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.value}
              />
            )}
          />
        </div>
      </div>
      <div className="bottomButtonSection">
        <div className="checkBoxContainer">
          <input
            type="checkbox"
            placeholder="Active"
            name="active"
            // defaultChecked={active}
            // onChange={() => setActive(!active)}

            className="checkboxStyle"
          />
          <label>Active</label>
        </div>

        <button className="orangeBtn btn">Submit</button>
      </div>
    </form>
  );
};

export default NewDomain;
