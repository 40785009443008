import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import reqHeader from "../../../helper/reqHeader.js";
import Table from "../../Table/Table.jsx";
import Select from "react-select";

const VendorHistory = () => {
  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Project",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Customer",
        accessor: "custName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Status",
        accessor: "stage",
        sortType: "basic",
        filter: "text",
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  // const [vendorList] = useState([
  //   { label: "Open", value: "Open" },
  //   { label: "Approved", value: "Approved" },
  //   { label: "Rejected", value: "Rejected" },
  // ]);
  const [vendors, setVendors] = useState();
  // const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let controller = new AbortController();

    async function getVendors() {
      const url = `${process.env.REACT_APP_URL}/admin/users/getallvendors`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          setVendorList(
            data.map(({ fname, lname, _id: id }) => ({
              id: id,
              label: `${fname} ${lname}`,
              value: `${fname} ${lname}`,
            }))
          );
        })
        .catch((err) => {
          setVendorList([]);
        });
    }

    getVendors();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  useEffect(() => {
    let controller = new AbortController();

    async function getVendorFiles(vendorIDs) {
      const url = `${process.env.REACT_APP_URL}/admin/projects/getvendorfiles`;
      const headers = reqHeader(true, true, true);
      const params = { vendorIDs };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          setDataList(data);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    if (vendors && vendors.length > 0) {
      let vendorIDs = vendors.map((val) => val.id).toString();

      getVendorFiles(vendorIDs);
    } else {
      setDataList([]);
    }

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, [vendors]);

  const OnChangeVendors = (item) => {
    setVendors(item);
    // setLoaded(false);
  };

  return (
    <div>
      <Select
        name="vendors"
        value={vendors}
        onChange={OnChangeVendors}
        placeholder={"Select Vendors"}
        options={vendorList}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.value}
        isMulti={true}
      />

      <Table
        columns={columns}
        data={datalist}
        count={datalist.length}
        pagination={true}
        expanded={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="File List"
        isMultiSelect={false}
        getHeaderProps={(column) => ({})}
        getColumnProps={(column) => ({})}
        getRowProps={(row) => ({
          // onClick: () => console.log(`row clicked`, row),
        })}
        getCellProps={(cellInfo) => ({})}
      />
    </div>
  );
};

export default VendorHistory;
