//Packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { CustomDialog } from "react-st-modal";
import { toast } from "react-toastify";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "../../../node_modules/react-h5-audio-player/lib/styles.css";

const DownloadFiles = (props) => {
  const {
    projectName,
    language: srcLang,
    fileDuration: totalDuration,
    fileLocation: url,
    targetFileLocation: targetUrl,
    targetFileName,
    targetOutputFormat,
    serviceType,
    targetJsonFileName,
    targetJsonFileLocation: targetJsonFileUrl,
  } = props.selectedRow;

  const [urlPath, setUrlPath] = useState("");
  const [urlDateTime, setUrlDateTime] = useState(new Date());
  const [expiryDateTime, setExpiryDateTime] = useState(new Date());

  useEffect(() => {
    async function getSignedURL() {
      const downloadurl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;
      const headers = reqHeader(true, true, true);
      var revisedUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? url.replace("/texlang-cloud-storage/", "/")
          : url.replace("/staging-texlang-cloudstorage/", "/");

      const params = {
        fpath: revisedUrl,
        type: serviceType,
      };

      const config = { headers, params };

      await axios
        .get(downloadurl, config)
        .then((response) => {
          const { url } = response.data.data;

          setUrlPath(url);

          let googDate = url.split("&")[2];
          let googExpiry = url.split("&")[3];

          if (googDate !== undefined && googExpiry !== undefined) {
            let date = new Date(
              parseInt(googDate.substring(12, 16)),
              parseInt(googDate.substring(16, 18)),
              parseInt(googDate.substring(18, 20)),
              parseInt(googDate.substring(21, 23)),
              parseInt(googDate.substring(23, 25)),
              parseInt(googDate.substring(25, 27)),
              0
            );

            let expiry = parseInt(googExpiry.substring(15, googExpiry.length));

            setUrlDateTime(date);
            setExpiryDateTime(date.setSeconds(date.getSeconds() + expiry));
          }
        })
        .catch((err) => {});
    }
    getSignedURL();
  }, [url, serviceType]);

  const handleSrcDownload = async () => {
    if (urlDateTime <= expiryDateTime) {
      let fname = url.split("/")[7];

      const link = document.createElement("a");
      link.download = fname;
      link.target = "_blank";
      link.href = urlPath;
      document.body.appendChild(link);
      link.click();
    } else {
      const headers = reqHeader(true, true, true);

      let downloadUrl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;

      var revisedUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? url.replace("/texlang-cloud-storage/", "/")
          : url.replace("/staging-texlang-cloudstorage/", "/");

      const params = {
        fpath: revisedUrl,
        type: serviceType,
      };

      let fname = targetUrl.split("/")[7];

      const config = { headers, params };
      await axios
        .get(downloadUrl, config)
        .then((response) => {
          const { url } = response.data.data;

          if (url !== undefined) {
            const link = document.createElement("a");
            link.download = fname;
            link.target = "_blank";
            link.href = url;
            document.body.appendChild(link);
            link.click();
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((err) => {});
    }
  };

  const handleTgtDownload = async (type) => {
    const downloadurl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;
    const headers = reqHeader(true, true, true);
    var revisedUrl =
      type === "json"
        ? process.env.REACT_APP_ENVIRONMENT === "production"
          ? targetJsonFileUrl.replace("/texlang-cloud-storage/", "/")
          : targetJsonFileUrl.replace("/staging-texlang-cloudstorage/", "/")
        : process.env.REACT_APP_ENVIRONMENT
        ? targetUrl.replace("/texlang-cloud-storage/", "/")
        : targetUrl.replace("/staging-texlang-cloudstorage/", "/");

    const params = {
      fpath: revisedUrl,
      type: "Transcription",
    };

    const config = { headers, params };

    let fname = url.split("/")[7];

    await axios
      .get(downloadurl, config)
      .then((response) => {
        const { url } = response.data.data;

        const link = document.createElement("a");
        link.download = fname;
        link.target = "_blank";
        link.href = url;
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  };

  const handleTgtExcelFile = async (serviceType) => {};

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <AudioPlayer
                src={`${urlPath}`}
                controls={true}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                className="audioPlayer"
                style={{
                  background: "#740AF6",
                  padding: "40px",
                  borderRadius: "5px",
                }}
              />
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Source File Name</label>
                <input
                  type="text"
                  placeholder="File Name"
                  name="fileName"
                  readOnly={true}
                  value={projectName}
                  title={projectName}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Duration</label>
                <input
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  readOnly={true}
                  value={totalDuration}
                  title={totalDuration}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Source Language</label>
                <input
                  type="text"
                  placeholder="Language"
                  name="language"
                  readOnly={true}
                  value={srcLang}
                  title={srcLang}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Target File Name</label>
                <input
                  type="text"
                  placeholder="Target File Name"
                  name="targetfileName"
                  readOnly={true}
                  value={
                    targetFileName === "" ? targetJsonFileName : targetFileName
                  }
                  title={
                    targetFileName === "" ? targetJsonFileName : targetFileName
                  }
                />
              </div>

              <div className="inputWrap">
                <label className="label">Output Format</label>
                <input
                  type="text"
                  placeholder="Output Format"
                  name="output"
                  readOnly={true}
                  value={targetOutputFormat}
                  title={targetOutputFormat}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Target Language</label>
                <input
                  type="text"
                  placeholder="Target Language"
                  name="targetlanguage"
                  readOnly={true}
                  value={srcLang}
                  title={srcLang}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  onClick={() => handleSrcDownload()}
                >
                  Source Audio
                </button>
              </div>

              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetUrl === "" ? true : false}
                  onClick={() => handleTgtDownload("target")}
                >
                  Target File
                </button>
              </div>

              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetJsonFileUrl === "" ? true : false}
                  onClick={() => handleTgtDownload("json")}
                >
                  JSON File
                </button>
              </div>

              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetUrl === "" ? true : false}
                  onClick={() => handleTgtExcelFile("Transcription")}
                >
                  Excel File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadFiles;
