import React from "react";

const ProgressBar = (props) => {
  const { completed } = props;

  const bgColor =
    completed <= 20
      ? "#FF0000"
      : completed > 20 && completed < 80
      ? "#FFA500"
      : "#00FF00";

  const containerStyles = {
    height: 18,
    width: "60%",
    backgroundColor: "#EFF0F3",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgColor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "black",
    fontWeight: "normal",
  };

  const wrapperContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={wrapperContainer}>
      <div style={containerStyles}>
        <div style={fillerStyles} />
      </div>
      <text style={labelStyles}>{Number(completed).toFixed()}%</text>
    </div>
  );
};

export default ProgressBar;
