import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CustomDialog } from "react-st-modal";
import reqHeader from "../../../helper/reqHeader.js";
import Table from "../../Table/Table.jsx";
import EditTranslator from "./EditTranslator.jsx";
import VendorsRates from "./VendorsRates.jsx";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components";

const Translators = (props) => {
  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return <span> {row.original.fname + " " + row.original.lname}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Skype ID",
        accessor: "skypeID",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Type",
        accessor: "vendType",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Translation Languages",
        accessor: "translatorlanguages",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Proof Reading Languages",
        accessor: "prtranslatorlanguages",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span> {value ? "Yes" : "No"}</span>,
      },
      {
        Header: "Edit",
        className: "center",
        Cell: ({ row }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              onClick={(e) => editTranslator(row.original, e)}
            >
              Edit
            </button>
          </div>
        ),
      },
      {
        Header: "Rate",
        className: "center",
        Cell: ({ row }) => (
          <button
            className="rateBtn"
            onClick={(e) => rateMapping(row.original, e)}
          >
            Rate
          </button>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let controller = new AbortController();

    async function getVendors() {
      const url = `${process.env.REACT_APP_URL}/admin/users/getalltranslators`;
      
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          //console.log("res.data.....",res.data);
          
          let vendList = [];

          data.forEach((vend) => {
            let srclanguages = [];
            let tgtlanguages = [];

            let vendType = vend.vendType.filter((value) => {
              if (
                value.status === "Partial" ||
                value.status === "Completed" ||
                value.status === "Inactive"
              ) {
                return value.type;
              }
            });
            // console.log("vendType..",vendType);

            let prType = vend.prType.filter((value) => {
              if (
                value.status === "Partial" ||
                value.status === "Completed" ||
                value.status === "Inactive"
              ) {
                return value.type;
              }
            });
            // console.log("prType..",prType);
            //  console.log("vend.vendTranslatorLang",vend.vendTranslatorLang);
            let translatorlanguages = [];
            vend.vendTranslatorLang.map(
              (row) => {
                ///console.log("srcvalue",value.srcLang);
                // if (value.status === "Approved") {
                //   return value.srcLang;
                // }
                translatorlanguages.push(row.srcLang+"_"+row.tgtLang);
              }
            );
            //console.log("translatorlanguages",translatorlanguages); 
            
            // console.log("vend.prTranslatorLang",vend.prTranslatorLang);
            let prtranslatorlanguages = [];
            vend.prTranslatorLang.map(
              (value) => {
                prtranslatorlanguages.push(value.srcLang+"_"+value.tgtLang)
              }
            );
            //console.log("prtranslatorlanguages",prtranslatorlanguages);

            //srclanguages =
            //  [
            //   ...srclanguages,
            //   translatorsrclanguages,
            //   prtranslatorsrclanguages
            // ];

            // tgtlanguages=[
            //   ...tgtlanguages,
            //   translatortgtlanguages,
            //   prtranslatortgtlanguages
            // ];

            // console.log("srclanguages,tgtlanguages",srclanguages,tgtlanguages);

            // let prTranscriberLang, prTranslatorLang, prSubtitlerLang;

            // if (
            //   vend.prTranscriberLang.length >= 1 ||
            //   vend.prTranslatorLang.length >= 1 ||
            //   vend.prSubtitlerLang.length >= 1
            // ) {
            //   prTranscriberLang = vend.prTranscriberLang.filter((value) => {
            //     if (value.status === "Approved") {
            //       return value.type;
            //     }
            //   });
            //   prTranslatorLang = vend.prTranslatorLang.filter((value) => {
            //     if (value.status === "Approved") {
            //       return value.type;
            //     }
            //   });
            //   prSubtitlerLang = vend.prSubtitlerLang.filter((value) => {
            //     if (value.status === "Approved") {
            //       return value.type;
            //     }
            //   });

            //   languages = [
            //     ...languages,
            //     prTranscriberLang,
            //     prTranslatorLang,
            //     prSubtitlerLang,
            //   ];
            // }

            let translangList = [];

            for (let i = 0; i < translatorlanguages.length; i++) {
              translangList.push(translatorlanguages[i]);
              }

            let prlangList = [];

            for (let i = 0; i < prtranslatorlanguages.length; i++) {
              prlangList.push(prtranslatorlanguages[i])
             
            }

            let uniqueTransLang = [...new Set(translangList.flat())];
            let uniquePRLang = [...new Set(prlangList.flat())];
            // console.log("uniqueTransLang,uniquePRLang",uniqueTransLang,uniquePRLang);
            let trlangs=uniqueTransLang.map((value) => value).join(", ");
            //console.log("trlangs",trlangs);
            let prlangs=uniquePRLang.map((value) => value).join(", ");
            //console.log("prlangs",prlangs);
            let vendtypes=vendType.map((value) => value.type).join(", ");
            let prtypes=prType.map((value) => value.type).join(", ");


            let vendor = {
              _id: vend._id,
              role: vend.role,
              fname: vend.fname,
              lname: vend.lname,
              email: vend.email,
              mobile: vend.mobile,
              skypeID: vend.skypeID,
              billingAddress: vend.billingAddress,
              vendType: vendtypes,
              prType:prtypes,
              translatorlanguages: trlangs,
              prTranslatorLang: prlangs,
              languages:trlangs.split("_")[0],
              prtranslatorlanguages: prlangs,
              currency: vend.currency,
              timings: vend.timings,
              hrsPerWeek: vend.hrsPerWeek,
              active: vend.active,
              //vendTypeDetails: vendType,
              //prTypeDetails: prType,
              //transcriberlanguages: vend.vendTranscriberLang,
              //translatorlanguages: trlangs,
              //subtitlerlanguages: vend.vendSubtitlerLang,
              //prTranscriberLang: vend.prTranscriberLang,
              //prTranslatorLang: prlangs,
              //prSubtitlerLang: vend.prSubtitlerLang,
            };

            vendList = [...vendList, vendor];
          });

          setDataList(vendList);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getVendors();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const editTranslator = async (row, e) => {
    e.preventDefault();

    await CustomDialog(<EditTranslator row={row} />, {
      title: "Edit Translator",
      showCloseIcon: true,
    });
  };

  const rateMapping = async (props, e) => {
    //console.log("props...",props);
    history.push({
      pathname: "/vendorrates",
      state: {
        selectedRow: props,
      },
    });
    // e.preventDefault();
    // await CustomDialog(<VendorsRates row={props} />, {
    //   title: "Vendor Rate",
    //   showCloseIcon: true,
    // });
  };

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Vendors"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default Translators;
