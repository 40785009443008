import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions.js";
import { SignOut } from "../icons.component.jsx";
import profileIcon from "../../assets/images/profile.svg";
import passwordIcon from "../../assets/images/passwordChange.svg";
import { useHistory } from "react-router";



const UserProfile = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const {  user } = userLogin;
  

  const dispatch = useDispatch();
  const history = useHistory();
 

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="userInfoWrap">
        <div className="userAvatar"></div>
        <div className="userName">
          {user.fullName} <div className="twoWayArrow"></div>
        </div>

        <ul className="profileOptions">
          {/* <li className="profileItem">
            <img src={profileIcon} alt="" />
            <span>my profile</span>
          </li> */}

          <li className="changePwdItem" onClick={()=>history.push("/changepassword")}>
            <img src={passwordIcon} alt="" />
            <span>change password</span>
          </li>

          <li className="signOutItem" onClick={logoutHandler}>
            <SignOut />
            <span>Sign Out</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserProfile;
