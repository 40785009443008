//Packages
import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

const PrjtAssign = (props) => {
  const {
    selectedRow: {
      _id: id,
      type,
      ETA,
      stage,
      serviceType,
      customerID,
      custName,
      custType,
      projectID,
      projectName,
      transcription,
      translation,
      subtitling,
      proofReading,
      language,
      fileLength,
    },
  } = props;

  const [assignedList, setAssignedList] = useState([]);
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedETA, setAssignedETA] = useState(new Date());
  const [eta, setETA] = useState(new Date());
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [rate, setRate] = useState({
    rateID: "",
    vendorID: "",
    language: "",
    currency: "",
    rates: Number(0).toFixed(3),
    amounts: Number(0).toFixed(3),
    rate: "INR " + Number(0).toFixed(3),
    amount: "INR " + Number(0).toFixed(3),
  });
  const [instructions, setInstructions] = useState("");
  const [loaded, setLoaded] = useState(false); //Need to manage the loading of the Assigned To Rates

  // use this hook to control the dialog
  const dialog = useDialog();

  useEffect(() => {
    async function getAssignedTo() {
      const url = `${process.env.REACT_APP_URL}/admin/users/assigned`;
      const headers = reqHeader(true, true, true);
      const params = {
        stage: stage,
        type: type,
        serviceType: serviceType,
        language: language,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          setAssignedList(
            res.data.data.map(({ _id: id, fname, lname }) => ({
              label: `${id}`,
              value: `${fname} ${lname}`,
            }))
          );
        })
        .catch((err) => {});
    }
    getAssignedTo();
  }, [stage, type, serviceType, language]);

  useEffect(() => {
    let currDate = new Date();

    if (currDate < new Date(ETA)) {
      setAssignedETA(currDate);
    } else {
      setAssignedETA(new Date(ETA));
    }

    setETA(new Date(ETA));
  }, [ETA]);

  useEffect(() => {
    async function getUserDetails() {
      if (
        assignedTo !== "" &&
        assignedTo !== undefined &&
        assignedTo !== null
      ) {
        let id = assignedTo.label;

        const url = `${process.env.REACT_APP_URL}/admin/users/${id}`;
        const headers = reqHeader(true, true, true);
        const config = { headers };

        await axios
          .get(url, config)
          .then((res) => {
            const { fname, lname, email } = res.data.data;

            setUser({ id: id, name: fname + " " + lname, email: email });
          })
          .catch((err) => {});
      }
    }

    async function getRateDetails() {
      if (
        assignedTo !== "" &&
        assignedTo !== undefined &&
        assignedTo !== null
      ) {
        let id = assignedTo.label;

        const url = `${process.env.REACT_APP_URL}/admin/vendorrates/getvendorratesfile`;
        const headers = reqHeader(true, true, true);
        const params = {
          id: id,
          customerID: customerID,
          custName: custName,
          custType: custType,
          projectID: projectID,
          projectName: projectName,
          language: language,
          serviceType: serviceType,
          stage: stage,
        };
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            const { data } = res.data;

            if (data === undefined || data === null) {
              Swal.fire({
                icon: "info",
                title: "Vendor Assign Missing !!!",
                text: `Rate is not set for this vendor !!!`,
              });

              setRate({
                rateID: "",
                vendorID: "",
                language: "",
                currency: "",
                rates: Number(0).toFixed(3),
                amounts: Number(0).toFixed(3),
                rate: "INR " + Number(0).toFixed(3),
                amount: "INR " + Number(0).toFixed(3),
              });
            } else {
              let amount = 0;

              if (
                data.type === "Transcriber" ||
                data.type === "Subtitler" ||
                data.type === "PR Transcriber" ||
                data.type === "PR Subtitler"
              ) {
                const times = fileLength.split(":");

                const hhRate = Number(times[0]) * 60 * data.rate;
                const mmRate = Number(times[1]) * data.rate;
                const ssRate = (Number(times[2]) / 60) * data.rate;
                amount = hhRate + mmRate + ssRate;
              } else {
                amount = data.rate * fileLength;
              }

              setRate({
                rateID: data._id,
                vendorID: data.vendorID,
                language: language,
                currency: data.currency,
                rates: data.rate.toFixed(3),
                amounts: amount.toFixed(3),
                rate: data.currency + " " + data.rate.toFixed(3),
                amount: data.currency + " " + amount.toFixed(3),
              });
            }
          })
          .catch((err) => {});
      }
    }

    getUserDetails();

    if (type === "child" && loaded === false) {
      getRateDetails();

      setLoaded(true);
    }
  }, [
    assignedTo,
    type,
    custName,
    custType,
    customerID,
    fileLength,
    language,
    loaded,
    projectID,
    projectName,
    rate,
    serviceType,
    stage,
  ]);

  useEffect(() => {
    if (stage === "Transcription Assigned" || stage === "Transcription") {
      setUser({
        id: transcription[transcription.length - 1].transcriber,
        name: transcription[transcription.length - 1].name,
        email: transcription[transcription.length - 1].email,
      });

      setRate({
        rateID: transcription[transcription.length - 1].rateID,
        vendorID: transcription[transcription.length - 1].transcriber,
        language: language,
        currency: transcription[transcription.length - 1].currency,
        rates: transcription[transcription.length - 1].cost.toFixed(3),
        amounts: transcription[transcription.length - 1].amount.toFixed(3),
        rate:
          transcription[transcription.length - 1].currency +
          " " +
          transcription[transcription.length - 1].cost.toFixed(3),
        amount:
          transcription[transcription.length - 1].currency +
          " " +
          transcription[transcription.length - 1].amount.toFixed(3),
      });

      setAssignedTo({
        label: transcription[transcription.length - 1].transcriber,
        value: transcription[transcription.length - 1].name,
      });

      setInstructions(transcription[transcription.length - 1].instructions);

      setAssignedETA(new Date(transcription[transcription.length - 1].ETA));

      setLoaded(true);
    } else if (stage === "Translation Assigned" || stage === "Translation") {
      setUser({
        id: translation[translation.length - 1].translator,
        name: translation[translation.length - 1].name,
        email: translation[translation.length - 1].email,
      });

      setRate({
        rateID: translation[translation.length - 1].rateID,
        vendorID: translation[translation.length - 1].translator,
        language: language,
        currency: translation[translation.length - 1].currency,
        rates: translation[translation.length - 1].cost.toFixed(3),
        amounts: translation[translation.length - 1].amount.toFixed(3),
        rate:
          translation[translation.length - 1].currency +
          " " +
          translation[translation.length - 1].cost.toFixed(3),
        amount:
          translation[translation.length - 1].currency +
          " " +
          translation[translation.length - 1].amount.toFixed(3),
      });

      setAssignedTo({
        label: translation[translation.length - 1].translator,
        value: translation[translation.length - 1].name,
      });

      setInstructions(translation[translation.length - 1].instructions);

      setAssignedETA(new Date(translation[translation.length - 1].ETA));
    } else if (stage === "Subtitling Assigned" || stage === "Subtitling") {
      setUser({
        id: subtitling[subtitling.length - 1].subtitler,
        name: subtitling[subtitling.length - 1].name,
        email: subtitling[subtitling.length - 1].email,
      });

      setRate({
        rateID: subtitling[subtitling.length - 1].rateID,
        vendorID: subtitling[subtitling.length - 1].subtitler,
        language: language,
        currency: subtitling[subtitling.length - 1].currency,
        rates: subtitling[subtitling.length - 1].cost.toFixed(3),
        amounts: subtitling[subtitling.length - 1].amount.toFixed(3),
        rate:
          subtitling[subtitling.length - 1].currency +
          " " +
          subtitling[subtitling.length - 1].cost.toFixed(3),
        amount:
          subtitling[subtitling.length - 1].currency +
          " " +
          subtitling[subtitling.length - 1].amount.toFixed(3),
      });

      setAssignedTo({
        label: subtitling[subtitling.length - 1].subtitler,
        value: subtitling[subtitling.length - 1].name,
      });

      setInstructions(subtitling[subtitling.length - 1].instructions);

      setAssignedETA(new Date(subtitling[subtitling.length - 1].ETA));
    } else if (
      stage === "Proof Reading Assigned" ||
      stage === "Proof Reading"
    ) {
      setUser({
        id: proofReading[proofReading.length - 1].proofReader,
        name: proofReading[proofReading.length - 1].name,
        email: proofReading[proofReading.length - 1].email,
      });

      setRate({
        rateID: proofReading[proofReading.length - 1].rateID,
        vendorID: proofReading[proofReading.length - 1].proofReader,
        language: language,
        currency: proofReading[proofReading.length - 1].currency,
        rates: proofReading[proofReading.length - 1].cost.toFixed(3),
        amounts: proofReading[proofReading.length - 1].amount.toFixed(3),
        rate:
          proofReading[proofReading.length - 1].currency +
          " " +
          proofReading[proofReading.length - 1].cost.toFixed(3),
        amount:
          proofReading[proofReading.length - 1].currency +
          " " +
          proofReading[proofReading.length - 1].amount.toFixed(3),
      });

      setAssignedTo({
        label: proofReading[proofReading.length - 1].proofReader,
        value: proofReading[proofReading.length - 1].name,
      });

      setInstructions(proofReading[proofReading.length - 1].instructions);

      setAssignedETA(new Date(proofReading[proofReading.length - 1].ETA));
    }
  }, [transcription, translation, subtitling, proofReading, stage, language]);

  const OnChangeAssignedTo = (item) => {
    setAssignedTo(item);
    setLoaded(false);
  };

  const handleDatePicker = (date) => {
    let selectedDate = date.toDateString();
    let Eta = new Date(ETA).toDateString();

    if (new Date(selectedDate) <= new Date(Eta)) {
      setAssignedETA(new Date(selectedDate));
    } else {
      setAssignedETA(new Date(assignedETA));
      let formattedDate = format(date, "dd/MM/yyyy");
      Swal.fire({
        icon: "info",
        title: "ETA Warning !!!",
        text: `ETA cannot be set as ${formattedDate} !!!`,
      });
    }
  };

  const handleAssign = async () => {
    if (assignedTo !== "" && id !== "" && id !== undefined && id !== null) {
      if (type === "parent") {
        const url =
          serviceType === "Transcription"
            ? `${process.env.REACT_APP_URL}/admin/transcription/update/${id}`
            : serviceType === "Translation"
            ? `${process.env.REACT_APP_URL}/admin/translation/update/${id}`
            : `${process.env.REACT_APP_URL}/admin/subtitling/update/${id}`;

        const headers = reqHeader(true, true, true);
        const params = {
          msg: `Project assigned to ${user.name}`,
          stage: stage,
        };
        const config = { headers, params };

        const body = {
          assignedPM: user.email,
          assignedPMOn: Date.now(),
          assignedPMInst: instructions,
          stage: "Assigned",
        };

        await axios
          .put(url, body, config)
          .then((res) => {
            if (res.data.success === true) {
              Swal.fire({
                icon: "success",
                title: "Assigned Successfully !!!",
                text: `Project assigned to ${user.name} !!!`,
              }).then(function () {
                dialog.close();
                window.location.reload();
              });
            }
          })
          .catch((err) => {});
      } else {
        if (rate.rates !== Number(0).toFixed(3)) {
          const url =
            serviceType === "Transcription"
              ? `${process.env.REACT_APP_URL}/admin/transcription/updatefile/${id}`
              : serviceType === "Translation"
              ? `${process.env.REACT_APP_URL}/admin/translation/updatefile/${id}`
              : `${process.env.REACT_APP_URL}/admin/subtitling/updatefile/${id}`;

          const headers = reqHeader(true, true, true);

          let arrID;
          if (serviceType === "Transcription") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Transcription Completed" ||
              stage === "Proof Reading Completed"
            ) {
              arrID = "";
            } else if (stage === "Transcription Assigned") {
              arrID = transcription[transcription.length - 1]._id;
            } else if (stage === "Proof Reading Assigned") {
              arrID = proofReading[proofReading.length - 1]._id;
            }
          } else if (serviceType === "Translation") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Translation Completed" ||
              stage === "Proof Reading Completed"
            ) {
              arrID = "";
            } else if (stage === "Translation Assigned") {
              arrID = translation[translation.length - 1]._id;
            } else if (stage === "Proof Reading Assigned") {
              arrID = proofReading[proofReading.length - 1]._id;
            }
          } else if (serviceType === "Subtitling") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Subtitling Completed" ||
              stage === "Proof Reading Completed"
            ) {
              arrID = "";
            } else if (stage === "Subtitling Assigned") {
              arrID = subtitling[subtitling.length - 1]._id;
            } else if (stage === "Proof Reading Assigned") {
              arrID = proofReading[proofReading.length - 1]._id;
            }
          }

          const params = {
            msg: `File assigned to ${user.name}`,
            stage: stage,
            arrID: arrID,
            stat: "insertUpdate",
          };
          const config = { headers, params };

          let body;

          if (serviceType === "Transcription") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Transcription Assigned"
            ) {
              body = {
                transcription: {
                  transcriber: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            } else if (
              stage === "Transcription Completed" ||
              stage === "Proof Reading Assigned" ||
              stage === "Proof Reading"
            ) {
              body = {
                proofReading: {
                  proofReader: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            }
          } else if (serviceType === "Translation") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Translation Assigned"
            ) {
              body = {
                translation: {
                  translator: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            } else if (
              stage === "Translation Completed" ||
              stage === "Proof Reading Assigned" ||
              stage === "Proof Reading"
            ) {
              body = {
                proofReading: {
                  proofReader: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            }
          } else if (serviceType === "Subtitling") {
            if (
              stage === "Open" ||
              stage === "Paid" ||
              stage === "Subtitling Assigned"
            ) {
              body = {
                subtitling: {
                  subtitler: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            } else if (
              stage === "Subtitling Completed" ||
              stage === "Proof Reading Assigned" ||
              stage === "Proof Reading"
            ) {
              body = {
                proofReading: {
                  proofReader: user.id,
                  name: user.name,
                  email: user.email,
                  assignedOn: Date.now(),
                  percentageOfCompletion: 0,
                  ETA: assignedETA,
                  currency: rate.currency,
                  cost: rate.rates,
                  amount: rate.amounts,
                  balAmount: rate.amounts,
                  appStatus: "Open",
                  docStatus: "Assigned",
                  completedOn: null,
                  instructions: instructions,
                  cancelReason: "",
                  cancelledOn: null,
                  rateID: rate.rateID,
                },
              };
            }
          }

          await axios
            .put(url, body, config)
            .then((res) => {
              if (res.data.success === true) {
                Swal.fire({
                  icon: "success",
                  title: "Assigned Successfully !!!",
                  text: `${res.data.customMsg}`,
                }).then(function () {
                  dialog.close();
                  window.location.reload();
                });
              }
            })
            .catch((err) => {});
        } else {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `Cannot Assign the File. Rate is not set for this vendor.`,
          });
        }
      }
    } else {
    }
  };

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">
                  Assigned To <span className="requiredField">*</span>
                </label>
                <Select
                  name="assignedTo"
                  value={assignedTo}
                  onChange={OnChangeAssignedTo}
                  placeholder={"Select Assigned To"}
                  options={assignedList}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              </div>
              <div className="inputWrap">
                <label className="label">
                  Assigned ETA <span className="requiredField">*</span>
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={assignedETA}
                  onChange={(date) => handleDatePicker(date)}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  readOnly={true}
                  value={user.name}
                />
              </div>
              <div className="inputWrap">
                <label className="label">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  readOnly={true}
                  value={user.email}
                />
              </div>
              <div className="inputWrap">
                <label className="label">Delivery Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={eta}
                  readOnly={true}
                />
              </div>
            </div>

            {type === "parent" ? (
              ""
            ) : (
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    {serviceType === "Translation" ? "Word Count" : "Duration"}
                  </label>
                  <input
                    type="text"
                    placeholder={
                      serviceType === "Translation" ? "Word Count" : "Duration"
                    }
                    name="duration"
                    readOnly={true}
                    value={fileLength}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    {serviceType === "Translation"
                      ? "Rate / Words"
                      : "Rate / Min"}
                  </label>
                  <input
                    type="text"
                    placeholder={
                      serviceType === "Translation"
                        ? "Rate / Words"
                        : "Rate / Min"
                    }
                    name="rate"
                    readOnly={true}
                    value={rate.rate}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">Amount</label>
                  <input
                    type="text"
                    placeholder="Amount"
                    name="amount"
                    readOnly={true}
                    value={rate.amount}
                  />
                </div>
              </div>
            )}

            <div className="d-flex createOption">
              <div className="inputWrap instructionarea">
                <label className="label">Instructions</label>
                <textarea
                  placeholder="Instructions"
                  name="instructions"
                  maxLength="1000"
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}></textarea>
              </div>
            </div>

            <div className="footerAction">
              <button className="orangeBtn btn" onClick={() => handleAssign()}>
                Assign
              </button>
            </div>

            {/* <div className="inputWrap">
              <button className="btn" onClick={() => handleAssign()}>
                Assign
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrjtAssign;
