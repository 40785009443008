import React from "react";
import spinner from "../../assets/images/spinner.gif";
import { Line } from "rc-progress";

export default ({ spinneruploadtext, spinnerpaytext, progress }) => (
  <div className="fullPageLoader">
    <div className="overlay"></div>
    <div className="loaderInner">
      <img
        src={spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading....."
      />
      {spinneruploadtext ? (
        <div className="loadingTxt">
          <div>{spinneruploadtext}</div>
          <Line percent={progress} strokeWidth="4" strokeColor="#57E3E0" />
          {`${progress}%`}
        </div>
      ) : (
        <div className="loadingTxt">{spinnerpaytext}</div>
      )}
    </div>
  </div>
);
