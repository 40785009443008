import React from "react";
import {
  SearchIcon,
  Subtitling,
  Transcription,
  Translation,
} from "../icons.component";
import UserProfile from "../Profile/userProfile.jsx";
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";

class Dashboard extends React.Component {
  render() {
    const activeNodes = ["000"];
    return (
      <>
        <div className="container dashboard d-flex">
          <LeftMenuList activeKey="000" activeNodes={activeNodes} />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Welcome, Admin
                <div className="search">
                  <input type="text" placeholder="Search" />
                  <button className="searchBtn">
                    <SearchIcon />
                  </button>
                </div>
              </div>

              <UserProfile />
            </div>

            <div className="projectListWrap">
              <ul className="projectList d-flex">
                <li className="projectBlock readyToDeliver">
                  <div className="numbers">78</div>
                  <div className="projectStats">
                    <div>Ready to</div>
                    <div>Deliver</div>
                  </div>
                </li>

                <li className="projectBlock onGoingProj">
                  <div className="numbers">92</div>
                  <div className="projectStats">
                    <div>Ongoing</div>
                    <div>Projects</div>
                  </div>
                </li>

                <li className="projectBlock pendingProj">
                  <div className="numbers">06</div>
                  <div className="projectStats">
                    <div>Pending</div>
                    <div>Projects</div>
                  </div>
                </li>

                <li className="projectBlock customersOnBoard">
                  <div className="numbers">92</div>
                  <div className="projectStats">
                    <div>Customers</div>
                    <div>onboard</div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="dashboardContent d-flex">
              <div className="block">
                <header className="d-flex">
                  <h2>Projects</h2>
                </header>

                <ul className="accordion">
                  <li className="accordionItem">
                    <header>
                      <div className="customerName">
                        <strong>Baleno</strong> (Customer)
                      </div>
                      <div className="d-flex arrowWrap">
                        <div className="deliverDate">
                          <div className="priorityStat red"></div>
                          <div className="dateDeliver">
                            Delivered Date: 05 Dec, 2020
                          </div>
                        </div>
                        <div className="twoWayArrow"></div>
                      </div>
                    </header>

                    <section className="accordionContent">
                      <ul>
                        <li>
                          <div className="productTitle">
                            <Transcription />
                            <span>Transcription</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Translation />
                            <span>Translation</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Subtitling />
                            <span>Subtitling</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </li>

                  <li className="accordionItem">
                    <header>
                      <div className="customerName">
                        <strong>Baleno</strong> (Customer)
                      </div>
                      <div className="d-flex arrowWrap">
                        <div className="deliverDate">
                          <div className="priorityStat red"></div>
                          <div className="dateDeliver">
                            Delivered Date: 05 Dec, 2020
                          </div>
                        </div>
                        <div className="twoWayArrow"></div>
                      </div>
                    </header>

                    <section className="accordionContent hide">
                      <ul>
                        <li>
                          <div className="productTitle">
                            <Transcription />
                            <span>Transcription</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Translation />
                            <span>Translation</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Subtitling />
                            <span>Subtitling</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </li>

                  <li className="accordionItem">
                    <header>
                      <div className="customerName">
                        <strong>Baleno</strong> (Customer)
                      </div>
                      <div className="d-flex arrowWrap">
                        <div className="deliverDate">
                          <div className="priorityStat red"></div>
                          <div className="dateDeliver">
                            Delivered Date: 05 Dec, 2020
                          </div>
                        </div>
                        <div className="twoWayArrow"></div>
                      </div>
                    </header>

                    <section className="accordionContent hide">
                      <ul>
                        <li>
                          <div className="productTitle">
                            <Transcription />
                            <span>Transcription</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Translation />
                            <span>Translation</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Subtitling />
                            <span>Subtitling</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </li>

                  <li className="accordionItem">
                    <header>
                      <div className="customerName">
                        <strong>Baleno</strong> (Customer)
                      </div>
                      <div className="d-flex arrowWrap">
                        <div className="deliverDate">
                          <div className="priorityStat red"></div>
                          <div className="dateDeliver">
                            Delivered Date: 05 Dec, 2020
                          </div>
                        </div>
                        <div className="twoWayArrow"></div>
                      </div>
                    </header>

                    <section className="accordionContent hide">
                      <ul>
                        <li>
                          <div className="productTitle">
                            <Transcription />
                            <span>Transcription</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Translation />
                            <span>Translation</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>

                        <li>
                          <div className="productTitle">
                            <Subtitling />
                            <span>Subtitling</span>
                          </div>
                          <div className="progressCell">
                            In Progress: <strong>38</strong>
                          </div>
                          <div className="pendingCell">
                            Pending: <strong>38</strong>
                          </div>
                          <div className="totalCell">
                            Total: <strong>60</strong>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </li>
                </ul>
              </div>

              <div className="criticalAlert">
                <h2>Critical Alerts</h2>

                <ul className="alertList">
                  <li className="alertItem d-flex">
                    <div className="alertAvtar">
                      <Transcription />
                    </div>
                    <div className="alertContent">
                      <div className="description">
                        <p>
                          <strong>Transcription:</strong> Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.{" "}
                        </p>
                      </div>
                      <div className="d-flex alertFooter">
                        <div className="alertDate">02 Jan 21</div>
                        <a href="#" className="viewMore">
                          view more
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="alertItem d-flex">
                    <div className="alertAvtar">
                      <Translation />
                    </div>
                    <div className="alertContent">
                      <div className="description">
                        <p>
                          <strong>Translation:</strong> Lorem Ipsum is simply
                          dummy text of the printing...
                        </p>
                      </div>
                      <div className="d-flex alertFooter">
                        <div className="alertDate">02 Jan 21</div>
                        <a href="#" className="viewMore">
                          view more
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="alertItem d-flex">
                    <div className="alertAvtar">
                      <Subtitling />
                    </div>
                    <div className="alertContent">
                      <div className="description">
                        <p>
                          <strong>Subtitling:</strong> Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.{" "}
                        </p>
                      </div>
                      <div className="d-flex alertFooter">
                        <div className="alertDate">02 Jan 21</div>
                        <a href="#" className="viewMore">
                          view more
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
