//Packages
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "react-phone-number-input/style.css";

const NewCustomer = () => {
  const schema = yup.object().shape({
    fname: yup
      .string()
      .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
      .required("First Name is required!"),
    lname: yup
      .string()
      .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!"),
    // .required("Last Name is required!"),
    email: yup
      .string()
      .required("Email is required!")
      .email("Enter a valid Email ID!"),
    address1: yup
      .string()
      .required("Address1 is required!")
      .matches(
        /^[aA-zZ0-9-_,#/\s]+$/,
        "Only alphabets, numbers, underscore, hyphen, comma, hash and slash are allowed!"
      ),
    country: yup.string().required("Country is required!"),
    state: yup
      .string()
      .max(30, "State should not more than 30 character.")
      .required("State is required!")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
    city: yup
      .string()
      .max(30, "City should not more than 30 character.")
      .required("City is required!")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
    zipcode: yup
      .string()
      .max(10, "Postal Code should not more than 10 character.")
      .required("Postal Code is required!")
      .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
    gstNo: yup
      .string()
      .max(18, "Invalid GST No.")
      .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
    code: yup.string().when("custType", {
      is: (custType) => custType === "Enterprise",
      then: yup
        .string()
        .required("Code is required for Enterprise Customers !"),
    }),
  });

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [custtypes] = useState([
    { label: "Enterprise Customer", value: "Enterprise" },
    { label: "Retail Customer", value: "Retail" },
  ]);
  const [countries, setCountries] = useState([]);
  const [custMobile, setCustMobile] = useState("");
  const [custType, setCustType] = useState(custtypes[0]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: name,
              value: code,
            }))
          );
          // setCountries(res.data.data);
        })
        .catch((err) => {});
    };

    fetchData();
  }, []);

  const OnChangeCustType = (item) => {
    setValue("custType", item.value);
    setCustType(item);

    setValue("code", "CP");
  };

  const submitHandler = (data, e) => {
    e.preventDefault();
    debugger;
    const url = `${process.env.REACT_APP_URL}/admin/users/addnewcustomer`;
    const headers = reqHeader(true, true, true);
    const body = {
      fname: data.fname,
      lname: data.lname,
      code: data.code,
      email: data.email,
      mobile: custMobile,
      custType: custType.value,
      active: data.active,
      billingAddress: {
        firstName: data.fname,
        lastName: data.lname,
        invName: data.fname + " " + data.lname,
        GSTIN: data.gstNo,
        email: data.email,
        mobile: custMobile,
        address1: data.address1,
        address2: data.address2,
        country: data.country,
        state: data.state,
        city: data.city,
        zipcode: data.zipcode,
      },
    };
    console.log("body..",body);
    const config = { headers };

    axios
      .post(url, body, config)
      .then((res) => {
        if (res.data.success === false && res.data.msg) {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `Error in Saving`,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Customer Created !!!",
            text: `Customer Created Successfully !!!`,
          }).then(function () {
            dialog.close();
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error !!!",
          text: `${
            err.message && err.response.data.error
              ? err.response.data.error
              : err.message
          }`,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="custommerFormWrapper">
          <div className={`textFieldContainer inputWrap ${errors.fname ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">First Name</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="First Name"
                name="fname"
                ref={register}
              />
              {errors.fname && (
                <text className="errorText">{errors.fname?.message}</text>
              )}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Last Name</span>
            </div>
            <div className="inputContainer">
              <input
                type="text"
                placeholder="Last Name"
                name="lname"
                ref={register}
              />
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.code ? "error" : ""}`} >
            <div className="titieWrapper">
              <span className="title">Code</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Code"
                name="code"
                ref={register}
              />
              {errors.code && (
                <text className="errorText">{errors.code?.message}</text>
              )}
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.email ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Email</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Email"
                name="email"
                ref={register}
              />
              {errors.email && (
                <text className="errorText">{errors.email?.message}</text>
              )}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Mobile No</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <PhoneInput
                international
                defaultCountry="IN"
                placeholder="Enter Mobile Number"
                value={formatPhoneNumberIntl(custMobile)}
                onChange={(phone) => setCustMobile(phone)}
              />
              {formatPhoneNumberIntl(custMobile) &&
                !isValidPhoneNumber(custMobile) && (
                  <text className="errorText"> Invalid Mobile Number !!!</text>
                )}
            </div>
          </div>

          <div className={`textFieldContainer inputWrap ${errors.custType ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Customer Type</span>
              <span className="requiredField">*</span>
            </div>
            <div>
              <Controller
                control={methods.control}
                name="custType"
                defaultValue={custtypes[0].value}
                render={({ onChange, value, name, ref }) => (
                  <Select
                    inputRef={ref}
                    name={name}
                    value={custType}
                    onChange={OnChangeCustType}
                    placeholder={"Select Customer Type"}
                    options={custtypes}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingRight: "15px", paddingLeft: "15px" }}>
          <div className={`textFieldContainer inputWrap ${errors.address1 ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Address 1</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Address 1"
                name="address1"
                ref={register}
              />
              {errors.address1 && (
                <text className="errorText">{errors.address1?.message}</text>
              )}
            </div>
          </div>

          <div className={`textFieldContainer inputWrap ${errors.address2 ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Address 2</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Address 2"
                name="address2"
                ref={register}
              />
              {errors.address1 && (
                <text className="errorText">{errors.address2?.message}</text>
              )}
            </div>
          </div>
        </div>

        <div className="custommerFormWrapper">
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Country</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <select name="country" ref={register}>
                    <option key="" value="">
                      Select Country
                    </option>
                    {countries.map((op) => (
                      <option key={op.id} value={op.value}>
                        {op.label}
                      </option>
                    ))}
                  </select>
              {errors.country && (
                <text className="errorText">{errors.country?.message}</text>
              )}
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.state ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">State</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="State"
                name="state"
                ref={register}
              />
              {errors.state && (
                <text className="errorText">{errors.state?.message}</text>
              )}
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.city ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">City</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="City"
                name="city"
                ref={register}
              />
              {errors.city && (
                <text className="errorText">{errors.city?.message}</text>
              )}
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.zipcode ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">Postal Code</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Postal Code"
                name="zipcode"
                ref={register}
              />
              {errors.zipcode && (
                <text className="errorText">{errors.zipcode?.message}</text>
              )}
            </div>
          </div>
          <div className={`textFieldContainer inputWrap ${errors.gstNo ? "error" : ""}`}>
            <div className="titieWrapper">
              <span className="title">GST No</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="GST No"
                name="gstNo"
                ref={register}
              />
              {errors.gstNo && (
                <text className="errorText">{errors.gstNo?.message}</text>
              )}
            </div>
          </div>
        </div>
        <div className="bottomButtonSection">
          <div className="checkBoxContainer">
            <input
              type="checkbox"
              placeholder="Active"
              name="active"
              defaultChecked="true"
              // defaultChecked={active}
              // onChange={() => setActive(!active)}
              ref={register}
              className="checkboxStyle"
            />
            <label>Active</label>
          </div>

          <button className="orangeBtn btn">Submit</button>
        </div>
      </form>
    </>
  );
};

export default NewCustomer;
