//Packages
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

//Custom Components
import Table from "../../Table/Table.jsx";

//Helper
import reqHeader from "../../../helper/reqHeader.js";

const DeadlinesList = (props) => {
  const { serviceType } = props;

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language Category",
        accessor: "category",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => (
          <span> {value + " (" + row.original.priorityFactor + ")"}</span>
        ),
      },
      {
        Header: "ETA (Hours)",
        accessor: "ETAHour",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
      },
      // {
      //   Header: "Edit",
      //   accessor: "edit",
      //   sortType: "basic",
      //   disableSortBy: true,
      //   className: "center",
      //   Cell: ({ value, row, cell }) => (
      //     <button className="invoiceBtn" value={value}>
      //       Edit
      //     </button>
      //   ),
      // },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  useEffect(() => {
    let controller = new AbortController();

    async function getRates() {
      const url = `${process.env.REACT_APP_URL}/admin/deadlines/getdeadlineswithtype`;
      const headers = reqHeader(true, true, true);

      const params = {
        type:
          serviceType === "transcription"
            ? "Transcription"
            : serviceType === "translation"
            ? "Translation"
            : "Subtitling",
      };

      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          let deadlineList = [];

          data.forEach((val) => {
            deadlineList = [...deadlineList, val];
          });

          setDataList(deadlineList);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getRates();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  return (
    <Table
      columns={columns}
      data={datalist}
      count={datalist.length}
      pagination={true}
      expanded={false}
      isHeader={true}
      filter={true}
      isCountDisplayed={true}
      customText="Deadlines"
      isMultiSelect={false}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        // onClick: () => console.log(`row clicked`, row),
      })}
      getCellProps={(cellInfo) => ({})}
    />
  );
};

export default DeadlinesList;
