//Packages
import React, { useState } from "react";
import { CustomDialog } from "react-st-modal";
import Split from "react-split";
import SplitPane from "react-split-pane";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

//Custom Components
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";
import UserProfile from "../Profile/userProfile.jsx";
import NewProject from "../Entities/Projects/NewProject.jsx";

//Styles
import "../../../src/styles.css";
import "react-tabs/style/react-tabs.scss";

const InvoicesPayments = () => {
  const activeNodes = ["002", "002_005"];

  const handleProject = async (props) => {
    await CustomDialog(<NewProject serviceType="Transcription" />, {
      title: "Transcription Project",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="002/002_005" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <ul className="breadcrumb">
              <li>Services</li>
              <li>Transcriptions</li>
              <li>Invoices / Payments</li>
            </ul>
            <UserProfile />
          </div>

          <div className="d-flex breadcrumWrap">
            <div></div>

            <button className="orangeBtn btn" onClick={handleProject}>
              Add Project
            </button>
          </div>

          <h2>Lists</h2>
        </div>
      </div>
    </>
  );
};

export default InvoicesPayments;
