import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import * as XLSX from "xlsx";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const acceptExtensions = ".xlsx";

const ExportToJSON = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [spinnerMessage, setSpinnerMessage] = useState("");

  // use this hook to control the dialog
  const dialog = useDialog();

  const handleSubmit = async (files, allFiles) => {
    setSpinnerMessage("Uploading files... Please Wait!");
    setLoading(true);
    allFiles.forEach((f) => f.remove());

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);

    setLoading(false);

    // await fileTextList(file);

    let workBook = null;
    let jsonData = null;
    let dataString = null;
    const reader = new FileReader();
    const f = file[0];

    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      dataString = JSON.stringify(jsonData);

      const url = `${process.env.REACT_APP_URL}/admin/upload/uploadmasters`;

      const headers = reqHeader(true, true, true);

      const params = { type: "Vendor" };

      const config = { headers, params };

      //   const body = dataString;

      await axios
        .post(url, dataString, config)
        .then((res) => {
          const { data, errors } = res.data;

          let successIDs = data.map((value) => value).toString();

          let errorIDs = errors.map((value) => value).toString();

          if (errorIDs !== undefined && errorIDs !== "") {
            toast.error(
              <div>
                Already exists these Email IDs :- <br />
                {errors.map((value) => {
                  return (
                    <span>
                      {value} <br />
                    </span>
                  );
                })}
              </div>
            );
          }

          if (successIDs !== undefined && successIDs !== "") {
            toast.success(
              <div>
                Registered Email IDs <br />
                {data.map((value) => {
                  return (
                    <span>
                      {value} <br />
                    </span>
                  );
                })}
              </div>
            );
            setTimeout(() => {
              dialog.close();
              window.location.reload();
            }, 5000);
          }
        })
        .catch((err) => {});
    };
    reader.readAsBinaryString(f);
  };

  return loading ? (
    <div>
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} />
    </div>
  ) : (
    <>
      <form action="#" encType="multipart/form-data">
        <div className="contentLeft">
          <div className="block">
            <Dropzone
              onSubmit={handleSubmit}
              autoUpload={false}
              maxFiles={1}
              styles={{
                dropzone: {
                  overflow: "auto",
                  background: "#F1FAED",
                  border: "2px solid #78CF4D",
                },
                inputLabelWithFiles: { display: "none" },
                inputLabel: {
                  color: "#78CF4D",
                },
              }}
              canRemove={true}
              accept={acceptExtensions}
              inputContent={(files, extra) =>
                extra.reject
                  ? "Audio and Video files only"
                  : "Drag & Drop files here or click to select the file"
              }
            />
            {/* </div> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default ExportToJSON;
