//NPM Packages
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { CustomDialog } from "react-st-modal";

//Custom Components
import Table from "../../Table/Table";
import reqHeader from "../../../helper/reqHeader.js";
import EditUser from "./EditUser.jsx"
import {
  DeleteIcon,
  EditIcon,
} from "../../../svgIconComponent/svg_icon_components";

const UsersList = (props) => {
  const { Width, userLists } = props;

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Role",
        accessor: "role",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span> {value ? "Yes" : "No"} {console.log("row.value", value)}</span>,
      },
      {
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              onClick={(e) => editUser(row.original, value)}
            >
              Edit
            </button>
          </div>
        ),
      },
      {
        accessor: "delete",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="deleteBtn"
            value={value}
            // onClick={(e) => edited(row.original, value)}
          >
            <DeleteIcon />
          </button>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  useEffect(() => {
    let controller = new AbortController();

    async function getUsers() {
      const url = `${process.env.REACT_APP_URL}/admin/users/`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          setDataList(data);
          userLists(data);
        })
        .catch((err) => {
          setDataList([]);
          userLists([]);
        });
    }

    getUsers();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const styles = {
    width: Width,
  };

  const editUser = async (row, e) => {
    console.log("Userlist row", row)
    await CustomDialog(<EditUser row={row} />, {
      title: "Edit User",
      showCloseIcon: true,
    });
  };

  return (
    //   <div style={{ width: "1000px" }}>
    <div style={styles}>
      <Table
        columns={columns}
        data={datalist}
        count={datalist.length}
        pagination={true}
        expanded={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="User"
        isMultiSelect={false}
        getHeaderProps={(column) => ({})}
        getColumnProps={(column) => ({})}
        getRowProps={(row) => ({
          // onClick: () => console.log(`row clicked`, row),
        })}
        getCellProps={(cellInfo) => ({})}
      />
    </div>
  );
};

export default UsersList;
