//Packages
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

//Helper Methods
import reqHeader from "../../../helper/reqHeader.js";

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
});

const NewLanguages = () => {
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [custtypes, setCustTypes] = useState([
    { label: "Retail Customers", value: "Retail Customers" },
    { label: "Enterprise Customers", value: "Enterprise Customers" },
  ]);
  const [custType, setCustType] = useState();

  const [transcriptiontypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [transcriptionType, setTranscriptionType] = useState(
    transcriptiontypes[0]
  );

  const [translationtypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [translationType, setTranslationType] = useState(translationtypes[0]);

  const [subtitlingtypes] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [subtitlingType, setSubtitlingType] = useState(subtitlingtypes[0]);

  const [transcriptioncategories, setTranscriptionCategories] = useState([]);
  const [transcriptionCategory, setTranscriptionCategory] = useState();

  const [translationcategories, setTranslationCategories] = useState([]);
  const [translationCategory, setTranslationCategory] = useState();

  const [subtitlingcategories, setSubtitlingCategories] = useState([]);
  const [subtitlingCategory, setSubtitlingCategory] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/users/getcustomerstypename`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCustTypes(
            res.data.data.map(({ name, custType, _id: id }) => ({
              id: id,
              label: custType,
              value: name,
            }))
          );
        })
        .catch((err) => {});
    };

    const fetchTranscriptionCategory = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/category/getCategory`;
      const headers = reqHeader(true, true, true);

      const params = { type: "Transcription" };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          setTranscriptionCategories(
            res.data.data.map(({ name, factor }) => ({
              label: name,
              value: factor,
            }))
          );
        })
        .catch((err) => {});
    };

    const fetchTranslationCategory = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/category/getCategory`;
      const headers = reqHeader(true, true, true);

      const params = { type: "Translation" };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          setTranslationCategories(
            res.data.data.map(({ name, factor }) => ({
              label: name,
              value: factor,
            }))
          );
        })
        .catch((err) => {});
    };

    const fetchSubtitlingCategory = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/category/getCategory`;
      const headers = reqHeader(true, true, true);

      const params = { type: "Subtitling" };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          setSubtitlingCategories(
            res.data.data.map(({ name, factor }) => ({
              label: name,
              value: factor,
            }))
          );
        })
        .catch((err) => {});
    };

    // fetchData();

    fetchTranscriptionCategory();

    fetchTranslationCategory();

    fetchSubtitlingCategory();
  }, []);

  useEffect(() => {
    if (custtypes.length > 0) {
      setCustType(custtypes[0]);
    }

    if (transcriptioncategories.length > 0) {
      setTranscriptionCategory(transcriptioncategories[0]);
    }

    if (translationcategories.length > 0) {
      setTranslationCategory(translationcategories[0]);
    }

    if (subtitlingcategories.length > 0) {
      setSubtitlingCategory(subtitlingcategories[0]);
    }
  }, [
    custtypes,
    transcriptioncategories,
    translationcategories,
    subtitlingcategories,
  ]);

  const OnChangeTranscriptionType = (item) => {
    setValue("transcriptionType", item.value);
    setTranscriptionType(item);
  };

  const OnChangeTranscriptionCategory = (item) => {
    setValue("transcriptionCategory", item.value);
    setTranscriptionCategory(item);
  };

  const OnChangeTranslationType = (item) => {
    setValue("translationType", item.value);
    setTranslationType(item);
  };

  const OnChangeTranslationCategory = (item) => {
    setValue("translationCategory", item.value);
    setTranslationCategory(item);
  };

  const OnChangeSubtitlingType = (item) => {
    setValue("subtitlingType", item.value);
    setSubtitlingType(item);
  };

  const OnChangeSubtitlingCategory = (item) => {
    setValue("subtitlingCategory", item.value);
    setSubtitlingCategory(item);
  };

  const OnChangeCustType = (item) => {
    setValue("custType", item.value);
    setCustType(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (data.name !== "" && data.name !== undefined && data.name !== null) {
      const url = `${process.env.REACT_APP_URL}/admin/language/addlanguage`;
      const headers = reqHeader(true, true, true);
      const body = {
        custType: custType.label === "Enterprise Customers" ? "EC" : "OC",
        customer: null,
        custName: custType.value,
        name: data.name,
        code: data.code,
        tesseractCode: data.tcode,
        language: "",
        accent: "",
        isTranscription: transcriptionType.value === "Yes" ? true : false,
        isTranslation: translationType.value === "Yes" ? true : false,
        isSubtitling: subtitlingType.value === "Yes" ? true : false,
        transcriptionCategory: transcriptionCategory.value,
        translationCategory: translationCategory.value,
        subtitlingCategory: subtitlingCategory.value,
        active: data.active,
      };

      const config = { headers };

      axios
        .post(url, body, config)
        .then((res) => {
          if (res.data.success === false && res.data.msg) {
            Swal.fire({
              icon: "error",
              title: "Server Error !!!",
              text: `Error in saving !`,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Created !!!",
              text: `Language added Successfully !`,
            }).then(function () {
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          let e = "";

          for (const [key, value] of Object.entries(errorList)) {
            const keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1);
            e += `${keyCapitalized} ${value} ${error}`;
          }

          e = e === "" ? error : e;

          if (success === false && error !== undefined) {
            Swal.fire({
              icon: "error",
              title: "Server Error !!!",
              text: `${e}`,
            });
          }
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Name is required !!!`,
        });
      } else if (
        custType.value === "" ||
        custType.value === undefined ||
        custType.value === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Customer is required !!!`,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="custommerFormWrapper">
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Name</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Name"
                name="name"
                ref={register}
              />
              {errors.name && (
                <text className="errorText">{errors.name?.message}</text>
              )}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Code</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Code"
                name="code"
                ref={register}
              />
              {errors.code && (
                <text className="errorText">{errors.code?.message}</text>
              )}
            </div>
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Tesseract Code</span>
              <span className="requiredField">*</span>
            </div>
            <div className="inputContainer ">
              <input
                type="text"
                placeholder="Tesseract Code"
                name="tcode"
                ref={register}
              />
              {errors.tcode && (
                <text className="errorText">{errors.tcode?.message}</text>
              )}
            </div>
          </div>

          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Customer</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="custType"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={custType}
                  onChange={OnChangeCustType}
                  placeholder={"Select the Customer"}
                  options={custtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
        <div className="userFormWrapper">
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Subtitling</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="subtitlingType"
              defaultValue={subtitlingtypes[0].value}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={subtitlingType}
                  onChange={OnChangeSubtitlingType}
                  placeholder={"Subtitling Needed ?"}
                  options={subtitlingtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Category</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="transcriptionCategory"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={transcriptionCategory}
                  onChange={OnChangeTranscriptionCategory}
                  placeholder={"Select Transcription Category"}
                  options={transcriptioncategories}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>

          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Translation</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="translationType"
              defaultValue={translationtypes[0].value}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={translationType}
                  onChange={OnChangeTranslationType}
                  placeholder={"Translation Needed ?"}
                  options={translationtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>

          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Category</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="translationCategory"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={translationCategory}
                  onChange={OnChangeTranslationCategory}
                  placeholder={"Select Translation Category"}
                  options={translationcategories}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>

          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Subtitling</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="subtitlingType"
              defaultValue={subtitlingtypes[0].value}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={subtitlingType}
                  onChange={OnChangeSubtitlingType}
                  placeholder={"Subtitling Needed ?"}
                  options={subtitlingtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>

          <div className="textFieldContainer">
            <div className="titieWrapper">
              <span className="title">Category</span>
              <span className="requiredField">*</span>
            </div>
            <Controller
              control={methods.control}
              name="subtitlingCategory"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={subtitlingCategory}
                  onChange={OnChangeSubtitlingCategory}
                  placeholder={"Select Subtitling Category"}
                  options={subtitlingcategories}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
        <div className="bottomButtonSection">
          <div className="checkBoxContainer">
            <input
              type="checkbox"
              placeholder="Active"
              name="active"
              // defaultChecked={active}
              // onChange={() => setActive(!active)}
              ref={register}
              className="checkboxStyle"
            />
            <label>Active</label>
          </div>

          <button className="orangeBtn btn">Submit</button>
        </div>
      </form>
    </>
  );
};

export default NewLanguages;
