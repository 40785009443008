import React, { useState } from "react";
import { CustomDialog } from "react-st-modal";

import LeftMenuList from "../../LeftMenu/LeftMenuList.jsx";
import UserProfile from "../../Profile/userProfile.jsx";
import NewUser from "./NewUser.jsx";
import UsersList from "./UsersList";
import { SearchIcon } from "../../icons.component";
import GlobalFilter from "../../Table/GlobalFilter.jsx";

const Users = () => {
  const activeNodes = ["001", "001_001"];
  const [userList, setUserList] = useState([]);

  const handleNewUser = async (e) => {
    await CustomDialog(<NewUser />, {
      title: "Add New User",
      showCloseIcon: true,
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="001/001_001" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">User</div>

            <UserProfile />
          </div>

          <div className="d-flex breadcrumWrap">
            <ul className="breadcrumb">
              <li>Entities</li>
              <li>User</li>
            </ul>

            <div className="d-flex">
              <button
                className="orangeBtn btn"
                onClick={(e) => handleNewUser(e)}
              >
                Create a New User
              </button>
            </div>
          </div>

          <UsersList Width="100%" userLists={setUserList} />
        </div>
      </div>
    </>
  );
};

export default Users;
