import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { login, microsoftLogin } from "../../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MicrosoftLogin from "react-microsoft-login";
// import Logo from "../assets/images/Logo.svg";

import Logo from "../../assets/images/Logo.svg";
// import { PasswordHide, PasswordShow } from "./icons.component";
import { PasswordHide, PasswordShow } from "../icons.component.jsx";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!"),
  password: yup
    .string()
    .required("Password is required !!!")
    .min(6, "Password should be 6 characters"),
});

const Login = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  let { authenticated, error } = userLogin;
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error, { autoClose: 3000 });
    } else {
    }
  }, [error]);

  const authHandlerMicrosoft = (err, data) => {
    if (data !== undefined && data !== "" && data !== null)
      dispatch(
        microsoftLogin({
          accessToken: data.accessToken,
          userID: data.uniqueId,
          email: data.account.idToken.email,
          name: data.account.idToken.name,
          given_name: data.account.idToken.given_name,
          family_name: data.account.idToken.family_name,
        })
      );
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const submitHandler = (data) => {
    dispatch(login(data));
  };

  if (authenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <div className="container RegisterWrap d-flex">
        <div className="registerLHS">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }}
              target="_blank"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
            <h2 className="loginTitle">Login to your Account</h2>

            <div className="loginType">
              <ul>
                <li className="officeBtn">
                  <MicrosoftLogin
                    clientId={process.env.REACT_APP_MS_APP_ID}
                    authCallback={authHandlerMicrosoft}
                  />
                </li>
              </ul>
            </div>

            <div className="orDivider">or</div>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">
                <div
                  className={`inputWrap emailField ${
                    errors.email ? "error" : ""
                  }`}
                >
                  <input
                    ref={register}
                    type="text"
                    placeholder="Email ID"
                    name="email"
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div
                  className={`inputWrap passwordField ${
                    errors.password ? "error" : ""
                  }`}
                >
                  <input
                    ref={register}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    autoComplete={passwordShown ? "off" : "on"}
                  />
                  <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                    {/* <PasswordHide /> */}
                  </div>
                  {errors.password && (
                    <span className="inputErrorMsg">
                      {errors.password?.message}
                    </span>
                  )}
                  {/* {error && <span className="inputErrorMsg">{error}</span>} */}
                </div>

                <div className="remember-forgotPass d-flex">
                  <div className="rememberMeWrap"></div>

                  <div className="Forgotpassword">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>

                <div className="formFooter">
                  <button>log in</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
