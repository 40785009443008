//Packages
import React from "react";
import { CustomDialog } from "react-st-modal";
import NewDomain from "../Entities/Domains/NewDomain.jsx";
import NewProject from "../Entities/Projects/NewProject.jsx";
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";
import UpLangDomainSpDets from "../Uploads/UpLangDomainSpDets.jsx";
import UserProfile from "../Profile/userProfile.jsx";
import ShakaPlayer from "shaka-player-react";

//styles
import "../../../node_modules/react-h5-audio-player/lib/styles.css";
import "shaka-player/dist/controls.css";

import audio from "../../samples/file.mp3";

const Transcription = () => {
  const activeNodes = ["002"];

  const handleDomain = async (props) => {
    await CustomDialog(<NewDomain serviceType="Transcription" />, {
      title: "Create Domain",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  const handleProject = async (props) => {
    await CustomDialog(<NewProject serviceType="Transcription" />, {
      title: "Transcription Project",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  const handleSpeakerDetails = async (props) => {
    let selectedRows = {
      assignedPM: "pm@megdap.com",
      currency: "INR",
      custCode: "MS",
      custEmail: "microsoft@megdap.com",
      custName: "Microsoft",
      custType: "EC",
      customerID: "614c0a72c376862fe0cd4e1b",
      domains: [
        {
          amount: 0,
          duration: "60:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_HIN_CC_44",
          frequency: "44",
          language: "Hindi",
          name: "Call Center",
          rate: 0,
          _id: "616534146ece942e008d2df6",
        },
        {
          amount: 0,
          duration: "40:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_HIN_YD_44",
          frequency: "44",
          language: "Hindi",
          name: "Youtube Dictation",
          rate: 0,
          _id: "616534146ece942e008d2df7",
        },
        {
          amount: 0,
          duration: "30:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_TAM_YD_8",
          frequency: "8",
          language: "Tamil",
          name: "Youtube Dictation",
          rate: 0,
          _id: "616534146ece942e008d2df8",
        },
        {
          amount: 0,
          duration: "70:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_TAM_CC_8",
          frequency: "8",
          language: "Tamil",
          name: "Call Center",
          rate: 0,
          _id: "616534146ece942e008d2df9",
        },
        {
          amount: 0,
          duration: "90:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_MAL_CC_16",
          frequency: "16",
          language: "Malayalam",
          name: "Call Center",
          rate: 0,
          _id: "616534146ece942e008d2dfa",
        },
        {
          amount: 0,
          duration: "01:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_MAL_GN_16",
          frequency: "16",
          language: "Malayalam",
          name: "General",
          rate: 0,
          _id: "616534146ece942e008d2dfb",
        },
        {
          amount: 0,
          duration: "100:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_ENG_GN_88",
          frequency: "88",
          language: "English",
          name: "General",
          rate: 0,
          _id: "616534146ece942e008d2dfc",
        },
        {
          amount: 0,
          duration: "100:00:00",
          folderPath: "MS_500_Hrs_Bucket_Test_ENG_MD_88",
          frequency: "88",
          language: "English",
          name: "Medical",
          rate: 0,
          _id: "616534146ece942e008d2dfd",
        },
      ],
      folderPath: "MS_500_Hrs_Bucket_Test",
      id: "616534146ece942e008d2df5",
      isToolNeeded: true,
      priority: "Normal",
      projectName: "500 Hrs Bucket Test",
      serviceType: "Transcription",
      srcLang: "Hindi,Tamil,Malayalam,English",
      stage: "Assigned",
      tgtFormat: "JSON",
      tgtLang: "Hindi,Tamil,Malayalam,English",
      totalDuration: "500:00:00",
    };

    await CustomDialog(
      <UpLangDomainSpDets
        selectedRow={selectedRows}
        serviceType="Transcription"
      />,
      {
        title: "Create Domain",
        showCloseIcon: true,
        className: { minwidth: "500px" },
      }
    );
  };

  const urlPath = "../samples/file.mp3";

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="002" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <ul className="breadcrumb">
              <li>Services</li>
              <li>Transcriptions</li>
            </ul>

            <UserProfile />
          </div>
          <div className="d-flex breadcrumWrap">
            <div></div>

            <button className="orangeBtn btn" onClick={handleDomain}>
              Add Domain
            </button>

            <button className="orangeBtn btn" onClick={handleSpeakerDetails}>
              Upload Speaker Details
            </button>

            <button className="orangeBtn btn" onClick={handleProject}>
              Add Project
            </button>
          </div>
          {/* <audio
            // id="audio2"
            // preload="auto"
            // src="file.mp3#t=00:01:00"
            // src="../samples/file.mp3"
            // oncanplaythrough="this.play();"
            controls></audio> */}
          {/* <AudioPlayer
            src={urlPath}
            controls={true}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          /> */}
          {/* <ShakaPlayer src="../samples/file.mp3" autoPlay /> */}
          {/* <ShakaPlayer src="https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd" /> */}

          {/* <ShakaPlayer src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4" /> */}

          {/* <ShakaPlayer src={audio} /> */}

          {/* <ShakaPlayer src="https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd" /> */}
        </div>
      </div>
    </>
  );
};

export default Transcription;
