// Packages
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  fname: yup
    .string()
    .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
    .required("First name is required!"),
  lname: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!"),
  // .required("Last name is required!"),
  email: yup
    .string()
    .required("Email is required!")
    .email("Enter a valid Email ID!"),
  address1: yup
    .string()
    .required("Address1 is required!")
    .matches(
      /^[aA-zZ0-9-_,#/\s]+$/,
      "Only alphabets, numbers, underscore, hyphen, comma, hash and slash are allowed!"
    ),
  country: yup.string().required("Country is required!"),
  state: yup
    .string()
    .max(30, "State should not more than 30 character.")
    .required("State is required!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  city: yup
    .string()
    .max(30, "City should not more than 30 character.")
    .required("City is required!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  zipcode: yup
    .string()
    .max(10, "Postal Code should not more than 10 character.")
    .required("Postal Code is required!")
    .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
  gstNo: yup
    .string()
    .max(18, "Invalid GST No.")
    .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
  code: yup.string().when("custType", {
    is: (custType) => custType === "Enterprise",
    then: yup.string().required("Code is required for Enterprise Customers !"),
  }),
});

toast.configure();

const EditCustomer = (props) => {
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, errors, reset } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [countries, setCountries] = useState([]);
  const [custMobile, setCustMobile] = useState("");

  const { _id, email, code, custType, active, mobile, billingAddress } =
    props.row;

    console.log("customer props", props.row)

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: name,
              value: code,
            }))
          );
        })
        .catch((err) => {});
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (billingAddress && countries.length >= 1) {
      reset({
        fname: billingAddress.firstName,
        lname: billingAddress.lastName,
        code: code,
        email: billingAddress.email,
        mobile:
          mobile !== "" && mobile !== undefined
            ? mobile
            : billingAddress.mobile,
        custtype: custType,
        active: active,
        gstNo: billingAddress.GSTIN,
        address1: billingAddress.address1,
        address2: billingAddress.address2,
        country: billingAddress.country,
        state: billingAddress.state,
        city: billingAddress.city,
        zipcode: billingAddress.zipcode,
      });

      setCustMobile(
        mobile !== "" && mobile !== undefined ? mobile : billingAddress.mobile
      );
    }
  }, [reset, billingAddress, code, custType, active, mobile, countries]);

  const submitHandler = (data, e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_URL}/admin/users/updatecustomer`;
    const headers = reqHeader(true, true, true);
    const body = {
      id: _id,
      fname: data.fname,
      lname: data.lname,
      code: data.code,
      mobile: custMobile,
      custType: custType,
      active: data.active,
      email: email,
      billingAddress: {
        firstName: data.fname,
        lastName: data.lname,
        invName: data.fname + " " + data.lname,
        GSTIN: data.gstNo,
        email: email,
        mobile: custMobile,
        address1: data.address1,
        address2: data.address2,
        country: data.country,
        state: data.state,
        city: data.city,
        zipcode: data.zipcode,
      },
    };

    const config = { headers };

    axios
      .put(url, body, config)
      .then((res) => {
        if (res.data.success === false && res.data.msg) {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `Error in Updation`,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Customer Updated !!!",
            text: `Customer Updated Successfully !!!`,
          }).then(function () {
            dialog.close();
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error !!!",
          text: `${
            err.message && err.response.data.error
              ? err.response.data.error
              : err.message
          }`,
        });
      });
  };

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className={`inputWrap ${errors.fname ? "error" : ""}`}>
                  <label className="label">
                    First Name<span className="requiredField">*</span>
                  </label>
                  <input type="text" name="fname" ref={register} />
                  {errors.fname && (
                    <span className="inputErrorMsg">
                      {errors.fname?.message}
                    </span>
                  )}
                </div>
                <div className="inputWrap">
                  <label className="label">Last Name</label>
                  <input type="text" name="lname" ref={register} />
                </div>

                <div className={`inputWrap ${errors.code ? "error" : ""}`}>
                  <label className="label">
                    Code
                    {custType.value === "Enterprise" ? (
                      <span className="requiredField">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    type="text"
                    placeholder="Code"
                    name="code"
                    ref={register}
                    readOnly
                  />
                  {/* {errors.code && (
                    <span className="inputErrorMsg">
                      {errors.code?.message}
                    </span>
                  )} */}
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Email<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    ref={register}
                    readOnly
                  />
                </div>

                <div className="inputWrap">
                  <label htmlFor="" className="label">
                    Mobile No.
                  </label>

                  <div
                    className={`inputWrap ${
                      formatPhoneNumberIntl(custMobile) &&
                      !isValidPhoneNumber(custMobile)
                        ? "error"
                        : ""
                    }`}>
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      placeholder="Enter Mobile Number"
                      value={formatPhoneNumberIntl(custMobile)}
                      onChange={(phone) => setCustMobile(phone)}
                      name="mobile"
                      ref={register}
                    />
                    {formatPhoneNumberIntl(custMobile) &&
                    !isValidPhoneNumber(custMobile) ? (
                      <span className="inputErrorMsg">
                        {" "}
                        Invalid phone number{" "}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Customer Type</label>
                  <input type="text" readOnly name="custtype" ref={register} />
                </div>

                <div className="inputWrap">
                  <label className="label">Active</label>
                  <input
                    type="checkbox"
                    placeholder="Active"
                    name="active"
                    defaultChecked={active}
                    style={{ height: "15px" }}
                    ref={register}
                  />
                </div>
                {console.log("customer active", active)}
              </div>
              <div className={`inputWrap ${errors.address1 ? "error" : ""}`}>
                <label className="label">
                  Address 1<span className="requiredField">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Address 1"
                  name="address1"
                  ref={register}
                />
                {errors.address1 && (
                  <span className="inputErrorMsg">
                    {errors.address1?.message}
                  </span>
                )}
              </div>

              <div className={`inputWrap ${errors.address2 ? "error" : ""}`}>
                <label className="label">Address 2</label>
                <input
                  type="text"
                  placeholder="Address 2"
                  name="address2"
                  ref={register}
                />
                {errors.address2 && (
                  <span className="inputErrorMsg">
                    {errors.address2?.message}
                  </span>
                )}
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Country <span className="requiredField">*</span>
                  </label>
                  <select name="country" ref={register}>
                    <option key="" value="">
                      Select Country
                    </option>
                    {countries.map((op) => (
                      <option key={op.id} value={op.value}>
                        {op.label}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <span className="inputErrorMsg">
                      {errors.country?.message}
                    </span>
                  )}
                </div>
                <div className="inputWrap">
                  <label className="label">
                    State <span className="requiredField">*</span>
                  </label>

                  <div className={`inputWrap ${errors.state ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      ref={register}
                    />

                    {errors.state && (
                      <span className="inputErrorMsg">
                        {errors.state?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    City <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap ${errors.city ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      ref={register}
                    />
                    {errors.city && (
                      <span className="inputErrorMsg">
                        {errors.city?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Postal Code <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap ${errors.zipcode ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Postal Code"
                      name="zipcode"
                      ref={register}
                    />
                    {errors.zipcode && (
                      <span className="inputErrorMsg">
                        {errors.zipcode?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">GST No.</label>
                  <div className="inputWrap">
                    <input
                      type="text"
                      placeholder="GST No."
                      name="gstNo"
                      ref={register}
                    />
                    {errors.gstNo && (
                      <span className="inputErrorMsg">
                        {errors.gstNo?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <button className="orangeBtn btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditCustomer;
