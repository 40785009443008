//Packages
import React, { useState, useEffect, useMemo } from "react";
import { CustomDialog } from "react-st-modal";
import axios from "axios";

//Custom Components
import Table from "../../Table/Table";
import reqHeader from "../../../helper/reqHeader.js";
import EditDomain from "./EditDomain.jsx";
import { EditIcon } from "../../../svgIconComponent/svg_icon_components";

const DomainsList = () => {
  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span title={value}>{value}</span>,
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span title={value}>{value}</span>,
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span title={value}>{value}</span>,
      },
      {
        Header: "Code",
        accessor: "code",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span title={value}>{value}</span>,
      },
      {
        Header: "Active",
        accessor: "active",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row, cell }) => <span title={value}>{value}</span>,
      },
      {
        Header: "",
        accessor: "edit",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <div className="editBtnWraper">
            <EditIcon />
            <button
              className="editBtn"
              value={value}
              onClick={(e) => editDomains(row.original, value)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const [datalist, setDataList] = useState([]);

  useEffect(() => {
    let controller = new AbortController();

    async function getDomains() {
      const url = `${process.env.REACT_APP_URL}/admin/domain/getalldomains`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          setDataList(res.data.data);
        })
        .catch((err) => {
          setDataList([]);
        });
    }

    getDomains();

    //aborts the request when the component umounts
    return () => controller?.abort();
  }, []);

  const editDomains = async (props) => {
    await CustomDialog(<EditDomain selectedRow={props} />, {
      title: "Edit Domains",
      showCloseIcon: true,
    });
  };

  return (
    <>
      <Table
        columns={columns}
        data={datalist}
        count={datalist.length}
        pagination={true}
        expanded={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="Domains"
        isMultiSelect={false}
        getHeaderProps={(column) => ({})}
        getColumnProps={(column) => ({})}
        getRowProps={(row) => ({
          // onClick: () => console.log(`row clicked`, row),
        })}
        getCellProps={(cellInfo) => ({})}
      />
    </>
  );
};

export default DomainsList;
