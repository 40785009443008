//Packages
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Swal from "sweetalert2";

//Custom Components
import Table from "../../Table/Table.jsx";
import LeftMenuList from "../../LeftMenu/LeftMenuList.jsx";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "react-toastify/dist/ReactToastify.css";

const VendorOnBoarding = () => {
  const [changedRow, setChangedRow] = useState();
  const [isUpdated, setIsUpdated] = useState(false);

  const history = useHistory();

  const IndeterminateSelect = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      const { value, selectedRow: row } = { ...rest };

      let val = { label: value, value: value };

      const [roles] = useState([
        { label: "Open", value: "Open" },
        { label: "Approved", value: "Approved" },
        { label: "Rejected", value: "Rejected" },
      ]);
      const [role, setRole] = useState(val);

      const OnChangeRole = (item) => {
        setRole(item);

        let rows = {
          id: row.id,
          type: row.type,
          language: row.language,
          status: item.value,
        };

        setChangedRow(rows);
        setIsUpdated(false);
      };

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <Select
            ref={resolvedRef}
            name="role"
            value={role}
            onChange={OnChangeRole}
            placeholder={"Select Status"}
            options={roles}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.value}
            // {...rest}
          />
        </>
      );
    }
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "id",
      // },
      {
        Header: "Language",
        accessor: "language",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row, value }) => (
          <div>
            <IndeterminateSelect value={value} selectedRow={row.original} />
          </div>
        ),
      },
    ],
    []
  );

  const [vendTranscriberLanguages, setVendTranscriberLanguages] = useState([]);
  const [vendTranslatorLanguages, setVendTranslatorLanguages] = useState([]);
  const [vendSubtitlerLanguages, setVendSubtitlerLanguages] = useState([]);

  const [prTranscriberLanguages, setPrTranscriberLanguages] = useState([]);
  const [prTranslatorLanguages, setPrTranslatorLanguages] = useState([]);
  const [prSubtitlerLanguages, setPrSubtitlerLanguages] = useState([]);

  const [vendTranscriberData, setVendTranscriberData] = useState([]);
  const [vendTranslatorData, setVendTranslatorData] = useState([]);
  const [vendSubtitlerData, setVendSubtitlerData] = useState([]);

  const [prTranscriberData, setPrTranscriberData] = useState([]);
  const [prTranslatorData, setPrTranslatorData] = useState([]);
  const [prSubtitlerData, setPrSubtitlerData] = useState([]);

  const activeNodes = ["001", "001_002"];

  const location = useLocation();

  const props = location.state !== undefined ? location.state.selectedRow : "";

  const {
    _id: id,
    fname,
    lname,
    mobile,
    email,
    vendorType,
    vendTranscriberLang,
    vendTranslatorLang,
    vendSubtitlerLang,
    prType,
    prTranscriberLang,
    prTranslatorLang,
    prSubtitlerLang,
  } = props;

  useEffect(() => {
    if (vendTranscriberLang !== undefined && vendTranscriberLang.length >= 1) {
      let languages = vendTranscriberLang.map((value, index) => {
        return {
          id: value._id,
          type: "vendTranscriberLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setVendTranscriberLanguages(languages);
    }

    if (vendTranslatorLang !== undefined && vendTranslatorLang.length >= 1) {
      let languages = vendTranslatorLang.map((value, index) => {
        return {
          id: value._id,
          type: "vendTranslatorLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setVendTranslatorLanguages(languages);
    }

    if (vendSubtitlerLang !== undefined && vendSubtitlerLang.length >= 1) {
      let languages = vendSubtitlerLang.map((value, index) => {
        return {
          id: value._id,
          type: "vendSubtitlerLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setVendSubtitlerLanguages(languages);
    }

    if (prTranscriberLang !== undefined && prTranscriberLang.length >= 1) {
      let languages = prTranscriberLang.map((value, index) => {
        return {
          id: value._id,
          type: "prTranscriberLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setPrTranscriberLanguages(languages);
    }

    if (prTranslatorLang !== undefined && prTranslatorLang.length >= 1) {
      let languages = prTranslatorLang.map((value, index) => {
        return {
          id: value._id,
          type: "prTranslatorLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setPrTranslatorLanguages(languages);
    }

    if (prSubtitlerLang !== undefined && prSubtitlerLang.length >= 1) {
      let languages = prSubtitlerLang.map((value, index) => {
        return {
          id: value._id,
          type: "prSubtitlerLang",
          language: value.type,
          status: value.status === undefined ? "Open" : value.status,
        };
      });

      setPrSubtitlerLanguages(languages);
    }
  }, [
    vendTranscriberLang,
    vendTranslatorLang,
    vendSubtitlerLang,
    prTranscriberLang,
    prTranslatorLang,
    prSubtitlerLang,
  ]);

  useEffect(() => {
    if (
      vendTranscriberLanguages !== undefined &&
      vendTranscriberLanguages.length >= 1
    ) {
      setVendTranscriberData(vendTranscriberLanguages);
    }

    if (
      vendTranslatorLanguages !== undefined &&
      vendTranslatorLanguages.length >= 1
    ) {
      setVendTranslatorData(vendTranslatorLanguages);
    }

    if (
      vendSubtitlerLanguages !== undefined &&
      vendSubtitlerLanguages.length >= 1
    ) {
      setVendSubtitlerData(vendSubtitlerLanguages);
    }

    if (
      prTranscriberLanguages !== undefined &&
      prTranscriberLanguages.length >= 1
    ) {
      setPrTranscriberData(prTranscriberLanguages);
    }

    if (
      prTranslatorLanguages !== undefined &&
      prTranslatorLanguages.length >= 1
    ) {
      setPrTranslatorData(prTranslatorLanguages);
    }

    if (
      prSubtitlerLanguages !== undefined &&
      prSubtitlerLanguages.length >= 1
    ) {
      setPrSubtitlerData(prSubtitlerLanguages);
    }
  }, [
    vendTranscriberLanguages,
    vendTranslatorLanguages,
    vendSubtitlerLanguages,
    prTranscriberLanguages,
    prTranslatorLanguages,
    prSubtitlerLanguages,
  ]);

  useEffect(() => {
    if (changedRow !== undefined && isUpdated === false) {
      let dataList = [];

      if (changedRow.type === "vendTranscriberLang") {
        vendTranscriberData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setVendTranscriberData(dataList);

        setIsUpdated(true);
      } else if (changedRow.type === "vendTranslatorLang") {
        vendTranslatorData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setVendTranslatorData(dataList);

        setIsUpdated(true);
      } else if (changedRow.type === "vendSubtitlerLang") {
        vendSubtitlerData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setVendSubtitlerData(dataList);

        setIsUpdated(true);
      } else if (changedRow.type === "prTranscriberLang") {
        prTranscriberData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrTranscriberData(dataList);

        setIsUpdated(true);
      } else if (changedRow.type === "prTranslatorLang") {
        prTranslatorData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrTranslatorData(dataList);

        setIsUpdated(true);
      } else if (changedRow.type === "prSubtitlerLang") {
        prSubtitlerData.forEach((value) => {
          let dataRows;
          if (changedRow.id === value.id) {
            dataRows = changedRow;
          } else {
            dataRows = {
              id: value.id,
              type: value.type,
              language: value.language,
              status: value.status,
            };
          }

          dataList = [...dataList, dataRows];
        });

        setPrSubtitlerData(dataList);

        setIsUpdated(true);
      }
    }
  }, [
    changedRow,
    isUpdated,
    vendTranscriberData,
    vendTranslatorData,
    vendSubtitlerData,
    prTranscriberData,
    prTranslatorData,
    prSubtitlerData,
  ]);

  const handleSubmit = (type) => {
    const url = `${process.env.REACT_APP_URL}/admin/users/updateOnBoardingVendor`;
    const headers = reqHeader(true, true, true);
    const config = { headers };

    const body = {
      id: id,
      fname: fname,
      lname: lname,
      email: email,
      type: type,
      vendType: vendorType,
      prType: prType,
      vendTranscriberData:
        vendTranscriberData === undefined ? [] : vendTranscriberData,
      vendTranslatorData:
        vendTranslatorData === undefined ? [] : vendTranslatorData,
      vendSubtitlerData:
        vendSubtitlerData === undefined ? [] : vendSubtitlerData,
      prTranscriberData:
        prTranscriberData === undefined ? [] : prTranscriberData,
      prTranslatorData: prTranslatorData === undefined ? [] : prTranslatorData,
      prSubtitlerData: prSubtitlerData === undefined ? [] : prSubtitlerData,
    };

    axios
      .put(url, body, config)
      .then((res) => {
        if (res.data.success === false && res.data.msg) {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${res.data.msg}`,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Vendor Onboarding !!!",
            text: `Vendor Status updated Successfully !!!`,
          }).then(function () {
            history.push({
              pathname: "/vendors",
            });
          });
        }
      })
      .catch((err) => {
        const { success, error } = err.response.data;

        if (success === false && error !== undefined) {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error.message && error ? error : err.message}`,
          });
        }
      });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="001/001_003" activeNodes={activeNodes} />

        <div className="pageRight d-flex">
          {/* <div className="userSection">
            <ul className="breadcrumb">
              <li>Entites</li>
              <li>Vendors</li>
              <li>On Boarding</li>
            </ul>

            <UserProfile />
          </div> */}

          <div className="contentLeft">
            <div className="block">
              <div className="addProjectDetails">
                <div className="d-flex createOption">
                  <div className="inputWrap">
                    <label className="label">Name</label>
                    <input
                      type="text"
                      placeholder="Vendor Name"
                      name="name"
                      readOnly={true}
                      value={`${fname} ${lname}`}
                    />
                  </div>
                  <div className="inputWrap">
                    <label className="label">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      name="name"
                      readOnly={true}
                      value={email}
                    />
                  </div>
                  <div className="inputWrap">
                    <label className="label">Mobile</label>
                    <input
                      type="text"
                      placeholder="Mobile"
                      name="name"
                      readOnly={true}
                      value={mobile}
                    />
                  </div>
                </div>
              </div>

              <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
                <TabList>
                  {vendorType.map((value, index) => (
                    <Tab key={index}> {value.type}</Tab>
                  ))}
                </TabList>
                {vendorType.map((value, index) =>
                  value.type !== "Proof Reader" ? (
                    <TabPanel key={index}>
                      <Table
                        columns={columns}
                        data={
                          value.type === "Transcriber"
                            ? vendTranscriberData
                            : value.type === "Translator"
                            ? vendTranslatorData
                            : vendSubtitlerData
                        }
                        count={
                          value.type === "Transcriber"
                            ? vendTranscriberData.length
                            : value.type === "Translator"
                            ? vendTranslatorData.length
                            : value.type === "Subtitler"
                            ? vendSubtitlerData.length
                            : 0
                        }
                        pagination={true}
                      />

                      <button
                        className="orangeBtn btn"
                        onClick={() => handleSubmit(`Vendor ${value.type}`)}>
                        Submit
                      </button>
                    </TabPanel>
                  ) : (
                    <TabPanel key={index}>
                      <Tabs
                        forceRenderTabPanel
                        defaultIndex={0}
                        defaultFocus={true}>
                        <TabList>
                          {prType.map((value, index) => (
                            <Tab key={index}>
                              {/* {value.type === "Transcription"
                                ? "Transcriber"
                                : value.type === "Translation"
                                ? "Translator"
                                : "Subtitler"} */}
                              {value.type}
                            </Tab>
                          ))}
                        </TabList>
                        {prType.map((value, index) => (
                          <TabPanel key={index}>
                            <Table
                              columns={columns}
                              data={
                                value.type === "Transcription"
                                  ? prTranscriberData
                                  : value.type === "Translation"
                                  ? prTranslatorData
                                  : prSubtitlerData
                              }
                              count={
                                value.type === "Transcription"
                                  ? prTranscriberData.length
                                  : value.type === "Translation"
                                  ? prTranslatorData.length
                                  : prSubtitlerData.length
                              }
                              pagination={true}
                            />

                            <button
                              className="orangeBtn btn"
                              onClick={() =>
                                handleSubmit(`Proof Reader ${value.type}`)
                              }>
                              Submit
                            </button>
                          </TabPanel>
                        ))}
                      </Tabs>
                    </TabPanel>
                  )
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorOnBoarding;
