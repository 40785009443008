import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Select from "react-select";
import { useDialog, ModalContent, ModalFooter, ModalButton } from "react-st-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import Spinner from "../Utils/Spinner.jsx";
import { CSVLink } from "react-csv";

const UploadLangDomain = (props) => {
  const {
    id,
    projectName = "",
    customerID,
    custName = "",
    custEmail = "",
    custType = "",
    custCode = "",
    srcLang = "",
    tgtFormat = "",
    isToolNeeded = false,
    ETA,
    domainID,
    domains: dataDomains,
  } = props.selectedRow;

  const { serviceType, src } = props;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [spinnerMessage, setSpinnerMessage] = useState("");
  const [upload, setUpload] = useState(false);

  const [languages, setLanguages] = useState("");
  const [language, setLanguage] = useState();
  const [domains, setDomains] = useState("");
  const [domain, setDomain] = useState();
  const [frequencies, setFrequencies] = useState("");
  const [frequency, setFrequency] = useState();
  const [duration, setDuration] = useState("00:00:00");
  const [upDuration, setUpDuration] = useState("00:00:00");
  const [fileList, setFileList] = useState([]);
  const [folderPath, setFolderPath] = useState("");
  const [csvData, setcsvData] = useState([]);

  // use this hook to control the dialog
  const dialog = useDialog();

  toast.configure();

  const acceptExtensions = ".wav";
  const readOnly = src === "LangDomain" ? true : false;

  const OnChangeLanguages = (item) => {
    setLanguage(item);
    setDomain(null);
    setFrequency(null);
    setDuration("00:00:00");
    setUpDuration("00:00:00");
  };

  const OnChangeDomain = (item) => {
    setDomain(item);
    setFrequency(null);
    setDuration("00:00:00");
    setUpDuration("00:00:00");
  };

  const OnChangeFrequencies = (item) => {
    setFrequency(item);
  };

  useEffect(() => {
    if (srcLang !== "") {
      const lang = srcLang.split(",");

      const langs = lang.map((value, index) => {
        return { id: index + 1, name: value, value: value };
      });

      setLanguages(langs);
    }
  }, [srcLang]);

  useEffect(() => {
    const getUploadedDuration = async () => {
      //serviceType
      const url = `${process.env.REACT_APP_URL}/admin/transcription/domainuploadedduration`;
      const headers = reqHeader(true, true, true);
      const params = {
        id: id,
        customerID: customerID,
        language: language.name,
        domain: domain.name,
        frequency: frequency.name,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          setUpDuration(res.data.data);
          setFileList(res.data.fileList);
        })
        .catch((err) => {
          setUpDuration("00:00:00");
          setFileList([]);
        });
    };

    if (src === "LangDomain") {
      if (dataDomains.length === 1) {
        if (
          dataDomains[0].language !== undefined &&
          dataDomains[0].language !== null &&
          dataDomains[0].language !== "" &&
          dataDomains[0].domain !== undefined &&
          dataDomains[0].domain !== null &&
          dataDomains[0].domain !== "" &&
          dataDomains[0].frequency !== undefined &&
          dataDomains[0].frequency !== null &&
          dataDomains[0].frequency !== ""
        ) {
          if (
            language === undefined ||
            language === null ||
            domain === undefined ||
            domain === null ||
            frequency === undefined ||
            frequency === null
          ) {
            setLanguage({
              id: 1,
              name: dataDomains[0].language,
              value: dataDomains[0].language,
            });
            setDomain({
              id: 1,
              name: dataDomains[0].domain,
              value: dataDomains[0].domain,
            });
            setFrequency({
              id: 1,
              name: dataDomains[0].frequency,
              value: dataDomains[0].frequency,
            });
            setDuration(dataDomains[0].duration);
            setFolderPath(dataDomains[0].folderPath);
            setUpDuration(dataDomains[0].upDuration);
          } else {
            getUploadedDuration();
          }
        }
      }
    } else {
      let doms, uniqueDoms;

      if (language !== undefined && language !== null) {
        if (dataDomains !== undefined || dataDomains.length > 0) {
          doms = dataDomains.filter((value, index) => {
            return value.language === language.name;
          });

          uniqueDoms = [...new Set(doms.map((val) => val.name))];

          uniqueDoms = uniqueDoms.map((val, ind) => {
            return { id: ind + 1, name: val, value: val };
          });
        }

        setDomains(uniqueDoms);
      }

      if (
        language !== undefined &&
        language !== null &&
        domain !== undefined &&
        domain !== null
      ) {
        if (dataDomains !== undefined || dataDomains.length > 0) {
          doms = dataDomains
            .filter((value, index) => {
              return (
                value.language === language.name && value.name === domain.name
              );
            })
            .map((val, ind) => {
              return { id: ind + 1, name: val.frequency, value: val.frequency };
            });
        }

        setFrequencies(doms);
      }

      if (
        language !== undefined &&
        language !== null &&
        domain !== undefined &&
        domain !== null &&
        frequency !== undefined &&
        frequency !== null
      ) {
        if (dataDomains !== undefined || dataDomains.length > 0) {
          doms = dataDomains
            .filter((value, index) => {
              return (
                value.language === language.name &&
                value.name === domain.name &&
                value.frequency === frequency.name
              );
            })
            .map((val, ind) => {
              return { duration: val.duration, folderPath: val.folderPath };
            });

          setDuration(doms.length < 1 ? "00:00:00" : doms[0].duration);

          setFolderPath(doms.length < 1 ? "" : doms[0].folderPath);
        }

        getUploadedDuration();
      }
    }
  }, [id, customerID, dataDomains, language, domain, frequency, upload]);

  const handleSubmit = async (files, allFiles) => {
    allFiles.forEach((f) => f.remove());

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);

    if (
      language !== undefined &&
      language !== null &&
      domain !== undefined &&
      domain !== null &&
      frequency !== undefined &&
      frequency !== null
    ) {
      const [hours, minutes, seconds] = duration.split(":");
      const durationSecs = +hours * 60 * 60 + +minutes * 60 + +seconds;

      const [upHours, upMinutes, upSeconds] = upDuration.split(":");
      const upDurationSecs = +upHours * 60 * 60 + +upMinutes * 60 + +upSeconds;

      // if (upDurationSecs > durationSecs) {
      //   toast.error("Uploaded Duration has crossed the required Duration!");
      //   return;
      // }

      setSpinnerMessage("Uploading files... Please Wait!");
      setLoading(true);

      let existedFiles = [];

      for (let newFile of file) {
        let exists = fileList.indexOf(newFile.name);

        if (exists === 0) {
          existedFiles.push(newFile.name);
        }
      }

      if (existedFiles.length > 0) {
        toast.error(
          <div>
            The below files are already uploaded :-
            <br />
            {existedFiles.map((value) => {
              return (
                <span key={value}>
                  {value} <br />
                </span>
              );
            })}
          </div>
        );

        setLoading(false);
        setUpload(!upload);

        return;
      } else {
        await fileTextList(file, meta);

        await uploadDuration();
        
        setLoading(false);
        setUpload(!upload);
        //dialog.close(window.location.reload());
      }
    } else {
      if (language === undefined || language === null) {
        toast.error("Kindly select the Language!");
      } else if (domain === undefined || domain === null) {
        toast.error("Kindly select the Domain!");
      } else if (frequency === undefined || frequency === null) {
        toast.error("Kindly select the Frequency!");
      }
    }
  };

  const fileTextList = async (files, metas) => {
    let counter = 0;
    let failedFileList = [];
    for (let file of files) {
      try {
        const url = `${process.env.REACT_APP_URL}/admin/upload/uploadprojectfiles`;
        const headers = reqHeader(true, true, true);

        const config = { headers };

        const body = {
          projectID: id,
          projectName: projectName,
          customerID: customerID,
          custName: custName,
          custEmail: custEmail,
          custType: custType,
          custCode: custCode,
          serviceType: serviceType,
          srcLang: language.value,
          tgtLang: language.value,
          fileLocation: "",
          fileName: file.name,
          fileSize: file.size,
          duration:
            metas[counter].duration === undefined
              ? "0"
              : metas[counter].duration,
          eta: ETA,
          folderPath: folderPath,
          source: src,
          domain: domain.value,
          frequency: frequency.value,
          tgtFormat: tgtFormat,
          isToolNeeded: isToolNeeded,
        };

        const { data } = await axios.post(url, body, config);
        console.log("data", data)
        if (data.msg == "Upload success" && data.success == true) {
          const signedurl = data.data.url;
          const urls = data.data.srcuploadpath;

          await sendFile(signedurl, files[counter], urls);
        }else{
          failedFileList.push(file.name);
          console.log("file name", failedFileList)
        }
      } catch (error) {}

      counter = counter + 1;
      console.log("Counter", counter)
    }

    if(failedFileList.length > 0){
      //console.log("file name", failedFileList);
      let getcsvdata =[]
      failedFileList.map((data)=>{
        let obj = {"fileName":data}
        getcsvdata.push(obj)
        setcsvData(getcsvdata)
      });
      toast.error(`Something went wrong, Please check failed file list `);
    }else{
      toast.success(`File Uploaded Successfully !!!`);
    }
    // if(csvData && csvData.length > 0){
    //   toast.error(`Something went wrong, Please check failed file list `);
    //   //setcsvData([]);
    // }else{
    //   toast.success(`File Uploaded Successfully !!!`);
    // }
    return counter;
  };
 // console.log("csvData", csvData);

  const sendFile = (signedUrl, file, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(signedUrl);
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        }
      };

      xhr.upload.onprogress = (evt) => {
        // For uploads
        if (evt.lengthComputable) {
          let percentComplete = ((evt.loaded / evt.total) * 100).toFixed(2);
          setProgress(percentComplete);
          console.log("progress uploading ", percentComplete);
        }
      };

      xhr.onloadend = async function () {
        // setLoading(false);
        // toast.success(`File Uploaded Successfully !!!`);
        // setTimeout(() => {
        //   dialog.close(url);
        //   window.location.reload();
        // }, 2000);
      };

      xhr.open("PUT", signedUrl);
      xhr.setRequestHeader("Content-type", "application/octet-stream");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(file);
    });
  };

  const uploadDuration = async () => {
    const url = `${process.env.REACT_APP_URL}/admin/transcription/updateuploaddurations`;
    const headers = reqHeader(true, true, true);

    const params = {
      projectID: id,
      language: language.value,
      domain: domain.value,
      frequency: frequency.value,
      domainID,
    };

    const body = {};

    const config = { headers, params };

    await axios
      .put(url, body, config)
      .then((res) => {})
      .catch((err) => {});
  };

  return loading ? (
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} />
  ) : (
    <>
      <form action="#" encType="multipart/form-data">
        <div className="contentLeft">
          <div className="block">
          <ModalContent>
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Language<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="language"
                    value={language}
                    onChange={OnChangeLanguages}
                    placeholder={"Select Languages"}
                    options={languages}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Domain<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="domain"
                    value={domain}
                    onChange={OnChangeDomain}
                    placeholder={"Select Domain"}
                    options={domains}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Frequency<span className="requiredField">*</span>
                  </label>
                  <Select
                    name="frequency"
                    value={frequency}
                    onChange={OnChangeFrequencies}
                    placeholder={"Select Frequency"}
                    options={frequencies}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.value}
                  />
                </div>
              </div>
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Total Duration<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Duration"
                    name="duration"
                    value={duration}
                    readOnly={true}
                  />
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Uploaded Duration<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Uploaded Duration"
                    name="upDuration"
                    value={upDuration}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>

            <Dropzone
              onSubmit={handleSubmit}
              autoUpload={false}
              // maxFiles={1}
              styles={{
                dropzone: {
                  overflow: "auto",
                  background: "#F1FAED",
                  border: "2px solid #78CF4D",
                },
                inputLabelWithFiles: { display: "none" },
                inputLabel: {
                  color: "#78CF4D",
                },
              }}
              canRemove={true}
              accept={acceptExtensions}
              inputContent={(files, extra) =>
                extra.reject
                  ? "Audio and Video files only"
                  : "Drag & Drop files here or click to select the file"
              }
            />
            {/* </div> */}
            </ModalContent>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  dialog.close(window.location.reload());
                  setcsvData([]);
                }}
              >
                Close
              </ModalButton>
                &nbsp;
                {console.log("csvData in html", csvData)}
              {csvData.length > 0 ? <CSVLink className="orangeBtn btn" data={csvData} target="_blank">
                Failed Filelist
              </CSVLink> : ""}
              
            </ModalFooter>
          </div>
        </div>
      </form>
    </>
  );
};

export default UploadLangDomain;
