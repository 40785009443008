//Packages
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "react-phone-number-input/style.css";

const NewVendor = () => {
  const schema = yup.object().shape({
    fname: yup
      .string()
      .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
      .required("First name is required!"),
    // lname: yup
    //   .string()
    //   .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!")
    //   .required("Last name is required!"),
    email: yup
      .string()
      .required("Email is required!")
      .email("Enter a valid Email ID!"),
  });

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [vendtypes] = useState([
    { label: "Transcriber", value: "Transcriber" },
    { label: "Translator", value: "Translator" },
    { label: "Subtitler", value: "Subtitler" },
    { label: "Proof Reader", value: "Proof Reader" },
  ]);
  const [vendType, setVendType] = useState();

  const [vendlanguages, setvendlanguages] = useState([]);
  const [vendTranscriptionLang, setVendTranscriptionLang] = useState();
  const [vendTranslationLang, setVendTranslationLang] = useState();
  const [vendSubtitlingLang, setVendSubtitlingLang] = useState();

  const [prtypes] = useState([
    { label: "Transcription", value: "Transcription" },
    { label: "Translation", value: "Translation" },
    { label: "Subtitling", value: "Subtitling" },
  ]);
  const [prType, setPrType] = useState();

  const [prlanguages, setprlanguages] = useState([]);
  const [prTranscriptionLang, setPrTranscriptionLang] = useState();
  const [prTranslationLang, setPrTranslationLang] = useState();
  const [prSubtitlingLang, setPrSubtitlingLang] = useState();

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();

  const [currencies] = useState([
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
    { label: "EURO", value: "EURO" },
  ]);
  const [currency, setCurrency] = useState(currencies[0]);

  const [mobile, setMobile] = useState("");

  const [timings] = useState([
    { label: "Full Time", value: "Full Time" },
    { label: "Part Time", value: "Part Time" },
  ]);

  const [categories] = useState([
    { label: "Individual", value: "Individual" },
    { label: "Sub Contract", value: "Sub Contract" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: code,
              value: name,
            }))
          );
          // setCountries(res.data.data);
        })
        .catch((err) => {});
    };

    const getlanguages = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/language/alllanguages`;

      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          setvendlanguages(
            res.data.data.map(({ _id, language }, index) => ({
              key: index,
              label: language,
              value: language,
            }))
          );

          setprlanguages(
            res.data.data.map(({ _id, language }, index) => ({
              key: index,
              label: language,
              value: language,
            }))
          );
        })
        .catch((err) => {});
    };

    fetchData();

    getlanguages();
  }, []);

  useEffect(() => {
    if (countries.length >= 1) {
      let index = countries.findIndex((x) => x.label === "IN");
      setValue("countries", countries.value);
      setCountry(countries[index === -1 ? 0 : index]);
    }
  }, [countries, setValue]);

  const OnChangeVendType = (item) => {
    setValue("vendType", item.value);
    setVendType(item);
  };

  const OnChangeTranscriptionVendLang = (item) => {
    setValue("vendTranscriptionLang", item.value);
    setVendTranscriptionLang(item);
  };

  const OnChangeTranslationVendLang = (item) => {
    setValue("vendTranslationLang", item.value);
    setVendTranslationLang(item);
  };

  const OnChangeSubtitlingVendLang = (item) => {
    setValue("vendSubtitlingLang", item.value);
    setVendSubtitlingLang(item);
  };

  const OnChangePrType = (item) => {
    setValue("prType", item.value);
    setPrType(item);
  };

  const OnChangeTranscriptionPrLang = (item) => {
    setValue("prTranscriptionLang", item.value);
    setPrTranscriptionLang(item);
  };

  const OnChangeTranslationPrLang = (item) => {
    setValue("prTranslationLang", item.value);
    setPrTranslationLang(item);
  };

  const OnChangeSubtitlingPrLang = (item) => {
    setValue("prSubtitlingLang", item.value);
    setPrSubtitlingLang(item);
  };

  const OnChangeCountries = (item) => {
    setValue("countries", item.value);
    setCountry(item);
  };

  const OnChangeCurrency = (item) => {
    setValue("currency", item.value);
    setCurrency(item);
  };

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (
      data.fname !== "" &&
      data.fname !== undefined &&
      data.fname !== null &&
      data.email !== "" &&
      data.email !== undefined &&
      data.email !== null &&
      vendType !== undefined
    ) {
      let pfIndex = vendType.findIndex((x) => x.value === "Proof Reader");

      if (pfIndex === -1) {
        let flag = false;

        vendType.forEach((val) => {
          if (
            val.value === "Transcriber" &&
            vendTranscriptionLang === undefined
          ) {
            flag = false;
            Swal.fire({
              icon: "error",
              title: "Error !!!",
              text: `Vendor Transcription Languages is required`,
            });
          } else if (
            val.value === "Translator" &&
            vendTranslationLang === undefined
          ) {
            flag = false;
            Swal.fire({
              icon: "error",
              title: "Error !!!",
              text: `Vendor Translation Languages is required`,
            });
          } else if (
            val.value === "Subtitler" &&
            vendSubtitlingLang === undefined
          ) {
            flag = false;
            Swal.fire({
              icon: "error",
              title: "Error !!!",
              text: `Vendor Subtitling Languages is required`,
            });
          } else {
            flag = true;
          }
        });

        if (flag) {
          const url = `${process.env.REACT_APP_URL}/admin/users/addnewvendor`;
          const headers = reqHeader(true, true, true);
          const config = { headers };

          const body = {
            fname: data.fname,
            lname: data.lname,
            email: data.email,
            skypeID: data.skypeID,
            mobile: mobile,
            timings: data.timings,
            hrsPerWeek: data.hrsPerWeek,
            vendType: vendType === undefined ? [] : vendType,
            vendTranscriptionLang:
              vendTranscriptionLang === undefined ? [] : vendTranscriptionLang,
            vendTranslationLang:
              vendTranslationLang === undefined ? [] : vendTranslationLang,
            vendSubtitlingLang:
              vendSubtitlingLang === undefined ? [] : vendSubtitlingLang,
            prType: prType === undefined ? [] : prType,
            prTranscriptionLang:
              prTranscriptionLang === undefined ? [] : prTranscriptionLang,
            prTranslationLang:
              prTranslationLang === undefined ? [] : prTranslationLang,
            prSubtitlingLang:
              prSubtitlingLang === undefined ? [] : prSubtitlingLang,
            currency: currency.value,
            active: data.active,
            billingAddress: {
              firstName: data.fname,
              lastName: data.lname,
              invName: data.fname + " " + data.lname,
              GSTIN: data.gstNo,
              email: data.email,
              mobile: mobile,
              address1: data.address1,
              address2: data.address2,
              country: country.label,
              state: data.state,
              city: data.city,
              zipcode: data.zipcode,
            },
          };

          axios
            .post(url, body, config)
            .then((res) => {
              if (res.data.success === false && res.data.msg) {
                Swal.fire({
                  icon: "error",
                  title: "Error !!!",
                  text: `${res.data.msg}`,
                });
              } else {
                Swal.fire({
                  icon: "success",
                  title: "Vendor Created !!!",
                  text: `Vendor Created Successfully !!!`,
                }).then(function () {
                  dialog.close();
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              const { success, error } = err.response.data;

              if (success === false && error !== undefined) {
                Swal.fire({
                  icon: "error",
                  title: "Error !!!",
                  text: `${error.message && error ? error : err.message}`,
                });
              }
            });
        }
      } else {
        if (prType !== undefined && prType.length >= 1) {
          let flag = false;

          prType.forEach((val) => {
            if (
              val.value === "Transcription" &&
              prTranscriptionLang === undefined
            ) {
              flag = false;
              Swal.fire({
                icon: "error",
                title: "Error !!!",
                text: `Proof Reader Transcription Languages is required !!!`,
              });
            } else if (
              val.value === "Translation" &&
              prTranslationLang === undefined
            ) {
              flag = false;
              Swal.fire({
                icon: "error",
                title: "Error !!!",
                text: `Proof Reader Translation Languages is required !!!`,
              });
            } else if (
              val.value === "Subtitling" &&
              prSubtitlingLang === undefined
            ) {
              flag = false;
              Swal.fire({
                icon: "error",
                title: "Error !!!",
                text: `Proof Reader Subtitling Languages is required !!!`,
              });
            } else {
              flag = true;
            }
          });

          if (flag) {
            const url = `${process.env.REACT_APP_URL}/admin/users/addnewvendor`;
            const headers = reqHeader(true, true, true);
            const config = { headers };

            const body = {
              fname: data.fname,
              lname: data.lname,
              email: data.email,
              skypeID: data.skypeID,
              mobile: mobile,
              timings: data.timings,
              hrsPerWeek: data.hrsPerWeek,
              vendType: vendType === undefined ? [] : vendType,
              vendTranscriptionLang:
                vendTranscriptionLang === undefined
                  ? []
                  : vendTranscriptionLang,
              vendTranslationLang:
                vendTranslationLang === undefined ? [] : vendTranslationLang,
              vendSubtitlingLang:
                vendSubtitlingLang === undefined ? [] : vendSubtitlingLang,
              prType: prType === undefined ? [] : prType,
              prTranscriptionLang:
                prTranscriptionLang === undefined ? [] : prTranscriptionLang,
              prTranslationLang:
                prTranslationLang === undefined ? [] : prTranslationLang,
              prSubtitlingLang:
                prSubtitlingLang === undefined ? [] : prSubtitlingLang,
              currency: currency.value,
              active: data.active,
              billingAddress: {
                firstName: data.fname,
                lastName: data.lname,
                invName: data.fname + " " + data.lname,
                GSTIN: data.gstNo,
                email: data.email,
                mobile: mobile,
                address1: data.address1,
                address2: data.address2,
                country: country.label,
                state: data.state,
                city: data.city,
                zipcode: data.zipcode,
              },
            };

            axios
              .post(url, body, config)
              .then((res) => {
                if (res.data.success === false && res.data.msg) {
                  Swal.fire({
                    icon: "error",
                    title: "Error !!!",
                    text: `${res.data.msg}`,
                  });
                } else {
                  Swal.fire({
                    icon: "success",
                    title: "Vendor Created !!!",
                    text: `Vendor Created Successfully !!!`,
                  }).then(function () {
                    dialog.close();
                    window.location.reload();
                  });
                }
              })
              .catch((err) => {
                const { success, error } = err.response.data;

                if (success === false && error !== undefined) {
                  Swal.fire({
                    icon: "error",
                    title: "Error !!!",
                    text: `${error.message && error ? error : err.message}`,
                  });
                }
              });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `Atleast one Proof Reader Type is needed !!!`,
          });
        }
      }
    } else {
      if (
        data.fname === "" ||
        data.fname === undefined ||
        data.fname === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `First Name is required !!!`,
        });
      } else if (
        data.email === "" ||
        data.email === undefined ||
        data.email === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Email is required !!!`,
        });
      } else if (vendType.length <= 0 || vendType === undefined) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Vendor Type is required !!!`,
        });
      } else if (
        vendTranscriptionLang === "" ||
        vendTranscriptionLang === undefined ||
        vendTranscriptionLang === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Vendor Transcription Languages is required !!!`,
        });
      } else if (
        vendTranslationLang === "" ||
        vendTranslationLang === undefined ||
        vendTranslationLang === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Vendor Translation Languages is required !!!`,
        });
      } else if (
        vendSubtitlingLang === "" ||
        vendSubtitlingLang === undefined ||
        vendSubtitlingLang === null
      ) {
        Swal.fire({
          icon: "info",
          title: "Required !!!",
          text: `Vendor Subtitling Languages is required !!!`,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="custommerFormWrapper">
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">First Name</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              ref={register}
            />
            {errors.fname && (
              <text className="errorText">{errors.fname?.message}</text>
            )}
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Last Name</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              ref={register}
            />
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Email</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Email"
              name="email"
              ref={register}
            />
            {errors.email && (
              <text className="errorText">{errors.email?.message}</text>
            )}
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Skype ID</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Skype ID"
              name="skypeID"
              ref={register}
            />
            {errors.skypeID && (
              <text className="errorText">{errors.skypeID?.message}</text>
            )}
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Mobile No</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <PhoneInput
              international
              defaultCountry="IN"
              placeholder="Enter Mobile Number"
              value={formatPhoneNumberIntl(mobile)}
              onChange={(phone) => setMobile(phone)}
            />
            {formatPhoneNumberIntl(mobile) && !isValidPhoneNumber(mobile) && (
              <text className="errorText"> Invalid Mobile Number !!!</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Category</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <select name="categories" ref={register}>
              {categories.map((op, index) => (
                <option key={index} value={op.value}>
                  {op.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Vendor Type</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="vendType"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={vendType}
                  onChange={OnChangeVendType}
                  placeholder={"Vendor Type"}
                  isMulti={true}
                  options={vendtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Proof Reader Type</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="prType"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={prType}
                  onChange={OnChangePrType}
                  placeholder={"Reader Type"}
                  isMulti={true}
                  options={prtypes}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Transcription Languages</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="vendTranscriptionLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={vendTranscriptionLang}
                  onChange={OnChangeTranscriptionVendLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={vendlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Translation Languages</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="vendTranslationLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={vendTranslationLang}
                  onChange={OnChangeTranslationVendLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={vendlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Subtitling Languages*</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="vendSubtitlingLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={vendSubtitlingLang}
                  onChange={OnChangeSubtitlingVendLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={vendlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">PR Transcription Languages</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="prTranscriptionLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={prTranscriptionLang}
                  onChange={OnChangeTranscriptionPrLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={prlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">PR Translation Languages</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="prTranslationLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={prTranslationLang}
                  onChange={OnChangeTranslationPrLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={prlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">PR Subtitling Languages</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="prSubtitlingLang"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={prSubtitlingLang}
                  onChange={OnChangeSubtitlingPrLang}
                  placeholder={"Select Languages"}
                  isMulti={true}
                  options={prlanguages}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div style={{ paddingRight: "15px", paddingLeft: "15px" }}>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Address 1</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Address 1"
              name="address1"
              ref={register}
            />
            {errors.address1 && (
              <text className="errorText">{errors.address1?.message}</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Address 2</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Address 2"
              name="address2"
              ref={register}
            />
            {errors.address1 && (
              <text className="errorText">{errors.address2?.message}</text>
            )}
          </div>
        </div>
      </div>

      <div className="custommerFormWrapper">
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Currency</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="currencies"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={currency}
                  onChange={OnChangeCurrency}
                  placeholder={"Select Currency"}
                  options={currencies}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Country</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <Controller
              control={methods.control}
              name="countries"
              defaultValue={{}}
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  name={name}
                  value={country}
                  onChange={OnChangeCountries}
                  placeholder={"Select Country"}
                  options={countries}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">State</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="State"
              name="state"
              ref={register}
            />
            {errors.state && (
              <text className="errorText">{errors.state?.message}</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">City</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input type="text" placeholder="City" name="city" ref={register} />
            {errors.city && (
              <text className="errorText">{errors.city?.message}</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Postal Code</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Postal Code"
              name="zipcode"
              ref={register}
            />
            {errors.zipcode && (
              <text className="errorText">{errors.zipcode?.message}</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">GST No.</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="GST No"
              name="gstNo"
              ref={register}
            />
            {errors.gstNo && (
              <text className="errorText">{errors.gstNo?.message}</text>
            )}
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Timings</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <select name="timings" ref={register}>
              {timings.map((op, index) => (
                <option key={index} value={op.value}>
                  {op.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="textFieldContainer">
          <div className="titieWrapper">
            <span className="title">Hrs Per Week</span>
            <span className="requiredField">*</span>
          </div>
          <div className="inputContainer ">
            <input
              type="text"
              placeholder="Hrs Per Week"
              name="gstNo"
              ref={register}
            />
            {errors.hrsPerWeek && (
              <text className="errorText">{errors.hrsPerWeek?.message}</text>
            )}
          </div>
        </div>
      </div>
      <div className="bottomButtonSection">
        <div className="checkBoxContainer">
          <input
            type="checkbox"
            placeholder="Active"
            name="active"
            // defaultChecked={active}
            // onChange={() => setActive(!active)}
            ref={register}
            className="checkboxStyle"
          />
          <label>Active</label>
        </div>

        <button className="orangeBtn btn">Submit</button>
      </div>
    </form>
  );
};

export default NewVendor;
