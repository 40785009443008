//Packages
import React from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

//Helper Files
import reqHeader from "../../../helper/reqHeader.js";

//Styles
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required!")
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!"),
  language: yup
    .string()
    .required("Language is required!")
    .matches(/^([^0-9]*)$/, "Language should not contain numbers!"),
});

toast.configure();

const NewDialect = (props) => {
  const { langID, langName } = props;

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      language: langName,
      active: true,
    },
  });

  const { register, handleSubmit, errors } = methods;

  // use this hook to control the dialog
  const dialog = useDialog();

  const submitHandler = (data, e) => {
    e.preventDefault();

    if (
      data.name !== "" &&
      data.name !== undefined &&
      data.name !== null &&
      data.language !== "" &&
      data.language !== undefined &&
      data.language !== null
    ) {
      const url = `${process.env.REACT_APP_URL}/admin/language/adddialect`;
      const headers = reqHeader(true, true, true);
      const body = {
        name: data.name.trim(),
        language: data.language.trim(),
        langID: langID,
        active: data.active,
      };

      const config = { headers };

      axios
        .put(url, body, config)
        .then((res) => {
          const { success, msg } = res.data;

          if (success === true) {
            toast.success(`${msg}`);
            setTimeout(() => {
              dialog.close(true);
              // window.location.reload();
            }, 2000);
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          toast.error(
            `${error === undefined ? err.response.statusText : error}`
          );
        });
    } else {
      if (data.name === "" || data.name === undefined || data.name === null) {
        toast.error("Name is required !!!");
      }
      if (
        data.language === "" ||
        data.language === undefined ||
        data.language === null
      ) {
        toast.error("Language is required !!!");
      }
    }
  };

  return (
    <>
      <form className="form d-flex" onSubmit={handleSubmit(submitHandler)}>
        <div className="contentLeft">
          <div className="block">
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <label className="label">
                    Name<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register}
                  />
                  {errors.name && (
                    <span className="inputErrorMsg">
                      {errors.name?.message}
                    </span>
                  )}
                </div>

                <div className={`inputWrap ${errors.language ? "error" : ""}`}>
                  <label className="label">
                    Language<span className="requiredField">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Language"
                    name="language"
                    ref={register}
                    readOnly={true}
                  />
                  {errors.language && (
                    <span className="inputErrorMsg">
                      {errors.language?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">Active</label>
                  <input
                    type="checkbox"
                    placeholder="Active"
                    name="active"
                    defaultChecked={true}
                    style={{ height: "15px" }}
                    ref={register}
                  />
                </div>
              </div>

              <br />
              <button className="orangeBtn btn">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewDialect;
