//Packages
import React, { useState, useEffect } from "react";
import { CustomDialog } from "react-st-modal";
import Split from "react-split";
import SplitPane from "react-split-pane";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

//Custom Components
import LeftMenuList from "../LeftMenu/LeftMenuList.jsx";
import UserProfile from "../Profile/userProfile.jsx";
import NewProject from "../Entities/Projects/NewProject.jsx";

import CustProjectList from "./CustProjectList.jsx";
import LangDomainList from "./LangDomainList.jsx";
import FileList from "./FileList.jsx";

//Styles
import "../../../src/styles.css";
import "react-tabs/style/react-tabs.scss";

const Delivery = () => {
  const activeNodes = ["002", "002_004"];

  const [customer, setCustomer] = useState({});
  const [clickedLangDomain, setClickedLangDomain] = useState({});

  useEffect(() => {
    setClickedLangDomain({});
  }, [customer]);

  const handleProject = async (props) => {
    await CustomDialog(<NewProject serviceType="Transcription" />, {
      title: "Transcription Project",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  return (
    <>
      <div className="container projectContainer d-flex">
        <LeftMenuList activeKey="002/002_004" activeNodes={activeNodes} />

        <div className="pageRight">
          <div className="userSection">
            <ul className="breadcrumb">
              <li>Services</li>
              <li>Transcriptions</li>
              <li>Delivery</li>
            </ul>
            <UserProfile />
          </div>

          <div className="d-flex breadcrumWrap">
            <div></div>

            <button className="orangeBtn btn" onClick={handleProject}>
              Add Project
            </button>
          </div>

          <Tabs forceRenderTabPanel defaultIndex={0} defaultFocus={true}>
            <TabList>
              <Tab key={0}>Delivered</Tab>
              <Tab key={1}>Pending</Tab>
            </TabList>
            <TabPanel key={0}>
              {/* <SplitPane split="vertical" minSize={30}>
                <SplitPane split="horizontal"> */}
                  {/* <div>
                    <h3>Panel 1 - Customer Project Pending Tab</h3>
                  </div>
                  <div>
                    <h3>Panel 2 - Language Domain Pending Tab</h3>
                  </div> */}
                  <Split className="split" sizes={[50, 50]}>
                    <CustProjectList
                      serviceType="Transcription"
                      type="delivery"
                      totalDuration={true}
                      uploadedDuration={false}
                      handledBy={false}
                      status={false}
                      progress={false}
                      setCustomer={setCustomer}
                    />
                    <LangDomainList
                      serviceType="Transcription"
                      type="delivery"
                      totalDuration={true}
                      langDomainDuration={true}
                      status={false}
                      progress={false}
                      customer={customer}
                      setClickedLangDomain={setClickedLangDomain}
                    />
                  </Split>
                {/* </SplitPane> */}
                <div style={{ height: 600 }}>
                  {/* <h3>Panel 3 - Files Pending Tab</h3> */}
                  <FileList
                    serviceType="Transcription"
                    type="delivery"
                    multiSelect={false}
                    customer={customer}
                    progress={false}
                    preview={false}
                    clickedLangDomain={clickedLangDomain}
                  />
                </div>
              {/* </SplitPane> */}
            </TabPanel>
            <TabPanel key={1}>
              <SplitPane split="vertical" minSize={30}>
                <SplitPane split="horizontal">
                  <h3>Panel 1 - Customer Project Pending Tab</h3>
                  <h3>Panel 2 - Language Domain Pending Tab</h3>
                </SplitPane>
                <div style={{ height: 600 }}>
                  <h3>Panel 3 - Files Pending Tab</h3>
                </div>
              </SplitPane>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Delivery;
