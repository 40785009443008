//Packages
import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Swal from "sweetalert2";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

const PrjtClosure = (props) => {
  const {
    selectedRow: {
      _id,
      type,
      ETA,
      stage,
      serviceType,
      customerID,
      custName,
      custType,
      projectID,
      projectName,
      transcription,
      translation,
      subtitling,
      proofReading,
      language,
      fileLength,
    },
  } = props;

  // use this hook to control the dialog
  const dialog = useDialog();

  console.log("props prjtclosure", _id)



  // useEffect(() => {
  //   async function getUserDetails() {
  //     if (
  //       assignedTo !== "" &&
  //       assignedTo !== undefined &&
  //       assignedTo !== null
  //     ) {
  //       let id = assignedTo.label;

  //       const url = `${process.env.REACT_APP_URL}/admin/users/${id}`;
  //       const headers = reqHeader(true, true, true);
  //       const config = { headers };

  //       await axios
  //         .get(url, config)
  //         .then((res) => {
  //           const { fname, lname, email } = res.data.data;

  //           setUser({ id: id, name: fname + " " + lname, email: email });
  //         })
  //         .catch((err) => {});
  //     }
  //   }

  //   async function getRateDetails() {
  //     if (
  //       assignedTo !== "" &&
  //       assignedTo !== undefined &&
  //       assignedTo !== null
  //     ) {
  //       let id = assignedTo.label;

  //       const url = `${process.env.REACT_APP_URL}/admin/vendorrates/getvendorratesfile`;
  //       const headers = reqHeader(true, true, true);
  //       const params = {
  //         id: id,
  //         customerID: customerID,
  //         custName: custName,
  //         custType: custType,
  //         projectID: projectID,
  //         projectName: projectName,
  //         language: language,
  //         serviceType: serviceType,
  //         stage: stage,
  //       };
  //       const config = { headers, params };

  //       await axios
  //         .get(url, config)
  //         .then((res) => {
  //           const { data } = res.data;

  //           if (data === undefined || data === null) {
  //             Swal.fire({
  //               icon: "info",
  //               title: "Vendor Assign Missing !!!",
  //               text: `Rate is not set for this vendor !!!`,
  //             });

  //             setRate({
  //               rateID: "",
  //               vendorID: "",
  //               language: "",
  //               currency: "",
  //               rates: Number(0).toFixed(3),
  //               amounts: Number(0).toFixed(3),
  //               rate: "INR " + Number(0).toFixed(3),
  //               amount: "INR " + Number(0).toFixed(3),
  //             });
  //           } else {
  //             let amount = 0;

  //             if (
  //               data.type === "Transcriber" ||
  //               data.type === "Subtitler" ||
  //               data.type === "PR Transcriber" ||
  //               data.type === "PR Subtitler"
  //             ) {
  //               const times = fileLength.split(":");

  //               const hhRate = Number(times[0]) * 60 * data.rate;
  //               const mmRate = Number(times[1]) * data.rate;
  //               const ssRate = (Number(times[2]) / 60) * data.rate;
  //               amount = hhRate + mmRate + ssRate;
  //             } else {
  //               amount = data.rate * fileLength;
  //             }

  //             setRate({
  //               rateID: data._id,
  //               vendorID: data.vendorID,
  //               language: language,
  //               currency: data.currency,
  //               rates: data.rate.toFixed(3),
  //               amounts: amount.toFixed(3),
  //               rate: data.currency + " " + data.rate.toFixed(3),
  //               amount: data.currency + " " + amount.toFixed(3),
  //             });
  //           }
  //         })
  //         .catch((err) => {});
  //     }
  //   }

  //   getUserDetails();

  //   if (type === "child" && loaded === false) {
  //     getRateDetails();

  //     setLoaded(true);
  //   }
  // }, [
  //   assignedTo,
  //   type,
  //   custName,
  //   custType,
  //   customerID,
  //   fileLength,
  //   language,
  //   loaded,
  //   projectID,
  //   projectName,
  //   rate,
  //   serviceType,
  //   stage,
  // ]);


    const handleGetOTP = async () => {
      console.log("projectID", _id)
      const url = `${process.env.REACT_APP_URL}/admin/transcription/sendotpverificationemail`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      const body = {
        _id: _id
      };
      console.log("body", body)
      axios
        .put(url, body, config)
        .then((res) => {
          console.log("res", res)
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "OTP sent !!!",
              text: `${msg}`,
            })
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error}`,
          });
        });
    }

    let textInput = React.createRef();
    const handleClosure = (e) => {
      //console.log(textInput.current.value); 
      const otp = textInput.current.value;
      const url = `${process.env.REACT_APP_URL}/admin/transcription/projectClosure`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      const body = {
        _id: _id,
        otp: otp
      };
      console.log("body", body)
      axios
        .put(url, body, config)
        .then((res) => {
          console.log("res", res)
          const { success, msg } = res.data;

          if (success === true) {
            Swal.fire({
              icon: "success",
              title: "PROJECT CLOSED SUCCESSFULLY !!!",
              text: `${msg}`,
            }).then(()=>{
              window.location.reload();
            })
          }
        })
        .catch((err) => {
          const { success, error, errorList } = err.response.data;

          Swal.fire({
            icon: "error",
            title: "Error !!!",
            text: `${error}`,
          });
        });
    }


  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label"><strong>Do you want to close this project?</strong></label>
                <input
                  type="text"
                  placeholder="OTP"
                  name="otp"
                  ref={textInput}
                  //value={user.name}
                />
              </div>           
            </div>

            <div className="footerAction">
              <button className="orangeBtn btn" onClick={() => handleGetOTP()}>
                  Get OTP
              </button> &nbsp;
              <button className="orangeBtn btn" onClick={(e) => handleClosure()}>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrjtClosure;
