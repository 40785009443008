//Packages
import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { format } from "date-fns";
import { CustomDialog } from "react-st-modal";
import { CSVLink } from "react-csv";
import moment from "moment";

//Custom Components
import Table from "../Table/Table";
import ProgressBar from "../Utils/ProgressBar.jsx";
import FileAssignBatch from "./FileAssignBatch.jsx";
import FileCancelBatch from "./FileCancelBatch.jsx";
import FileClosureBatch from "./FileClosureBatch.jsx";
//import AudioPlayers from "../Downloads/AudioPlayers.jsx";


//Helper Files
import reqHeader from "../../helper/reqHeader.js";
import DownloadFiles from "../Downloads/DownloadFiles";
import { DownloadIcon } from "../../svgIconComponent/svg_icon_components";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const FileList = (props) => {
  const {
    type,
    clickedSrcTgtLang,
    setClickedSrcTgtLang,
    langDomainDuration,
    customer,
    progress,
    download,
    multiSelect,
    preview,
  } = props;

  console.log("type..",type);
  console.log("clickedSrcTgtLang", clickedSrcTgtLang);

  const [data, setData] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [TRAssignedCount, setTRAssignedCount] = useState(0);
  const [PRAssignedCount, setPRAssignedCount] = useState(0);
  const [TRInProgressCount, setTRInProgressCount] = useState(0);
  const [PRInProgressCount, setPRInProgressCount] = useState(0);
  const [TRCompletedCount, setTRCompletedCount] = useState(0);
  const [PRCompletedCount, setPRCompletedCount] = useState(0)

  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [isAssigned, setIsAssigned] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isClosure, setIsClosure] = useState(false);

  const [openDuration, setOpenDuration] = useState(0)
  const [TRAssignedDuration, setTRAssignedDuration] = useState(0);
  const [PRAssignedDuration, setPRAssignedDuration] = useState(0);
  const [TRInProgressDuration, setTRInProgressDuration] = useState(0);
  const [PRInProgressDuration, setPRInProgressDuration] = useState(0);
  const [TRCompletedDuration, setTRCompletedDuration] = useState(0);
  const [PRCompletedDuration, setPRCompletedDuration] = useState(0);

  const checkBoxCols = multiSelect
    ? {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        // Header: ({ getToggleAllRowsSelectedProps }) => (
        //   <div>
        //     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        //   </div>
        // ),
        // // The cell can use the individual row's getToggleRowSelectedProps method
        // // to the render a checkbox
        // Cell: ({ row }) => (
        //   <div>
        //     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //   </div>
        // ),
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      }
    : {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      };

  let columns = useMemo(
    () => [
      checkBoxCols,
      {
        Header: "Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        style: {
          fontWeight: "bolder",
        },
        width: 500,
        Cell: ({ value }) => {
          return <span title={value}>{value}</span>;
        },
      },
      // {
      //   Header: "CreatedOn",
      //   accessor: "CreatedOn",
      //   sortType: "basic",
      //   filter: "text",
      //   style: {},
      //   width: 100,
      //   Cell: ({ value,row }) => {
      //     //console.log("assigned on", value)
      //     return(
      //       <span>{format(new Date(row.original.CreatedOn), "dd/MM/yyyy")}</span>
      //     )
      //   },
      // },
      // {
      //   Header: "ETA",
      //   accessor: "ETA",
      //   sortType: "basic",
      //   filter: "text",
      //   style: {},
      //   width: 100,
      //   Cell: ({ value,row }) => {
      //     //console.log("assigned on", value)
      //     return(
      //       <span>{format(new Date(row.original.ETA), "dd/MM/yyyy")}</span>
      //     )
      //   },
      // },
      {
        Header: "Word Count",
        accessor: "fileWordCount",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value }) => {
          return <span title={value}>{value}</span>;
        },
      },
      {
        Header: "Handled By",
        accessor: "handledBy",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value }) => {
          return <span title={value}>{value}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "stage",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value, row }) => { 
          // console.log("value status", row);
          return (row.original.translationCancelled == true || row.original.proofreadingCancelled == true || row.original.stage == "Proof Reader Rejected") ? <span style={{color:"red"}} title={value}>{value}</span> : <span title={value}>{value}</span>
        },
      },
      {
        Header: "Type",
        accessor: "custType",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value, row }) => { 
          // console.log("value status", row);
          return (row.original.custType == "OC" ?<span>RetailCustomer</span>:<span>Enterprise Customer</span>)
        },
      },
      // {
      //   Header: "Completed (%)",
      //   accessor: "percentageOfCompletion",
      //   sortType: "basic",
      //   filter: "text",
      //   width: 150,
      //   hidden: !progress,
      //   Cell: ({ value, row }) => {
      //     return (
      //       <ProgressBar completed={row.original.percentageOfCompletion} />
      //     );
      //   },
      // },
      // {
      //   Header: "Preview",
      //   accessor: "preview",
      //   hidden: !preview,
      //   Cell: ({ row, value }) => {
      //     return (
      //       <button
      //         className="previewBtn"
      //         onClick={() => handleFilePreview(row.original, value)}
      //       >
      //         Preview
      //       </button>
      //     );
      //   },
      // },
      {
        Header: "",
        accessor: "download",
        hidden: !download,
        Cell: ({ row, value }) => {
          return (
            <button
              className="downloadBtn"
              onClick={() => handleDownload(row.original, value)}
            >
              <DownloadIcon />
              &nbsp; Download
            </button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const getFiles = async () => {
      //console.log("Inside translationprojectfileList");
      const url = `${process.env.REACT_APP_URL}/admin/translation/translationprojectfileList`;
      const headers = reqHeader(true, true, true);
      let srctgtlangs=[];
      if(clickedSrcTgtLang.srctgtlang)
      {
        srctgtlangs= clickedSrcTgtLang.srctgtlang.split("_");
      }
    
      const params = {
        customerID: customer.customerID,
        projectID:customer._id,
        projectName: customer.projectName,
        srcLang: srctgtlangs&&srctgtlangs[0],
        tgtLang: srctgtlangs&&srctgtlangs[1],
      };
      //console.log("params..",params)
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          console.log("data..",data);
          // debugger;
          let newdataarray=[];
          let newData = data.map((value) => {
            
            const {
              stage,
              translation,
              proofReading,
            } = value;
           
            let proofReader = proofReading.map((value) => value.name);

            let translator = translation.map((value) =>
              value.status !== "Cancelled" ? value.name : ""
            );

            //let assignedOnDate = transcription.map((value) => value.assignedOn);
            let TranslationReason = translation.map((value)=> value.docStatus === "Rejected" ? value.rejectReason : "");
            let TranslationCancelReason = translation.map((value)=> value.docStatus === "Cancelled" ? value.cancelReason : ""); 
            let ProofreadingReason = proofReading.map((value)=> value.docStatus === "Rejected Reassign" ? value.proofreadComment : value.docStatus === "Rejected" ? value.rejectReason : "" );
            let ProofreadingCancelReason = proofReading.map((value)=> value.docStatus === "Cancelled" ? value.cancelReason : "");
            let TranslationHandledBy = translation.map((value)=>value.email);
            let ProofreadingHandledBy = proofReading.map((value)=>value.email);

            console.log("TranslationHandledBy", TranslationHandledBy)
            

            let TLCancelled = (stage === "Open" && translation.length > 0) ? true : false;
            //console.log("TRCancelled", TRCancelled);

            let PRCancelled = (stage === "Translation Completed" && proofReading.length > 0) ? true : false;
            //console.log("PRCancelled", PRCancelled);

            let reason = "";
            if(TLCancelled === true && stage === "Open"){
               reason = TranslationReason[translation.length - 1] != "" ? TranslationReason[translation.length - 1] :  TranslationCancelReason[translation.length - 1] != "" ?TranslationCancelReason[translation.length - 1] : "";
            }

            if(PRCancelled === true && stage === "Translation Completed"){
              reason = ProofreadingReason[proofReading.length - 1] != "" ? ProofreadingReason[proofReading.length - 1] :  ProofreadingCancelReason[proofReading.length - 1] != "" ?ProofreadingCancelReason[proofReading.length - 1] : "";
            }

            //Completed On Date
            let completedOnDate = ""

            if(stage === "Translation Completed"){
              completedOnDate = format(
                new Date(translation[translation.length - 1].completedOn),
                "dd/MM/yyyy"
              )
            }else if(stage === "Proof Reading Completed"){
              completedOnDate = format(
                new Date(proofReading[proofReading.length - 1].completedOn),
                "dd/MM/yyyy"
              )
            }else{
              completedOnDate = ""
            }


  
            //console.log("completedOnDate", completedOnDate)

            let percComplete = 0;
            let handledBy = "",
              handledByEmail = "", assignedOn="";
            if (translation.length>0 && 
              (stage === "Translation Assigned" ||
              stage === "Translation" ||
              stage === "Translation Completed")
            ) {
              percComplete =
              translation[translation.length - 1].percentageOfCompletion;
              handledBy = translation[translation.length - 1].name;
              handledByEmail = translation[translation.length - 1].email;
              assignedOn=translation[translation.length - 1].assignedOn;
              
            } else if (proofReading.length>0 && 
              (stage === "Proof Reading Assigned" ||
              stage === "Proof Reading" ||
              stage === "Proof Reading Completed" || stage === "Proof Reader Rejected")
            ) {
              percComplete =
              proofReading[proofReading.length - 1].percentageOfCompletion;
              handledBy = proofReading[proofReading.length - 1].name;
              handledByEmail = proofReading[proofReading.length - 1].email;
              assignedOn=proofReading[proofReading.length - 1].assignedOn;
            } else {
              percComplete = 0;
              handledBy = "";
              handledByEmail = "";
              assignedOn="";
            }
           
            console.log("Inside map",value);
            console.log("handledByEmail",handledByEmail);
            
            return {
              type: "child",
              serviceType: "Translation",
              fileName:value.fileName,
              srcLang: value.srcLang,
              tgtLang: value.tgtLang,
              customerID: value.customerID,
              custName: value.custName,
              custType: value.custType,
              handledBy:handledByEmail,
              ETA:value.ETA,
              id:value._id,
              projectID: value.projectID,
              projectName: value.projectName,
              fileWordCount:value.fileWordCount,
              status:value.status,
              stage:value.stage,
              rate:value.rate,
              translation: value.translation,
              proofReading: value.proofReading,
            };
            //console.log("newobj",newobj);
            //newdataarray=[...newdataarray,newobj];
          });
          console.log("newData..",newData);
          setData(newData);
        })
        .catch((err) => {
          setData([]);
        });
    };

    if (clickedSrcTgtLang && clickedSrcTgtLang.length !== 0) {
      //console.log("Inside clickedSrcTgtLang");
      getFiles();
    } else {
      setData([]);
    }
  }, [clickedSrcTgtLang]);

  //console.log("clickedSrcTgtLang",clickedSrcTgtLang);
  //console.log("data...",data);

  const refreshFiles = async () => {
    const url = `${process.env.REACT_APP_URL}/admin/translation/translationprojectfileList`;
    const headers = reqHeader(true, true, true);
    let srctgtlangs=[];
      if(clickedSrcTgtLang.srctgtlang)
      {
        srctgtlangs= clickedSrcTgtLang.srctgtlang.split("_");
      }
    
      const params = {
        customerID: customer.customerID,
        projectID:customer._id,
        projectName: customer.projectName,
        srcLang: srctgtlangs&&srctgtlangs[0],
        tgtLang: srctgtlangs&&srctgtlangs[1],
      };
    const config = { headers, params };

    await axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        let newdataarray=[];
        let newData = data.map((value) => {
          const {
            stage,
            translation,
            proofReading,
          } = value;

          let proofReader = proofReading.map((value) => value.name);

            let translator = translation.map((value) =>
              value.status !== "Cancelled" ? value.name : ""
            );

            //let assignedOnDate = transcription.map((value) => value.assignedOn);
            let TranslationReason = translation.map((value)=> value.docStatus === "Rejected" ? value.rejectReason : "");
            let TranslationCancelReason = translation.map((value)=> value.docStatus === "Cancelled" ? value.cancelReason : ""); 
            let ProofreadingReason = proofReading.map((value)=> value.docStatus === "Rejected Reassign" ? value.proofreadComment : value.docStatus === "Rejected" ? value.rejectReason : "" );
            let ProofreadingCancelReason = proofReading.map((value)=> value.docStatus === "Cancelled" ? value.cancelReason : "");
            let TranslationHandledBy = translation.map((value)=>value.email);
            let ProofreadingHandledBy = proofReading.map((value)=>value.email);

            //console.log("TranscriptionHandledBy", TranscriptionHandledBy)
            

            let TLCancelled = (stage === "Open" && translation.length > 0) ? true : false;
            //console.log("TRCancelled", TRCancelled);

            let PRCancelled = (stage === "Translation Completed" && proofReading.length > 0) ? true : false;
            //console.log("PRCancelled", PRCancelled);

            let reason = "";
            if(TLCancelled === true && stage === "Open"){
               reason = TranslationReason[translation.length - 1] != "" ? TranslationReason[translation.length - 1] :  TranslationCancelReason[translation.length - 1] != "" ?TranslationCancelReason[translation.length - 1] : "";
            }

            if(PRCancelled === true && stage === "Translation Completed"){
              reason = ProofreadingReason[proofReading.length - 1] != "" ? ProofreadingReason[proofReading.length - 1] :  ProofreadingCancelReason[proofReading.length - 1] != "" ?ProofreadingCancelReason[proofReading.length - 1] : "";
            }

            //Completed On Date
            let completedOnDate = ""

            if(stage === "Translation Completed"){
              completedOnDate = format(
                new Date(translation[translation.length - 1].completedOn),
                "dd/MM/yyyy"
              )
            }else if(stage === "Proof Reading Completed"){
              completedOnDate = format(
                new Date(proofReading[proofReading.length - 1].completedOn),
                "dd/MM/yyyy"
              )
            }else{
              completedOnDate = ""
            }


  
            //console.log("completedOnDate", completedOnDate)

            let percComplete = 0;
            let handledBy = "",
              handledByEmail = "", assignedOn="";
            if (translation.length>0 && 
              (stage === "Translation Assigned" ||
              stage === "Translation" ||
              stage === "Translation Completed")
            ) {
              percComplete =
              translation[translation.length - 1].percentageOfCompletion;
              handledBy = translation[translation.length - 1].name;
              handledByEmail = translation[translation.length - 1].email;
              assignedOn=translation[translation.length - 1].assignedOn;
            } else if (proofReading.length>0 && 
              (stage === "Proof Reading Assigned" ||
              stage === "Proof Reading" ||
              stage === "Proof Reading Completed" || stage === "Proof Reader Rejected")
            ) {
              percComplete =
              proofReading[proofReading.length - 1].percentageOfCompletion;
              handledBy = proofReading[proofReading.length - 1].name;
              handledByEmail = proofReading[proofReading.length - 1].email;
              assignedOn=proofReading[proofReading.length - 1].assignedOn;
            } else {
              percComplete = 0;
              handledBy = "";
              handledByEmail = "";
              assignedOn="";
            }


            let newobj= {
              type: "child",
              serviceType: "Translation",
              id: value._id,
              fileName: value.fileName,
              fileWordCount: value.fileWordCount,
              ETA: value.ETA,
              stage:
                value.stage === "Translation"
                  ? "Translation Inprogress"
                  : value.stage === "Proof Reading"
                  ? "Proof Reading Inprogress"
                  : value.stage,
              status: value.status,
              fileLocation: value.fileLocation,
              //transcription: transcription,
              translation: translation,
              //subtitling: subtitling,
              proofReading: proofReading,
              // transcriberName:
              //   value.stage === "Paid" || value.stage === "Open"
              //     ? ""
              //     : transcriber[transcription.length - 1],
              translatorName: value.stage === "Paid" || value.stage === "Open"
              ? ""
              : translator[translation.length - 1],
              //subtitlerName: "",
              proofReaderName:
                value.stage === "Translation Completed"
                  ? ""
                  : proofReader[proofReading.length - 1],
              assignedPM: "", //assignedPM is needed
              //language: value.tgtLang,
              srcLang: value.srcLang,
              tgtLang: value.tgtLang,
              customerID: value.customerID,
              custName: value.custName,
              custType: value.custType,
              projectID: value.projectID,
              projectName: value.projectName,
              percentageOfCompletion: percComplete,
              //completedDuration: value.completedDuration,
              handledBy: handledBy,
              handledByEmail: handledByEmail,
              targetFileLocation: value.targetFileLocation,
              targetFileName: value.targetFileName,
              translationCancelled: TLCancelled,
              proofreadingCancelled: PRCancelled,
              AssignedOn: assignedOn!=""?format(new Date(assignedOn), "dd/MM/yyyy"):"",
              // AssignedOn: assignedOnDate[transcription.length - 1] !== undefined ? format(
              //   new Date(assignedOnDate[transcription.length - 1]),
              //   "dd/MM/yyyy"
              // ) : "",
              translationReject: TranslationReason[translation.length - 1],
              transcriptionCancel: TranslationCancelReason[translation.length - 1],
              proofreadReject: ProofreadingReason[proofReading.length - 1],
              proofreadCancel: ProofreadingCancelReason[proofReading.length - 1],
              TranslationHandledBy: TranslationHandledBy[translation.length - 1],
              ProofreadingHandledBy: ProofreadingHandledBy[proofReading.length - 1],
              createdOn: format(
                new Date(value.createdOn),
                "dd/MM/yyyy"
              ),
              completedOnDate: completedOnDate,
              reason: reason
            };
          return {
            type: "child",
            serviceType: "Translation",
            fileName:value.fileName,
            srcLang: value.srcLang,
            tgtLang: value.tgtLang,
            customerID: value.customerID,
            custName: value.custName,
            custType: value.custType,
            handledBy:handledByEmail,
            ETA:value.ETA,
            id:value._id,
            projectID: value.projectID,
            projectName: value.projectName,
            fileWordCount:value.fileWordCount,
            status:value.status,
            stage:value.stage,
            rate:value.rate,
            translation: value.translation,
            proofReading: value.proofReading,
          };
          });
        //console.log("new data", newData)
        setData(newData);
      })
      .catch((err) => {
        setData([]);
      });
  };

  // useEffect(() => {
  //   if (data.length !== undefined && data.length !== null) {
  //     console.log("Inside data.length");
  //     let open = data.filter((value) => value.stage === "Open");
      
  //     let TRAssigned = data.filter((value)=> value.stage === "Translation Assigned");
  //     let PRAssigned = data.filter((value) => value.stage === "Proof Reading Assigned");

  //     let TRInProgress = data.filter((value)=> value.stage === "Translation Inprogress")
  //     let PRInprogress = data.filter((value) => value.stage === "Proof Reading Inprogress");

  //     let TRCompleted = data.filter((value)=> value.stage === "Translation Completed");
  //     let PRCompleted = data.filter((value)=> value.stage === "Proof Reading Completed" || value.stage === "Proof Reader Rejected");

  //     setOpenCount(open.length);
  //     setTRAssignedCount(TRAssigned.length)
  //     setPRAssignedCount(PRAssigned.length)
  //     setTRInProgressCount(TRInProgress.length)
  //     setPRInProgressCount(PRInprogress.length)
  //     setTRCompletedCount(TRCompleted.length)
  //     setPRCompletedCount(PRCompleted.length)

      
  //     if(open){
  //       let totalFileDuration = []
  //       open.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setOpenDuration(sum)

  //     }
      
  //     if(TRAssigned){
  //       let totalFileDuration = []
  //       TRAssigned.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setTRAssignedDuration(sum)
  //     }
  //     if(PRAssigned){
  //       let totalFileDuration = []
  //       PRAssigned.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setPRAssignedDuration(sum)
  //     }
      
  //     if(TRInProgress){
  //       let totalFileDuration = []
  //       TRInProgress.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setTRInProgressDuration(sum)
  //     }

  //     if(PRInprogress){
  //       let totalFileDuration = []
  //       PRInprogress.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setPRInProgressDuration(sum)
  //     }
      
  //     if(TRCompleted){
  //       let totalFileDuration = []
  //       TRCompleted.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setTRCompletedDuration(sum)
  //     }

  //     if(PRCompleted){
  //       let totalFileDuration = []
  //       PRCompleted.map((value)=>totalFileDuration.push(value.fileDuration))

  //       const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //       let sum = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  //       setPRCompletedDuration(sum)
  //     }
  //   }

  // }, [data]);

  //Total file duration and File count

  // let totalFileDuration = [];
  // totalFileDuration.push(openDuration);
  // totalFileDuration.push(TRAssignedDuration);
  // totalFileDuration.push(PRAssignedDuration);
  // totalFileDuration.push(TRInProgressDuration);
  // totalFileDuration.push(PRInProgressDuration);
  // totalFileDuration.push(TRCompletedDuration);
  // totalFileDuration.push(PRCompletedDuration);

  // const totalFileDurationSum = totalFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  // let totalDuration = [Math.floor(totalFileDurationSum.asHours()), totalFileDurationSum.minutes(), totalFileDurationSum.seconds()].join(':');
  // let totalFileCount = openCount + TRAssignedCount + PRAssignedCount + TRInProgressCount + PRInProgressCount + TRCompletedCount + PRCompletedCount;
  

  // //Total Assigned File Duration and File Count

  // let totalAssignedFileDuration = [];
  // totalAssignedFileDuration.push(TRAssignedDuration);
  // totalAssignedFileDuration.push(PRAssignedDuration);
  // totalAssignedFileDuration.push(TRInProgressDuration);
  // totalAssignedFileDuration.push(PRInProgressDuration);
  // totalAssignedFileDuration.push(TRCompletedDuration);
  // totalAssignedFileDuration.push(PRCompletedDuration);

  // let totalAssignedFileDurationSum = totalAssignedFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  // let totalAssignedDuration = [Math.floor(totalAssignedFileDurationSum.asHours()), totalAssignedFileDurationSum.minutes(), totalAssignedFileDurationSum.seconds()].join(':');
  // let totalAssignedFileCount = TRAssignedCount + PRAssignedCount + TRInProgressCount + PRInProgressCount + TRCompletedCount + PRCompletedCount;

  // //Total Completed File Duration and File Count
  // let totalCompletedFileDuration = []
  // totalCompletedFileDuration.push(TRCompletedDuration);
  // totalCompletedFileDuration.push(PRAssignedDuration);
  // totalCompletedFileDuration.push(PRInProgressDuration);
  // totalCompletedFileDuration.push(PRCompletedDuration);

  // let totalCompletedFileDurationsum = totalCompletedFileDuration.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  // let totalCompletedDuration = [Math.floor(totalCompletedFileDurationsum.asHours()), totalCompletedFileDurationsum.minutes(), totalCompletedFileDurationsum.seconds()].join(':');

  // let totalCompletedFileCount = TRCompletedCount + PRAssignedCount + PRInProgressCount + PRCompletedCount;




  useEffect(() => {
    if (selectedRowsValues.length > 0) {
      let rows = selectedRowsValues.map((value, index) => {
        return value.original;
      });

      let selectedStages = rows.map((value) => value.stage);
      console.log("selectedStages",selectedStages);

      let uniqueStage = [...new Set(selectedStages)];
      console.log("uniqueStage..",uniqueStage);

      if (uniqueStage.length === 1) {
        if (
          uniqueStage.toString() === "Translation" ||
          uniqueStage.toString() === "Translation Inprogress"
          ||
          uniqueStage.toString() === "Proofreading Inprogress" || 
          uniqueStage.toString() === "Proof Reading" || uniqueStage.toString() === "Proof Reading Inprogress"
        ) {
          setIsAssigned(false);
          setIsCancelled(true);
          setIsClosure(false);
        } else if (uniqueStage.toString() === "Translation Assigned"
         || uniqueStage.toString() === "Proof Reading Assigned"
        ) {
          //setIsAssigned(true);
          setIsAssigned(false);
          setIsCancelled(true);
          setIsClosure(false);
        } else if (uniqueStage.toString() === "Open"||uniqueStage.toString() === "Paid") {
          setIsAssigned(true);
          setIsCancelled(false);
          setIsClosure(false);
        } else if (uniqueStage.toString() === "Translation Completed") {
          setIsAssigned(true);
          setIsCancelled(false);
          setIsClosure(false);
        } else if (uniqueStage.toString() === "Proof Reader Rejected") {
          //setIsClosure(true);
          setIsAssigned(true);
        } else if (uniqueStage.toString() === "Proof Reading Completed") {
          setIsClosure(true);
          setIsAssigned(true);
        }
      } else {
        setIsAssigned(false);
        setIsCancelled(false);
        setIsClosure(false);
      }
    } else {
      setIsAssigned(false);
      setIsCancelled(false);
      setIsClosure(false);
    }
  }, [selectedRowsValues]);

  const handleFileAssignBatch = async () => {
    console.log("selectedRowsValues..",selectedRowsValues);
    let rows = selectedRowsValues.map((value, index) => {
      return value.original;
    });
    console.log("rows",rows);
    await CustomDialog(<FileAssignBatch selectedRows={rows} />, {
      title: "File Assign",
      showCloseIcon: true,
      className: { minwidth: "500px" },
    });
  };

  const handleFileCancelBatch = async () => {
    let rows = selectedRowsValues.map((value, index) => {
      return value.original;
    });

    await CustomDialog(<FileCancelBatch selectedRows={rows} />, {
      title: "File Assign Cancel",
      showCloseIcon: true,
    });
  };

  const handleFileClosureBatch = async () => {
    let rows = selectedRowsValues.map((value, index) => {
      return value.original;
    });

    await CustomDialog(<FileClosureBatch selectedRows={rows} />, {
      title: "File Closure",
      showCloseIcon: true,
    });
  };

  // const handleFilePreview = async (props) => {
  //   await CustomDialog(
  //     <AudioPlayers
  //       selectedRow={props}
  //       className="audioPlayer"
  //       style={{
  //         background: "#740AF6",
  //         padding: "40px",
  //         borderRadius: "5px",
  //       }}
  //     />,
  //     {
  //       title: "File Preview",
  //       showCloseIcon: true,
  //     }
  //   );
  // };

  const handleDownload = async (row, value) => {
    await CustomDialog(<DownloadFiles selectedRow={row} />, {
      title: "File Downloads",
      showCloseIcon: true,
    });
  };

  const headers = [
    { label: "Name", key: "fileName" },
    { label: "Duration", key: "fileDuration" },
    { label: "Project ID", key: "projectID" },
    { label: "Project Name", key: "projectName" },
    { label: "Uploaded On", key:"createdOn" },
    { label: "AssignedOn", key: "AssignedOn" },
    { label: "completedOnDate", key:"completedOnDate" },
    { label: "Status", key: "stage" },
    { label: "percentageOfCompletion", key: "percentageOfCompletion" },
    { label: "Transcription Handled By", key: "TranscriptionHandledBy"},
    { label: "Proofreading Handled By", key: "ProofreadingHandledBy"},
    { label: "Transcription Rejected Reason", key: "transcriptionReject" },
    { label: "Proofread Rejected Reason", key: "proofreadReject" },
    { label: "Transcription Cancel Reason", key: "transcriptionCancel" },
    { label: "Proofread Cancel Reason", key: "proofreadCancel" }
  ];

  return (
    <div className="fileListTable">
      {type=="delivery"?<div className="tableTopHeader" >
       <CSVLink data={data} headers={headers} className="table-btn" target="_blank" filename={customer.projectName + "-" + clickedSrcTgtLang.srctgtlang}>
        Export
      </CSVLink>
        <button className="greeTable-btn" onClick={refreshFiles}>Refresh</button>
      </div>
      :
      <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
        <CSVLink data={data} headers={headers} className="table-btn" target="_blank" filename={customer.projectName + "-" + clickedSrcTgtLang.srctgtlang}>
        Export
      </CSVLink>
        <button className="redTable-btn" disabled={!isAssigned} onClick={handleFileAssignBatch}>Assign</button>
        <button className="redTable-btn" disabled={!isCancelled} onClick={handleFileCancelBatch}>Cancel</button>
        <button className="redTable-btn" disabled={!isClosure} onClick={handleFileClosureBatch}>Closure</button>
        <button className="greeTable-btn" onClick={refreshFiles}>Refresh</button>
      </div>
      }

      {/* {type=="delivery"?""
      :
      <div className="tableBottomHeader">
        <table style={{width:"70%", marginTop:"0px", textAlign:"center"}}>
          <tr>
            <td></td>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">Open</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">TR Assigned</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">TR InProgress</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">TR Completed</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">PR Assigned</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">PR InProgress</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">PR Completed</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">Total Uploaded</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">Total Assigned</th>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="col">Total Completed</th>
          </tr>
          <tr>

            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="row">File Count</th>
            <td>{openCount}</td>
            <td>{TRAssignedCount}</td>
            <td>{TRInProgressCount}</td>
            <td>{TRCompletedCount}</td>
            <td>{PRAssignedCount}</td>
            <td>{PRInProgressCount}</td>
            <td>{PRCompletedCount}</td>
            <td>{totalFileCount}</td>
            <td>{totalAssignedFileCount}</td>
            <td>{totalCompletedFileCount}</td>
          </tr>
          <tr>
            <th style={{backgroundColor:"#005a6a", color:"#FFFFFF"}} scope="row">File Duration</th>
            <td>{openDuration}</td>
            <td>{TRAssignedDuration}</td>
            <td>{TRInProgressDuration}</td>
            <td>{TRCompletedDuration}</td>
            <td>{PRAssignedDuration}</td>
            <td>{PRInProgressDuration}</td>
            <td>{PRCompletedDuration}</td>
            <td>{totalDuration}</td>
            <td>{totalAssignedDuration}</td>
            <td>{totalCompletedDuration}</td>
          </tr>
        </table>
      </div>
      }    */}

       
      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText={clickedSrcTgtLang.srctgtlang}
        isMultiSelect={multiSelect}
        // setSelectedRows={setSelectedRows}
        setSelectedRowsValues={setSelectedRowsValues}
        getHeaderProps={(column) => ({
          // onClick: () => console.log(`Header Column clicked`, column),
        })}
        getColumnProps={(column) => ({
          // onClick: () => console.log(`Column clicked`, column),
        })}
        getRowProps={(row) => ({
          // onClick: () => console.log(`row clicked`, row),
          // style: {
          //   background: row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
          // },
        })}
        getCellProps={(cellInfo) => ({
          // style: {
          //   backgroundColor: `hsl(${
          //     120 * ((120 - cellInfo.value) / 120) * -1 + 120
          //   }, 100%, 67%)`,
          // },
        })}
      />
    </div>
  );
};

export default FileList;
